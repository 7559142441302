import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom"; // Portal support
import Button from "../Button";
import { MessageContextData, MessageContextDataLocal } from "../../types";
import { AssistantBubble } from "./AssistantBubble";
import Modal from "../Modal";
import PageWrapper from "../PageWrapper";
import PageTitle from "../PageTitle";
import { useTranslation } from "react-i18next";
import { ApartmentFaqs } from "../apartment/ApartmentFaqs";
import { Editor } from "@tinymce/tinymce-react";
import { handleFileContentEdit } from "../../shared/utils/common";
import SpecialTags from "../common/SpecialTags";

type KnowledgeBaseEditProps = {
  messageContextData: MessageContextData |undefined | null;
  refetch_message_context_data: () => void;
  isMessageContextLoading: boolean;
  t: (key: string) => string;
  className?: string;
};

export const KnowledgeBaseEdit: React.FC<KnowledgeBaseEditProps> = ({
  className = "",
  messageContextData,
  refetch_message_context_data,
  isMessageContextLoading,
  t,
}) => {
  const [fileContent, setFileContent] = useState("");
  const [fileSavingStatus, setFileSavingStatus] = useState(false);

  let assistantFileModeButton = {
    label: fileSavingStatus ? t("general.saving") : t("general.save"),
    onClick: (
      context_id: string,
      file_id: string | undefined,
      firestore_document_id: string | undefined,
      new_content: string
    ) => {
      handleFileContentEdit(
        context_id,
        file_id,
        firestore_document_id,
        new_content,
        setFileSavingStatus,
        refetch_message_context_data,
        t
      );
    },
  };

  useEffect(() => {
    if (messageContextData?.files) {
      const uniqueContent = Array.from(
        new Set(messageContextData.files.map((file) => file.file_content))
      ).join("");
      setFileContent(uniqueContent);
    }
  }, [messageContextData?.files]);

  return (
    <>
      <SpecialTags target={"ASS"} />

      <div className="bg-white  border-t-2 p-4 mt-4">
        {messageContextData?.running_mode === "faq_mode" ? (
          <ApartmentFaqs
            context_id={messageContextData?.context_id}
            refetch={() =>
              refetch_message_context_data
                ? refetch_message_context_data()
                : console.log("no refetch function")
            }
            maxHeight="55vh"
          />
        ) : messageContextData?.running_mode === "file_mode" ? (
          <>
            <Editor
              apiKey={"af1s9ngxeroefg0odkelxcvqpjzqffflygu2cw2tqk9jexpo"}
              value={fileContent}
              init={{
                promotion: false,
                height: 350,
                plugins: [
                  "advlist",
                  "autolink",
                  "link",
                  "lists",
                  "charmap",
                  "preview",
                  "searchreplace",
                  "wordcount",
                  "visualblocks",
                  "visualchars",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  // "table",
                  "emoticons",
                  "help",
                ],
                toolbar:
                  "undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | removeformat | help",
                menubar: "file edit view insert format tools table help",
              }}
              onEditorChange={(value) => setFileContent(value)}
            />
            <div className="border overflow-hidden rounded-2xl flex flex-wrap w-full mt-2">
              <Button
                onClick={() =>
                  assistantFileModeButton.onClick(
                    messageContextData?.context_id,
                    messageContextData?.files?.[0]?.file_id,
                    messageContextData?.files?.[0]?.firestore_document_id,
                    fileContent
                  )
                }
                className={`flex-grow flex items-center justify-center px-4 py-2 rounded-lg text-white ${
                  isMessageContextLoading
                    ? "bg-cyan-400 cursor-not-allowed"
                    : "bg-cyan-500 hover:bg-cyan-600"
                } transition duration-200 focus:outline-none focus:ring-2 focus:ring-cyan-300`}
                loading={fileSavingStatus}
                disabled={fileContent.trim().length === 0}
              >
                {assistantFileModeButton.label}
              </Button>
            </div>
          </>
        ) : (
          <div className="w-full text-center text-gray-400">
            {t("messageContextDetail.select_mode")}
          </div>
        )}
      </div>
    </>
  );
};
