import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function SpecialTags({ target }: { target: string }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  const specialTags : {key: string, enable: {[CC: string]: number}, translate: string}[] = [
    {key : '##Checkin-date##'                       , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Checkindate'                    },
    {key : '##Checkout-date##'                      , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Checkoutdate'                   },
    {key : '##Checkin-time##'                       , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'resv.checkInTime'                           },
    {key : '##Checkout-time##'                      , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'resv.checkOutTime'                          },
    {key : '##Nights-number##'                      , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Nightsnumber'                   },
    {key : '##Price##'                              , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Price'                          },
    {key : '##Description##'                        , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Description'                    },
    {key : '##Remind-desc##'                        , enable : {CC : 0 , DE : 0 , EM : 1 , CO : 0, ASS: 1} , translate : 'SpecialTags.Reminddesc'                     },
    {key : '##Guest-name##'                         , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Guestname'                      },
    {key : '##Guest-email##'                        , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Guestemail'                     },
    {key : '##Guest-count##'                        , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Guestcount'                     },
    {key : '##Apartment-name##'                     , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Apartmentname'                  },
    {key : '##Apartment-first-contact##'            , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'apartmentGeneral.hostFirstNumber'           },
    {key : '##Apartment-second-contact##'           , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'apartmentGeneral.hostSecondNumber'          },
    {key : '##Apartment-bell##'                     , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Bell'                           },
    {key : '##Apartment-specs##'                    , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Specs'                          },
    {key : '##Apartment-city##'                     , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Apartmentcity'                  },
    {key : '##Apartment-address##'                  , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Apartmentaddress'               },
    {key : '##Apartment-get-in-instruction##'       , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 0, ASS: 1} , translate : 'SpecialTags.ApartmentGetIn'                 },
    {key : '##Custom-apt-1##'                       , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Custom-apt1'                    },
    {key : '##Custom-apt-2##'                       , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Custom-apt2'                    },
    {key : '##Custom-apt-3##'                       , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Custom-apt3'                    },
    {key : '##Checkin-Link##'                       , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.CheckinLink'                    },
    {key : '##Reservation-code##'                   , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.Reservationcode'                },
    {key : '##Reservation-link##'                   , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 1} , translate : 'SpecialTags.ReservationLink'                },
    {key : '##ANDROID-applink##'                    , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 0, ASS: 0} , translate : 'SpecialTags.ANDROIDapplink'                 },
    {key : '##IOS-applink##'                        , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 0, ASS: 0} , translate : 'SpecialTags.IOSapplink'                     },
    {key : '##App-guide-img##'                      , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 0, ASS: 0} , translate : 'SpecialTags.Appguideimg'                    },
    {key : '$PDF_CONTRACT$'                         , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 0, ASS: 0} , translate : 'SpecialTags.PdfContract'                    },
    {key : '##Guest-filled-fname##'                 , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-fname'             },
    {key : '##Guest-filled-lname##'                 , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-lname'             },
    {key : '##Guest-filled-email##'                 , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-email'             },
    {key : '##Guest-filled-phone##'                 , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-phonenumber'       },
    {key : '##Guest-filled-country##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-country'           },
    {key : '##Guest-filled-state##'                 , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-state'             },
    {key : '##Guest-filled-city##'                  , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-city'              },
    {key : '##Guest-filled-dob##'                   , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-dob'               },
    {key : '##Guest-filled-doctype##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-doctype'           },
    {key : '##Guest-filled-docnum##'                , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-docnum'            },
    {key : '##Guest-filled-doc-country##'           , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-doc-country'       },
    {key : '##Guest-filled-doc-state##'             , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-doc-state'         },
    {key : '##Guest-filled-doc-city##'              , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-doc-city'          },
    {key : '##Guest-filled-doc-issue##'             , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-doc-issue'         },
    {key : '##Guest-filled-doc-expiration##'        , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-doc-expiration'    },
    {key : '##Guest-filled-invoicecountry##'        , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-invoicecountry'    },
    {key : '##Guest-filled-invoicecity##'           , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-invoicecity'       },
    {key : '##Guest-filled-invoicezip##'            , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-invoicezip'        },
    {key : '##Guest-filled-invoiceaddress##'        , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-invoiceaddress'    },
    {key : '##Guest-filled-invoicevatnum##'         , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-invoicevatnum'     },
    {key : '##Guest-filled-invoicename##'           , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-invoicename'       },
    {key : '##Guest-filled-invoicepassport##'       , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-invoicepassport'   },
    {key : '##Guest-filled-residence-city##'        , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-residencecity'     },
    {key : '##Guest-filled-residence-province##'    , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-residenceprovince' },
    {key : '##Guest-filled-residence-country###'    , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.Guest-filled-residencecountry'  },
    {key : '##Guest-filled-extra-1##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.ExtraField1'                    },
    {key : '##Guest-filled-extra-2##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.ExtraField2'                    },
    {key : '##Guest-filled-extra-3##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.ExtraField3'                    },
    {key : '##Guest-filled-extra-4##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.ExtraField4'                    },
    {key : '##Guest-filled-extra-5##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.ExtraField5'                    },
    {key : '##Guest-filled-extra-6##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.ExtraField6'                    },
    {key : '##Guest-filled-extra-7##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.ExtraField7'                    },
    {key : '##Guest-filled-extra-8##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.ExtraField8'                    },
    {key : '##Guest-filled-extra-9##'               , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.ExtraField9'                    },
    {key : '##Guest-filled-extra-10##'              , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'SpecialTags.ExtraField10'                   },
    {key : '##Apartment-image-1##'                  , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.ApartmentImage1'                },
    {key : '##Apartment-image-2##'                  , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.ApartmentImage2'                },
    {key : '##Apartment-image-3##'                  , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.ApartmentImage3'                },
    {key : '##Apartment-image-4##'                  , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.ApartmentImage4'                },
    {key : '##Apartment-image-5##'                  , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.ApartmentImage5'                },
    {key : '##Apartment-image-6##'                  , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.ApartmentImage6'                },
    {key : '##Apartment-image-7##'                  , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.ApartmentImage7'                },
    {key : '##Apartment-image-8##'                  , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.ApartmentImage8'                },
    {key : '##Apartment-image-9##'                  , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.ApartmentImage9'                },
    {key : '##Apartment-image-10##'                 , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.ApartmentImage10'               },
    {key : '##Apartment-logo-1##'                   , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.logo1info'                      },
    {key : '##Apartment-logo-2##'                   , enable : {CC : 1 , DE : 1 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.logo2info'                      },
    {key : '##Custom-1##'                           , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'reservationNotes.custom1'                   },
    {key : '##Custom-2##'                           , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'reservationNotes.custom2'                   },
    {key : '##Custom-3##'                           , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'reservationNotes.custom3'                   },
    {key : '##Custom-4##'                           , enable : {CC : 0 , DE : 0 , EM : 0 , CO : 1, ASS: 0} , translate : 'reservationNotes.custom4'                   },
    {key : '##City-tax-amount##'                    , enable : {CC : 0 , DE : 0 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.citytax_tot'                    },
    {key : '##City-tax-amount-net##'                , enable : {CC : 0 , DE : 0 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.citytax_net'                    },
    {key : '##City-tax-commission##'                , enable : {CC : 0 , DE : 0 , EM : 1 , CO : 1, ASS: 0} , translate : 'SpecialTags.citytax_commission'             },
    {key : '##Deposit-amount##'                     , enable : {CC : 1 , DE : 0 , EM : 1 , CO : 1, ASS: 0} , translate : 'apartmentSettings.deposit_amount'           },
];

  return (
    <div className={"bg-white cst-shadow rounded border  relative mb-6"}>
      <button
        onClick={() => setVisible(!visible)}
        className={
          "w-full px-4 py-3 text-sm border-gray-300 font-medium hover:bg-cyan-50 hover:text-cyan-600 cursor-pointer"
        }
      >
        <div className="flex space-x-2">
          <div>
            {t("SpecialTags.description") + "->"}{" "}
            {visible ? t("SpecialTags.HelpHide") : t("SpecialTags.HelpShow")}
          </div>
        </div>
      </button>

      {visible && (
        <div className={"bg-white cst-shadow rounded border  relative mb-6"}>
          <table className={"table-auto w-full"}>
            <thead>
              <tr className={"text-sm border-b border-gray-300"}>
                <th className={"py-2 text-left text-gray-600 font-small"}>
                  {t("SpecialTags.key")}
                </th>
                <th className={"py-2 text-left text-gray-600 font-small"}>
                  {t("SpecialTags.tagDescription")}
                </th>
              </tr>
            </thead>

            <tbody>
              {specialTags.map(
                (item) =>
                  item.enable[target] === 1 && (
                    <tr className={"border-b text-sm border-gray-300"}>
                      <td className={"py-2"}>{item.key}</td>
                      <td className={"py-2"}>{t(item.translate)}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
