import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchThreads,
  setCurrentThread,
  setIsFocused,
  setCurrentFilter,
  fetchMessages,
  sendMessage,
  pinChat,
  sortThreads,
  archiveChat,
  noReplyNeeded,
  dismissSpecialRequest,
  resetThreads,
  searchHandler,
  fetchAttachments,
  readAllThread,
  activateAssistant,
} from "../features/messaging/messagingSlice";
import PageWrapper from "../components/PageWrapper";
import MessageThread from "../components/messaging/MessageThread";
import MessageBubble from "../components/messaging/MessageBubble";
import MessageInput from "../components/messaging/MessageInput";
import CircleSpinner from "../components/CircleSpinner";
import Button from "../components/Button";
import store from "../store";
import { AnimatePresence, motion } from "framer-motion";

import { useTranslation } from "react-i18next";
import {
  BroadcastSelectedBooking,
  GroupedMessages,
  Message,
  MessageUploadAttachments,
  Thread,
} from "../types";
import { format, isToday, isYesterday } from "date-fns";
import { RootState } from "../reducers";
import ProgressBar from "../components/ProgressBar";
import ReservationDetail from "../components/calendar/ReservationDetail";
import Icon from "../components/Icon";
import { ImageViewModal } from "../components/ImageViewModal";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import {
  fetchAllModules,
  fetchMessageThreadDrafts,
  fetchMessageContextDatas,
  fetchMessageThreadAssistants,
  fetchMessageThreadReservationNotes,
  setMessageThreadAssistantStatus,
} from "../shared/queries";
import { moduleId } from "../shared/utils/config";
import Modal from "../components/Modal";
import ModalCard from "../components/ModalCard";
import ModalSection from "../components/ModalSection";
import ModalActions from "../components/ModalAction";
import { useHistory, useLocation } from "react-router-dom";
import Pagination from "../components/Pagination";
import NewChatModal from "../components/messaging/newChatModal";
import { encodeFileToBase64 } from "../shared/utils/common";
import BroadcastMessageModal from "../components/messaging/broadcastMessageModal";
import TooltipModal from "../components/TooltipModal";
import { TextBanner } from "../components/TextBannerMessageView";
import { AssistantBubble } from "../components/assistant/AssistantBubble";
import { EditAssistantModal } from "../components/EditAssistantModal";

export default function Messaging() {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.login);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [searchFinished, setSearchFinished] = useState(false);
  const [moduleFound, setModuelFound] = useState<boolean>();

  const {
    threads,
    currentThread,
    messages,
    hasMore,
    currentFilter,
    limit,
    isLoading,
    isFetchingMessages,
    isFetching,
    isSendingMessage,
    isSearchngThread,
    isFocused,
    has_unread_messages,
  } = useSelector((state) => store.getState().messageStore);
  const [currentResv, setCurrentResv] = useState("");
  const location = useLocation();
  const [queryParams, setQueryParams] = useState({
    thread_id: new URLSearchParams(location.search).get("thread_id"),
    query: new URLSearchParams(location.search).get("query"),
  });

  const [newMessage, setNewMessage] = useState("");

  const [attachments, setAttachments] = useState<MessageUploadAttachments[]>(
    []
  );

  const messagesEnd = useRef(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isMessagesReady, setIsMessagesReady] = useState(false);
  const [reservations, setReservations] = useState<string[] | undefined>([]);

  const [viewImage, setViewImage] = useState<string | null>(null);
  const [displayMod, setDisplayMod] = useState({
    display: false,
    moduleName: "",
  });
  const [newChatModal, setNewChatModalStatus] = useState(false);
  const [newBroadCastMessageModalStatus, setNewBroadCastMessageModalStatus] =
    useState(false);
  const [selectedBookingsForMessage, setSelectedBookingsForMessage] = useState<
    BroadcastSelectedBooking[]
  >([]);

  const { t } = useTranslation();
  const allowedImageMimeTypes = ["image/jpeg", "image/png"];

  const {
    data: messageThreadAssistantStatus,
    isLoading: loadingAssistantStatus,
    isError,
    error,
    refetch: refetchAssistantStatus,
  } = useQuery(
    ["messageThreadAssistantStatus", currentThread],
    () =>
      fetchMessageThreadAssistants({
        resv_key: currentThread?.resv_key,
        thread_id: currentThread?.thread_id,
      }),
    {
      staleTime: 60000,
      onError: (err: any) => {
        console.error("Query Error:", err);
      },
    }
  );
  const [editAssistantModal, setEditAssistantModal] = useState(false);

  // console.log("messageThreadAssistantStatus ",messageThreadAssistantStatus)

  const groupMessagesByDate = (messages: Message[]): GroupedMessages[] => {
    const grouped: { [key: string]: Message[] } = {};

    messages.forEach((message) => {
      if (!message.sent_on) return;

      const sentDate = new Date(message.sent_on);
      if (isNaN(sentDate.getTime())) return;

      const dateKey = isToday(sentDate)
        ? t("messaging.sentToday")
        : isYesterday(sentDate)
        ? t("messaging.sentYesterday")
        : format(sentDate, "dd/MM/yyyy");

      if (!grouped[dateKey]) {
        grouped[dateKey] = [];
      }
      grouped[dateKey].push(message);
    });

    return Object.entries(grouped).map(([date, messages]) => ({
      date: date,
      messages: messages,
    }));
  };
  const threadMessages: Message[] =
    messages[currentThread?.thread_id || ""] || [];
  const groupedMessages: GroupedMessages[] =
    groupMessagesByDate(threadMessages).reverse();

  const handleSelectChat = (thread_id: string | null) => {
    // console.log("selecting chat ",thread_id    )
    setIsMessagesReady(false);
    dispatch(setCurrentThread({ thread_id: thread_id }));
    if (thread_id) {
      dispatch(fetchMessages({ threadId: thread_id }));
    }
    
    setIsSidebarVisible(false);
    setIsMessagesReady(true);
    setNewMessage("");
    setAttachments([]);
  };
  const handleChatArchive = (thread: Thread) => {
    dispatch(archiveChat({ thread: thread }));
  };
  const handlePinChat = (thread: Thread) => {
    dispatch(pinChat({ thread: thread }));
    dispatch(sortThreads());
  };
  const handleMuteChat = (thread: Thread) => {
    /* TODO */
  };
  const handleNoReplyNeeded = (thread: Thread) => {
    dispatch(noReplyNeeded({ thread: thread }));
  };

  const toggleNewChatModal = () => {
    // console.log("new chat modal status ", newChatModal);
    if (newChatModal) {
      setNewChatModalStatus(false);
      setSelectedBookingsForMessage([]);
    } else {
      setNewChatModalStatus(true);
    }
  };

  const toggleBroadcastMessageModal = () => {
    if (newBroadCastMessageModalStatus) {
      setNewBroadCastMessageModalStatus(false);
    } else {
      setNewBroadCastMessageModalStatus(true);
    }
  };
  const handleFilterSelect = (
    selectedFilter: "all" | "unread" | "archived"
  ) => {
    dispatch(setCurrentFilter({ currentFilter: selectedFilter }));
    dispatch(resetThreads());
  };
  const handleReadAll = () => {
    dispatch(readAllThread());
  };
  const resetQueryParams = () => {
    if (queryParams.thread_id || queryParams.query) {
      setQueryParams({ thread_id: null, query: null });
    }
  };

  const clearNewChatSearch = () => {
    setSearchValue("");
  };

  const handleActivateAssisant = useMutation(
    () =>
      setMessageThreadAssistantStatus({
        resv_key: currentThread?.resv_key,
        thread_id: currentThread?.thread_id,
        action: "ACTIVATE_ASSISTANT",
      }),
    {
      onSuccess: (msg: any) => {
        toast.success(t(msg));
        refetchAssistantStatus();
        if(currentThread){
          dispatch(activateAssistant({thread:currentThread}))
        }
      },
      onError: (error: any) => {
        toast.error(t(error));
        refetchAssistantStatus();
      },
    }
  );

  const handleSendMessage = () => {
    if (
      (newMessage.trim() && currentThread) ||
      (attachments.length > 0 && currentThread)
    ) {
      dispatch(
        sendMessage({
          thread: currentThread,
          message_content: newMessage,
          files: attachments,
        })
      );
      setNewMessage("");
      setAttachments([]);
      refetchAssistantStatus();
    }
  };
  const clearSearch = () => {
    setSearchValue("");
    resetQueryParams();
  };

  const handleThumbnailClick = (base64: string) => {
    setViewImage(base64);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    // console.log("files ",files)
    if (files && files.length > 0) {
      let totalSize = 0;
      const newAttachments: MessageUploadAttachments[] = [];
      for (let i = 0; i < files.length; i++) {
        totalSize += files[i].size;
      }
      if (totalSize > 1024 * 1024 * 1024) {
        toast.error(t("messaging.attachments.fileTooBig"));
        event.target.value = "";
        return;
      }
      for (let i = 0; i < files.length; i++) {
        let fileMimeType = files[i].type;

        if (!allowedImageMimeTypes.includes(fileMimeType)) {
          toast.error(t("messaging.attachments.notAnImage"));
          event.target.value = "";
          return;
        }
      }
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          const base64 = await encodeFileToBase64(file);
          const temp_file_id = file.name + "_" + file.size;
          const fileExists = attachments.some(
            (attachment) => attachment.id === temp_file_id
          );
          if (!fileExists) {
            newAttachments.push({
              id: temp_file_id,
              base_64_string: base64,
              file_name: file.name.replace(/\.[^/.]+$/, ""),
              file_mime_type: file.type,
            });
          }
        } catch (error) {
          console.error("Error encoding file to base64:", error);
        }
      }
      const updatedAttachments = [...attachments, ...newAttachments];
      setAttachments(updatedAttachments);
      event.target.value = "";
    }
  };
  const handleRemoveAttachment = (id: string) => {
    const updatedAttachments = attachments.filter(
      (attachment) => attachment.id !== id
    );
    setAttachments(updatedAttachments);
  };
  const {
    data: threadResvNotes,
    isLoading: isLoadingResvNotes,
    isError: isResvDataError,
    error: resvDataError,
    refetch: refetchThreadResvNotes,
  } = useQuery(
    ["messageThreadResvNotes", currentThread?.resv_key],
    () =>
      currentThread?.resv_key
        ? fetchMessageThreadReservationNotes({
            resv_key: currentThread?.resv_key,
            action: "SPECIAL_REQUESTS",
          })
        : undefined,
    {
      staleTime: 600,
      retry: 3,
    }
  );
  // console.log("thread resv notes ", threadResvNotes);

  const { refetch: refetchDataModules } = useQuery<any>([], () => {
    fetchAllModules().then((data: any) => {
      const moduleFound = data?.modules?.find(
        (module: any) => module?.id === moduleId.channel_manager
      );
      if (moduleFound) {
        if (moduleFound?.active_locals_count > 0) {
          setDisplayMod({
            display: false,
            moduleName: "",
          });
          setModuelFound(true);
        } else {
          setDisplayMod({
            display: true,
            moduleName: t(`module.id${moduleFound.id}`).toUpperCase(),
          });
          setModuelFound(false);
        }
      } else {
        setDisplayMod({
          display: true,
          moduleName: "NOTAVAILABLE",
        });
        setModuelFound(false);
      }
      return data;
    });
  });

  const handleLoadMore = useCallback(() => {
    // console.log("has more is fetching ", hasMore, isFetching);
    if (hasMore && !isFetching) {
      dispatch(
        fetchThreads({
          limit,
          filter: currentFilter,
        })
      );
    }
  }, [dispatch, hasMore, isFetching, currentFilter, limit]);
  const threadExists = (
    thread_id: string,
    threads: Record<string, Thread>
  ): boolean => {
    return thread_id in threads;
  };

  const handleBotMessage = (
    action: string,
    message_id: string,
    botMessage: string,
    use_for_learning?: boolean
  ) => {
    if (currentThread) {
      dispatch(
        sendMessage({
          thread: currentThread,
          message_content: botMessage,
          action: action,
          message_id: message_id,
          use_for_learning: use_for_learning,
        })
      );
    }
  };

  useEffect(() => {
    const fetchMessagesLocal = async () => {
      try {
        await dispatch(
          fetchMessages({
            threadId: currentThread?.thread_id,
          })
        );
      } catch {
        console.log("oopsie");
      }
    };

    fetchMessagesLocal();
  }, [dispatch, currentThread]);

  useEffect(() => {
    if (searchValue !== "") {
      setSearchFinished(false);
      dispatch(searchHandler({ query: searchValue, filter: currentFilter }));
      setSearchFinished(true);
    }
  }, [dispatch, searchValue, currentFilter]);

  useEffect(() => {
    dispatch(
      setIsFocused({ thread_id: currentThread?.thread_id, isFocused: true })
    );
    if (currentThread) {
      setIsMessagesReady(true);
    }

    return () => {
      dispatch(
        setIsFocused({ thread_id: currentThread?.thread_id, isFocused: false })
      );
    };
  }, [dispatch, currentThread]);

  useEffect(() => {
    refetchDataModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && queryParams && queryParams.query) {
      setSearchValue(queryParams.query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, queryParams]);
  useEffect(() => {
    if (
      searchFinished &&
      queryParams &&
      queryParams.thread_id &&
      threadExists(queryParams.thread_id, threads) &&
      (!currentThread ||
        (currentThread &&
          queryParams.thread_id &&
          currentThread.thread_id !== queryParams.thread_id))
    ) {
      // console.log("handle select chat ")
      handleSelectChat(queryParams.thread_id);
    }
    const resvs = currentThread?.subtitle.split(" ");
    setReservations(resvs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFinished, threads, currentThread]);

  return (
    <PageWrapper
      className="flex h-screen"
      style={{
        height: "85vh",
      }}
    >
      {/* Sidebar */}
      <div
        className={`w-full md:w-1/3 bg-gray-100 border-r flex flex-col h-full ${
          isSidebarVisible ? "" : "hidden"
        } sm:flex`}
      >
        <div className="bg-gray-200 p-5 flex items-center justify-between">
          {/* Tooltip with floating modal */}
          <TooltipModal
            content={t("messaging.disclaimer")}
            strokeColor="#e8a317"
          />

          <h1 className="text-lg font-semibold text-gray-700">
            {t("navigation.messaging")}
          </h1>
          <button
            className="hover:bg-gray-300"
            title={t("messaging.newMessage")}
            onClick={toggleNewChatModal}
            disabled={!moduleFound}
          >
            <Icon name="new_chat" size="20px" />
          </button>
        </div>
        <div className="mb-2">{isLoading && <ProgressBar className="" />}</div>

        {/* Sidebar Content */}
        <div className="p-4">
          <div className="relative">
            <input
              type="text"
              value={searchValue}
              onChange={(event) => {
                const value = event.target.value;

                // Guard Empty check, max length, and valid characters
                if (value.length > 50 || !/^[a-zA-Z0-9 ]*$/.test(value)) {
                  return;
                }
                setSearchValue(value);
                resetQueryParams();
              }}
              className="w-full border rounded-lg p-2 focus:border-cyan-600 focus:outline-none"
              placeholder={t("messaging.filter.search")}
            />
            {/* Clear Button */}
            {searchValue && (
              <button
                type="button"
                onClick={clearSearch}
                className="absolute text-gray-400 right-2 top-1/2 transform -translate-y-1/2 bg-transparent border-none cursor-pointer"
              >
                {isSearchngThread ? (
                  <CircleSpinner color={"primary"} />
                ) : (
                  <Icon name="close" size="20px" />
                )}
              </button>
            )}
          </div>
        </div>
        <div className="bg-gray-100 p-4 flex items-center justify-between overflow-auto">
          <div className="flex space-x-2">
            <Button
              className={`text-sm text-white-500 hover:text-white-700 ${
                currentFilter === "all"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
              onClick={() => {
                if (currentFilter === "all") {
                  return;
                }
                handleFilterSelect("all");
              }}
              label={t("messaging.filter.all")}
            />

            <Button
              className={`text-sm text-white-500 hover:text-white-700 ${
                currentFilter === "unread"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
              onClick={() => {
                if (currentFilter === "unread") {
                  return;
                }
                handleFilterSelect("unread");
              }}
              label={t("messaging.filter.unread")}
            />
            <Button
              className={`text-sm text-white-500 hover:text-white-700 ${
                currentFilter === "archived"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
              onClick={() => {
                if (currentFilter === "archived") {
                  return;
                }
                handleFilterSelect("archived");
              }}
              label={t("messaging.filter.archived")}
            />
          </div>
          <Button
            icon="readmail"
            color="info"
            dataTip={t("messaging.setAllRead")}
            className="flex flex-wrap"
            disabled={!has_unread_messages || isLoading}
            onClick={handleReadAll}
          />
        </div>

        <div className="flex-1 overflow-y-auto">
          <AnimatePresence>
            {Object.values(threads)
              .filter((thread) => {
                const matchesArchived =
                  currentFilter === "archived"
                    ? thread.archived
                    : !thread.archived;
                const matchesSearchValue =
                  searchValue.length > 0
                    ? thread.name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                      thread.subtitle
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    : true;

                return matchesArchived && matchesSearchValue;
              })
              .sort((a, b) => {
                if (a.pinned && !b.pinned) return -1;
                if (!a.pinned && b.pinned) return 1;

                const dateA = new Date(a.last_message.sent_on).valueOf();
                const dateB = new Date(b.last_message.sent_on).valueOf();

                return dateB - dateA;
              })
              .map((thread) => (
                <motion.div
                  key={thread.thread_id}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ type: "tween" }}
                >
                  <MessageThread
                    thread_id={thread.thread_id}
                    name={thread.name}
                    resv_key={thread.resv_key}
                    subtitle={thread.subtitle}
                    pinned={thread.pinned}
                    muted={thread.muted}
                    last_message={thread.last_message}
                    has_been_read={thread.has_been_read}
                    archived={thread.archived}
                    source={thread.source}
                    onArchive={() => handleChatArchive(thread)}
                    onMute={() => handleMuteChat(thread)}
                    onPin={() => handlePinChat(thread)}
                    isSelected={currentThread?.thread_id === thread.thread_id}
                    onSelect={() => {
                      if (currentThread?.thread_id === thread.thread_id) return;
                      handleSelectChat(thread.thread_id);
                    }}
                  />
                </motion.div>
              ))}
          </AnimatePresence>

          {/* Observer Target */}
          {hasMore && !isLoading && (
            <div className="flex justify-center items-center bg-cyan-50 hover:bg-cyan-100">
              <Button
                label={t("messaging.loadMore")}
                color="info"
                onClick={handleLoadMore}
              />
            </div>
          )}
          {!hasMore && (
            <p className="mt-4" style={{ textAlign: "center", color: "gray" }}>
              {t("messaging.endOfList")}
            </p>
          )}
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="w-full md:w-2/3 flex flex-col h-full">
        {currentThread ? (
          <>
            {!isFetchingMessages && isMessagesReady && (
              <>
                <div className="bg-gray-200 p-3 flex items-center justify-between border-l border-gray-300">
                  {/* Header Text */}
                  <div className="flex items-center">
                    <Icon
                      className="bg-cyan-500 mr-2"
                      name={currentThread ? currentThread.source : "vikey_logo"}
                      size="25px"
                    />{" "}
                    <div>
                      <h2 className="text-sm">{currentThread.name}</h2>
                      {reservations?.map((reservation, index) => (
                        <span
                          key={index}
                          className="text-sm text-gray-500 mr-2 hover:underline cursor-pointer"
                          onClick={() => setCurrentResv(reservation)}
                        >
                          {reservation}
                        </span>
                      ))}
                    </div>
                  </div>

                  {/* Action Buttons */}
                  <div className="flex space-x-4">
                    {/* <Button
                      className="text-white-500 hover:text-white-600 p-2 transition-colors duration-300"
                      onClick={() =>
                        setCurrentResv(
                          currentThread ? currentThread.resv_key : ""
                        )
                      }
                      label=""
                      icon="information-circle"
                    /> */}
                    <Button
                      className="text-white-500 hover:text-red-600 p-2 transition-colors duration-300 lg:hidden"
                      onClick={() => {
                        handleSelectChat(null);
                        setIsSidebarVisible(true);
                      }}
                      label=""
                      icon="close"
                    />
                  </div>
                </div>
                {messageThreadAssistantStatus?.module_active &&
                  currentThread.skip_ai_intervention && (
                    <div className="bg-gray-50 p-4 flex flex-wrap items-center text-cyan-400 overflow-auto">
                      <Icon
                        className="mr-2"
                        name="information-circle"
                        size={"20px"}
                      />
                      {t("messaging.assistant.assistant_disabled_message")}

                      {/* Button Container */}
                      <div className="ml-auto flex gap-2">
                        <Button
                          color="info"
                          className="flex"
                          label={t("assistant.knowledgebase.edit")}
                          onClick={() =>
                            setEditAssistantModal(!editAssistantModal)
                          }
                          disabled={handleActivateAssisant.isLoading}
                          loading={handleActivateAssisant.isLoading}
                        />
                        <Button
                          color="info"
                          className="flex"
                          label={t(
                            "messaging.assistant.activate_assistant_intervention"
                          )}
                          onClick={() => handleActivateAssisant.mutate()}
                          disabled={handleActivateAssisant.isLoading}
                          loading={handleActivateAssisant.isLoading}
                        />
                      </div>
                    </div>
                  )}
                {currentThread && !isLoadingResvNotes && (
                  <TextBanner
                    visible={threadResvNotes.length > 0}
                    alerts={threadResvNotes}
                    variant="info"
                  />
                )}
                <div
                  className="flex-1 bg-white p-4 overflow-y-auto flex flex-col"
                  style={{ flexDirection: "column-reverse" }}
                  ref={messagesEnd}
                >
                  {currentThread.can_send_no_need_to_reply && (
                    <div className="flex justify-center mt-4">
                      <Button
                        color="info"
                        className="bg-gray-100"
                        label={t("messaging.no_reply_needed")}
                        onClick={() => handleNoReplyNeeded(currentThread)}
                      />
                    </div>
                  )}
                  {groupedMessages.map((group) => (
                    <div key={group.date}>
                      <h3 className="text-sm text-gray-500 font-semibold mt-4 text-center">
                        {group.date}
                      </h3>
                      {group.messages.map((msg, index) => (
                        <motion.div
                          key={msg.message_id}
                          layout
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ type: "tween" }}
                        >
                          <MessageBubble
                            key={msg.message_id || index}
                            message={msg}
                            profile_lang={profile?.lang}
                            resv_key={currentThread.resv_key}
                            handleBotMessage={handleBotMessage}
                            isSending={isSendingMessage}
                            thread_id={currentThread.thread_id}
                          />
                        </motion.div>
                      ))}
                    </div>
                  ))}
                </div>

                {currentResv && (
                  <motion.div
                    initial={{ right: -200 }}
                    animate={{ right: 15 }}
                    style={{
                      top: "0",
                      position: "fixed",
                      height: "100vh",
                      width: "100vw",
                      zIndex: 99,
                      padding: "2rem",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    onClick={(e) => setCurrentResv("")}
                  >
                    <div
                      style={{
                        width: "100%",
                        maxWidth: "600px",
                        borderRadius: "6px",
                        overflowX: "auto",
                        height: "100%",
                        boxShadow: "0px 2px 16px rgba(0,0,0,0.3)",
                        position: "relative",
                        backgroundColor: "#FFF",
                      }}
                      className="border border-gray-300"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Button
                        className="text-white-500 mt-2 ml-10 hover:text-red-600 p-2 transition-colors duration-300 float-right md:hidden"
                        onClick={() => {
                          setCurrentResv("");
                        }}
                        label=""
                        icon="close"
                      />
                      <div style={{ padding: "2rem" }}>
                        <ReservationDetail reservationId={currentResv} />
                      </div>
                    </div>
                  </motion.div>
                )}
                {/* Message Input */}
                {/* Attachments */}

                <div className="flex flex-wrap gap-4 ml-2">
                  {attachments.map((attachment) => (
                    <div
                      key={attachment.id}
                      className="relative w-20 h-20 border rounded overflow-hidden flex-shrink-0"
                    >
                      <img
                        src={attachment.base_64_string}
                        alt="Thumbnail"
                        className="w-full h-full object-cover cursor-pointer"
                        onClick={() =>
                          handleThumbnailClick(attachment.base_64_string)
                        }
                      />
                      <button
                        onClick={() => handleRemoveAttachment(attachment.id)}
                        className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1"
                      >
                        <Icon name="close" size="12px" />
                      </button>
                    </div>
                  ))}
                </div>

                <ImageViewModal
                  visible={!!viewImage}
                  onClose={() => setViewImage(null)}
                >
                  <img
                    src={viewImage || ""}
                    alt="Enlarged view"
                    className="w-full h-full max-w-full max-h-full object-contain"
                    style={{ objectFit: "contain", height: "85vh" }}
                  />
                </ImageViewModal>
                {editAssistantModal &&
                  messageThreadAssistantStatus &&
                  !loadingAssistantStatus && (
                    <EditAssistantModal
                      contexts={messageThreadAssistantStatus.contexts}
                      visible={editAssistantModal}
                      onClose={() => setEditAssistantModal(!editAssistantModal)}
                      noBackdropDismiss={true}
                    />
                  )}

                <div className="bg-gray-100 flex items-center pt-2">
                  <MessageInput
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                      }
                    }}
                    placeholder={t("messaging.typeMessage")}
                    className="flex-1 rounded-lg p-2"
                    emoji_lang={profile?.lang}
                    disabled={isSendingMessage}
                    handleFileChange={handleFileChange}
                  />
                  {!isSendingMessage ? (
                    <Button
                      className="bg-blue-500 text-white p-2 rounded-lg mr-2"
                      label=""
                      icon="send"
                      disabled={
                        !(newMessage.length > 0 || attachments.length > 0)
                      }
                      onClick={handleSendMessage}
                    />
                  ) : (
                    <div className="p-2 mr-2">
                      <CircleSpinner color={"primary"} />
                    </div>
                  )}
                </div>
              </>
            )}
            {(isFetchingMessages || !isMessagesReady) && (
              <div className="flex justify-center items-center h-full">
                <CircleSpinner color={"primary"} />
              </div>
            )}
          </>
        ) : (
          <>
            {!isFetchingMessages && (
              <div
                className={`flex-1 flex items-center justify-center sm:flex hidden`}
              >
                {t("messaging.selectChatPrompt")}
              </div>
            )}
          </>
        )}
      </div>
      <Modal visible={displayMod.display} className={"w-full"}>
        <ModalCard
          title={`${displayMod.moduleName.toUpperCase()} (${t(
            "module.statusInactive"
          )})`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <span className="text-gray-600 font-medium">
                {t("module.buyModuleFunctionality", {
                  name: displayMod.moduleName.toUpperCase(),
                })}
              </span>
            </PageWrapper>
            <ModalActions
              onClose={() =>
                setDisplayMod({
                  display: false,
                  moduleName: "",
                })
              }
              onSave={() => {
                if (displayMod.moduleName === "NOTAVAILABLE") {
                  toast.error("NOTAVAILABLE");
                } else {
                  history.push(`/modules/module#${moduleId.channel_manager}`);
                }
              }}
              saveLabel={t("module.purchase")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
      <NewChatModal
        newChatModal={newChatModal}
        toggleNewChatModal={toggleNewChatModal}
        toggleBroadcastMessageModal={toggleBroadcastMessageModal}
        selectedBookingsForMessage={selectedBookingsForMessage}
        setSelectedBookingsForMessage={setSelectedBookingsForMessage}
      />
      <BroadcastMessageModal
        newBroadCastMessageModalStatus={newBroadCastMessageModalStatus}
        selectedBookingsForMessage={selectedBookingsForMessage}
        toggleBroadcastMessageModal={toggleBroadcastMessageModal}
        allowedImageMimeTypes={allowedImageMimeTypes}
      />
    </PageWrapper>
  );
}
