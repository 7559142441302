import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchTokenChannel } from "../../shared/queries";
import Skeleton from "react-loading-skeleton";
import i18n from "../../langs";
import Button from "../Button";
import { isMobile } from "react-device-detect";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import ModalActions from "../ModalAction";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";

type Props = {
  isLoading: boolean;
  user_key: any;
};

const CalendarCM = (props: Props) => {
  const { t } = useTranslation();
  const { isLoading, user_key } = props;
  const { data } = useQuery<any>(
    ["channelcalendar", i18n.language, user_key],
    () => fetchTokenChannel(user_key)
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isZoomed, setIsZoomed] = useState(0);
  const [modalView, setModalView] = useState(false);

  const zoomInMobile = () => {
    const viewport: any = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      viewport.content = "initial-scale=1";
      setIsZoomed(0);
    }
  };

  const zoomMobile = (type: "+" | "-") => {
    const viewport: any = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      if (type === "-") {
        setIsZoomed((prev) => {
          viewport.content = `width=${800 + prev * 200}`;
          return prev + 1;
        });
      } else {
        viewport.content = "initial-scale=1";
        setIsZoomed(0);
      }
    }
  };

  useEffect(() => {
    return () => {
      zoomInMobile();
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setModalView(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  if (isLoading) {
    return (
      <div className={"mt-2 flex border-b pb-2 border-gray-200"}>
        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>

        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>

        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>

        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>

        <div className={"px-1 flex-1"}>
          <Skeleton />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          boxShadow:
            "0px 2px 8px rgba(0,0,0,0.03), 0px 0px 0px 1px rgba(0,0,0,0.05)",
        }}
        className={"bg-gray-50 rounded-2xl overflow-hidden my-2"}
      >
        <div
          className={
            "bg-white items-center w-full p-4 flex flex-wrap justify-between rounded-2xl rounded-tr-none rounded-tl-none"
          }
        >
          <div>
            <div className="flex gap-3">
              <Icon name="alert-bell" size="20px" className="text-yellow-400" />
              <p>{t("attention.important")}</p>
              <Icon name="alert-bell" size="20px" className="text-yellow-400" />
            </div>
            <div className={`text-cyan-800 text-sm space-y-2 mt-3`}>
              {t("attention.importantDescriptionCM")
                .split("///")
                .map((desc) => (
                  <p key={desc}>{desc}</p>
                ))}
            </div>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className="mb-3 w-full">
          <Button
            label={t("general.reset") + " Zoom"}
            onClick={() => zoomMobile("+")}
            size="small"
          />
          <Button
            label="Zoom -"
            className="ml-0.5"
            onClick={() => zoomMobile("-")}
            size="small"
          />
        </div>
      )}
      <div
        className="relative overflow-hidden"
        style={{ paddingBottom: "46.25%" }}
        // style={{ paddingBottom: "46.25%" }}
      >
        <iframe
          className={`overflow-hidden border-0 align-middle self-center absolute top-0 left-0 w-full ${
            isMobile ? "h-screen" : "h-full"
          }`}
          title="import-channel-manager"
          allow="clipboard-read; clipboard-write"
          src={`https://app.channex.io/auth/exchange?oauth_session_key=${data?.results?.token}&app_mode=headless&redirect_to=/inventory&property_id=${data?.results?.property_id}&lng=${i18n.language}#zoom=50`}
          // src={`https://staging.channex.io/auth/exchange?oauth_session_key=${data?.results?.token}&app_mode=headless&redirect_to=/inventory&property_id=${data?.results?.property_id}`}
        ></iframe>
      </div>
      <Modal visible={modalView}>
        <ModalCard title={t("apartments.warning")} className="w-full">
          <ModalSection>
            <div className="text-gray-700 p-4 pt-0 border-gray-300">
              {t("channelManager.calendarManager.msgMobile")}
              <ul className="list-disc p-4">
                <li>{t("channelManager.calendarManager.msgMobile1")}</li>
                <li>{t("channelManager.calendarManager.msgMobile2")}</li>
                <li>{t("channelManager.calendarManager.msgMobile3")}</li>
              </ul>
            </div>
          </ModalSection>
          <ModalActions
            onClose={() => {
              setModalView(false);
            }}
          />
        </ModalCard>
      </Modal>
    </>
  );
};

export default CalendarCM;
