import { toast } from "react-toastify";
import { editFirestoreDocumentFileContent } from "../queries";
import { useTranslation } from "react-i18next";

  export const encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  export const handleFileContentEdit = async (
    context_id: string,
    file_id: string | undefined,
    firestore_document_id: string | undefined,
    new_content: string,
    setFileSavingStatus: (status: boolean) => void, 
    refetch_message_context_data: () => void,
    t: (key: string) => string
  ) => {
    setFileSavingStatus(true);
    try {
      let action = "edit_file_content";
      const response = await editFirestoreDocumentFileContent({
        context_id,
        file_id,
        firestore_document_id,
        new_content,
        action,
      });
  
      if (response) {
        toast.success(t(response.msg)); 
      } else {
        toast.error(t("general.KO"));
      }
    } catch (error: any) {
      console.log(error);
      toast.error(t(error)); 
    }
  
    refetch_message_context_data();
    setFileSavingStatus(false); 
  };