import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import { TableProps } from '../types';
import Icon from './Icon';
import PageTitle from './PageTitle';
import ProgressBar from './ProgressBar';
import TableColumnSelector from './TableColumnSelector';
import TableFilters from './TableFilters';

export default function BaseTable({
  onDelete,
  onEdit,
  className,
  pageCount = 0,
  currentPage = 0,
  columns = [],
  filters = [],
  loading,
  data = [],
  title,
  paginated = true,
  renderButtons,
  identifierKey,
  totalRows,
  filtersValue,
  displayRows = 15,
  hiddenColumns = [],
  renderQuickFilters,
  showFilters = true,
  showColumnSelector = true,
  onSortChanged,
  onFilterChange,
  onColumnChange,
  onRemoveFilters,
  onSorting,
  selectedRow,
  initialData,
}: TableProps) {
  const cols = useMemo(() => {
    return columns;
  }, [columns]);

  const { t } = useTranslation();
  const [count, setCount] = useState(1);

  let initialStateTemp: any;
  if(initialData && initialData.length > 0) {
    initialStateTemp = {
      hiddenColumns: hiddenColumns,
      pageSize: displayRows,
      sortBy: initialData.map((one) => {
        return {
          desc: one.desc,
          id: one.id,
        };
      }),
    }
  } else {
    initialStateTemp = {
      hiddenColumns: hiddenColumns,
      pageSize: displayRows,
    }
  }
  const tableInstance = useTable<any>(
    {
      columns: cols,
      data: data,
      manualPagination: true,
      manualSortBy: onSorting ? true : false,
      pageCount: pageCount,
      initialState: initialStateTemp,
    },
    useSortBy,
    usePagination,
    useFlexLayout
    // useResizeColumns
  );


  function isNextPageAvailable() {
    if (pageCount === currentPage) return false;
    return true;
  }

  function isPrevPageAvailable() {
    if (currentPage === 1) return false;
    return true;
  }

  useEffect(() => {
    if(onSorting && onSortChanged){
      const storing = localStorage.getItem('vikey_res_sorting')
      if(storing) {
        const dataParsed = JSON.parse(storing);
        if(dataParsed && dataParsed.length === 0){
          onSortChanged(tableInstance.state.sortBy, false)
          return;
        }
        if(dataParsed && dataParsed.length > 0){
          if(tableInstance.state.sortBy.length === 0){
            if(dataParsed[0]?.desc === true){
              if(count > 0){
                onSortChanged(dataParsed, false)
                setCount(0)
                return;
              }
              if(count === 0) {
                onSortChanged(tableInstance.state.sortBy, false)
                setCount(1)
                return;
              }
              return;
            }
            if(dataParsed[0]?.desc === false){
              onSortChanged(dataParsed, false)
              setCount(1)
              return;
            }
            return;
          }
          if(tableInstance.state.sortBy.length > 0){
            if(dataParsed[0].desc === false && tableInstance.state.sortBy[0]?.desc === true){
              setCount(0)
              onSortChanged(tableInstance.state.sortBy, false)
              return;
            }
            if(dataParsed[0].desc === false && tableInstance.state.sortBy[0]?.desc === false){
              onSortChanged(tableInstance.state.sortBy, false)
              return;
            }
            if(dataParsed[0].desc === true){
              onSortChanged(tableInstance.state.sortBy, false)
              return;
            }
            return;
          }
        }
        if(!dataParsed) {
          onSortChanged(tableInstance.state.sortBy, false)
        }
      } else {
        onSortChanged(tableInstance.state.sortBy, true)
        localStorage.setItem('vikey_res_sorting', JSON.stringify([]))
        localStorage.setItem('vikey_res_sorting_API', JSON.stringify([]))
      }
    }
    if (onSorting && !onSortChanged) {
      onSorting(tableInstance.state.sortBy);
    }
  }, [tableInstance.state.sortBy]);

  return (
    <div className={className}>
      <div
        className={`${
          renderQuickFilters ? 'mb-2' : ''
        } space-y-2 flex-row`}
      >
        <div className={'flex flex-col xl:items-center justify-center xl:space-x-4'}>
          {title && (
            <PageTitle
              noMargin={renderQuickFilters ? true : false}
              title={title}
            />
          )}

          {renderQuickFilters && (
            <div className={'block'}>{renderQuickFilters()}</div>
          )}
        </div>
        {((showFilters && filters && filters.length > 0) || renderButtons) && (
          <div
            className={'flex lg:flex-row items-center space-x-2 self-end mb-4 overflow-auto h-16'}
          >
            {filters && filters.length > 0 && (
                <TableFilters
                  columns={filters}
                  filtersValue={filtersValue}
                  onChange={(filters) => {
                    if (onFilterChange) {
                      onFilterChange(filters, 1);
                    }
                  }}
                />
              )}
              {showColumnSelector && (
                <TableColumnSelector
                  columns={tableInstance.allColumns}
                  onChange={(columns) => {
                    if (onColumnChange) onColumnChange(columns);
                  }}
                />
              )}
            {renderButtons && renderButtons()}
          </div>
        )}
      </div>
      <div
        className={
          'mt-2 bg-white cst-shadow overflow-auto rounded-2xl shadow-2xl border border-gray-200 relative'
        }
      >
        {loading && (
          <ProgressBar className={'absolute top-0 left-0'}></ProgressBar>
        )}
        <table className='w-full'>
          <thead>
            {tableInstance.headerGroups.map((group, index) => (
              <tr
                key={index}
                className={'uppercase text-xs text-gray-600 flex'}
              >
                {group.headers.map((column, index) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      // width: (!column.width || column.width === 150) ? 'initial' : column.width,
                      cursor: 'pointer',
                    }}
                    className={
                      'whitespace-nowrap w-full font-medium border-b px-4 py-3 text-center'
                    }
                  >
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <Icon
                          className={'inline-block ml-1'}
                          name={'chevron-down'}
                          size={'12px'}
                        ></Icon>
                      ) : (
                        <Icon
                          className={'inline-block ml-1'}
                          name={'chevron-up'}
                          size={'12px'}
                        ></Icon>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...tableInstance.getTableBodyProps()}>
            {tableInstance.rows.map((row, index) => {
              tableInstance.prepareRow(row);
              return (
                <tr
                  className={`cursor-pointer ${
                    selectedRow == index ? 'bg-cyan-400' : 'bg-gray-50'
                  } `}
                  {...row.getRowProps()}
                  onClick={() => {
                    if (onEdit)
                      onEdit(
                        row.original[identifierKey ? identifierKey : 'id'],
                        index
                      );
                  }}
                >
                  {row.cells.map((cell) => (
                    <td
                      className={`px-4 py-2 whitespace-nowrap w-full text-clip text-sm flex justify-center ${
                        index !== data.length - 1 ? '' : ''
                      } p-1 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                      {...cell.getCellProps()}
                    >
                      <p className='truncate'>
                        {cell.render('Cell')}
                      </p>
                    </td>
                  ))}
                </tr>
              );
            })}

            {tableInstance.rows.length === 0 && (
              <div>
                <div className={'w-full text-center'}>
                  <div className="text-center p-4">
                    <img src={'/search.svg'} className={'inline-block h-32'} />
                    <div className="mt-6 font-bold text-lg">
                      {t('table.noRes')}
                    </div>
                    <div className="text-gray-600 mt-1">
                      {t('table.noResDesc')}
                    </div>

                    <button
                      onClick={() => {
                        if (onRemoveFilters) onRemoveFilters();
                      }}
                      className={
                        'mt-2 bg-cyan-50 text-cyan-600 px-4 py-2 rounded-2xl text-sm'
                      }
                    >
                      {t('table.removeFilters')}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>

      {paginated && tableInstance.pageOptions.length > 1 && (
        <div className={'flex mt-6 items-center justify-between'}>
          <div className={'text-sm text-gray-600 font-medium'}>
          {t('table.pageOf', {number:currentPage, total: tableInstance.pageOptions.length > 0
              ? tableInstance.pageOptions.length
              : 1})}
          </div>

          <div className={'flex'}>
            <button
              style={{
                opacity: isPrevPageAvailable() ? 1 : 0.5,
              }}
              onClick={() => {
                if (!isPrevPageAvailable()) return;
                // tableInstance.previousPage()
                if (onFilterChange) onFilterChange(filters, currentPage - 1);
              }}
              className={
                'focus:outline-none mr-2 bg-white-600 shadow border px-3 text-sm font-medium py-1 border-gray-300 rounded-2xl'
              }
            >
              {t('table.prev')}
            </button>
            <button
              style={{
                opacity: isNextPageAvailable() ? 1 : 0.5,
              }}
              onClick={() => {
                if (!isNextPageAvailable()) return;
                // tableInstance.nextPage()
                if (onFilterChange) onFilterChange(filters, currentPage + 1);
              }}
              className={
                'focus:outline-none bg-white-600 shadow border px-3 text-sm font-medium py-1 border-gray-300 rounded-2xl'
              }
            >
              {t('table.next')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
