import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../Button";
import Icon from "../Icon";


export default function Glossary ({
  target
} : {
  target: string
}) {

  const { t } = useTranslation()
  const [visible, setVisible] = useState<boolean>(false)
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  
    

  const specialTags : {icon?:string, key: string, enable: {[CC: string]: number}, translate: string}[] = [
    {icon:"faq_mode"               , key : t("assistant.running_mode.faq")                     , enable : {ASS: 1} , translate : "assistant.running_mode.faq_usage"                                                    },
    {icon:"file_mode"              , key : t("assistant.running_mode.file")                    , enable : {ASS: 1} , translate : "assistant.running_mode.file_usage"                                                   },
    {icon:"autonomous_learning"    , key : t("assistant.learning_mode.autonomous_learning")    , enable : {ASS: 1} , translate : "assistant.learning_mode.autonomous_learning_usage"                                   },
    {icon:"nonautonomous_learning" , key : t("assistant.learning_mode.nonautonomous_learning") , enable : {ASS: 1} , translate : "assistant.learning_mode.nonautonomous_learning_usage"                                },
    {icon:"nonautonomous"          , key : t("assistant.response_mode.nonautonomous")          , enable : {ASS: 1} , translate : "assistant.response_mode.nonautonomous_usage"                                         },
    {icon:"autonomous"             , key : t("assistant.response_mode.autonomous")             , enable : {ASS: 1} , translate : "assistant.response_mode.autonomous_usage"                                            },
    {icon:"deploy_assistant"       , key : t("assistant.deploy")                               , enable : {ASS: 1} , translate : "assistant.deploy_usage"                                                              },
    {icon:"cancel_deploy"          , key : t("assistant.cancel_deploy")                        , enable : {ASS: 1} , translate : "assistant.cancel_deploy_usage"                                                       },
    {icon:"magic_wand"             , key : t("fileUpload.assistant.generateFaqs")              , enable : {ASS: 1} , translate : "assistant.generateFaqs_usage"                                                        },
    {icon:"magic_wand_empty"       , key : t("fileUpload.assistant.remove_faq_generation")     , enable : {ASS: 1} , translate : "assistant.remove_faq_generation_usage"                                               },
];

const toggleAccordion = (index: number) => {
  setOpenIndex(openIndex === index ? null : index);
};

  return (
    <div className={"bg-white cst-shadow relative "}>
      <table className={"table-auto w-full"}>
        <thead>
          <tr className={"text-sm"}>
            <th className={"py-2 text-left text-gray-600 font-small"}>{}</th>
            <th className={"py-2 text-left text-gray-600 font-small"}>
              {t("glossary.key")}
            </th>
          </tr>
        </thead>

        <tbody>
          {specialTags.map(
            (item, index) =>
              item.enable[target] === 1 && (
                <tr
                  className={"border-b text-sm hover:bg-cyan-100 cursor-pointer"}
                  onClick={() => toggleAccordion(index)}
                >
                  <td className={"py-2"}>
                    {item.icon ? (
                      <Icon name={item.icon} size="20px" />
                    ) : (
                      <div></div>
                    )}
                  </td>
                  <td
                    className={"py-2"}
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {item.key}
                    {openIndex === index && (
                      <div className={"py-4 ml-2"}>{t(item.translate)}</div>
                    )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
}