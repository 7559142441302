import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Modal from "../components/Modal";
import ModalActions from "../components/ModalAction";
import ModalCard from "../components/ModalCard";
import PageTitle from "../components/PageTitle";
import LoadingIntegrationSkeleton from "../components/integration/LoadingIntegrationSkeleton";
import {
  fetchMessageContextDatas,
  createMessageContextData,
  fetchAllModules,
} from "../shared/queries";
import Button from "../components/Button";
import { MessageContextDataLocal } from "../types";
import MessageContextDataListItem from "../components/messaging/MessageContextDataListItem";
import AssistantWizard from "../components/messaging/AssistantWizard";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { assistantStatusStyle } from "../shared/utils/CommonStyles";
import CircleSpinner from "../components/CircleSpinner";
import ModalSection from "../components/ModalSection";
import PageWrapper from "../components/PageWrapper";
import { moduleId } from "../shared/utils/config";
import { AssistantBubble } from "../components/assistant/AssistantBubble";

export default function AssistantsPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [addContext, setAddContext] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [displayMod, setDisplayMod] = useState({
    display: false,
    moduleName: "",
  });
  const [moduleFound, setModuelFound] = useState<boolean>();

  const {
    data,
    isLoading,
    isError,
    error,
    refetch: refetchAssistants,
  } = useQuery(
    ["messageContextDatas", searchTerm],
    () => fetchMessageContextDatas({ query: searchTerm }),
    {
      staleTime: 600,
      refetchOnWindowFocus: true,
      retry: 3,
    }
  );

  const handleOnComplete = () => {
    queryClient.invalidateQueries("messageContextDatas");
    setAddContext(false);
  };
  const { refetch: refetchDataModules } = useQuery<any>([], () => {
    fetchAllModules().then((data: any) => {
      const moduleFound = data?.modules?.find(
        (module: any) => module?.id === moduleId.ai_assistant
      );
      if (moduleFound) {
        if (moduleFound?.active_locals_count > 0) {
          setDisplayMod({
            display: false,
            moduleName: "",
          });
          setModuelFound(true);
        } else {
          setDisplayMod({
            display: true,
            moduleName: t(`module.id${moduleFound.id}`).toUpperCase(),
          });
          setModuelFound(false);
        }
      } else {
        setDisplayMod({
          display: true,
          moduleName: "NOTAVAILABLE",
        });
        setModuelFound(false);
      }
      return data;
    });
  });

  useEffect(() => {
    refetchDataModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="flex justify-between flex-wrap">
        <div className="flex items-center gap-2">
          <PageTitle
            title={t("assistant.assistants")}
            subtitle={t("assistant.assistantsContext")}
            isBetaFunction={true}
            betaIconSize="45px"
          />

        </div>

        <div>
          <Button
            onClick={() => setAddContext(true)}
            label={t("assistant.addAssistant")}
            icon={"document-add"}
            iconSize={"24px"}
          />
        </div>
      </div>
      {/* Search Input with Spinner */}
      <div className="relative flex-grow">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          placeholder={t("assistant.filter.searchAssistantOrLocal")}
          className="w-full border rounded-lg p-2 pr-10 focus:border-cyan-600 focus:outline-none min-w-[200px] sm:mt-2"
        />
        {isLoading && (
          <div className="absolute inset-y-0 right-2 flex items-center">
            <CircleSpinner color={"primary"} />
          </div>
        )}
      </div>

      {/* Display loading or empty message based on data state */}
      {/* {isLoading ? (
        <LoadingIntegrationSkeleton />
      ) : data?.length === 0 ? (
        <div>{t("assistant.noAssistantsAvailable")}</div>
      ) : ( */}
      <AssistantBubble data={data} />
      {/* )} */}
      <Modal visible={displayMod.display} className={"w-full"}>
        <ModalCard
          title={`${displayMod.moduleName.toUpperCase()} (${t(
            "module.statusInactive"
          )})`}
          className={"w-auto"}
        >
          <ModalSection>
            <PageWrapper>
              <span className="text-gray-600 font-medium">
                {t("module.buyModuleFunctionality", {
                  name: displayMod.moduleName.toUpperCase(),
                })}
              </span>
            </PageWrapper>
            <ModalActions
              onClose={() =>
                setDisplayMod({
                  display: false,
                  moduleName: "",
                })
              }
              onSave={() => {
                if (displayMod.moduleName === "NOTAVAILABLE") {
                  toast.error("NOTAVAILABLE");
                } else {
                  history.push(`/modules/module#${moduleId.ai_assistant}`);
                }
              }}
              saveLabel={t("module.purchase")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>

      {/* Modal for adding new context */}
      <Modal visible={addContext}>
        <ModalCard className="w-full max-w-4xl">
          <AssistantWizard onComplete={handleOnComplete} />
          <ModalActions onClose={() => setAddContext(false)} />
        </ModalCard>
      </Modal>
    </div>
  );
}
