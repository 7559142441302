import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
  saveItalyBuruAssoc,
  fetchV3AllLocals,
  fetchCredsMossos,
  fetchCredsMoTo,
  fetchCredsPoliciaNational,
  fetchCredsPortaleAlloggiati,
  savePoliciaNacionalBuruAssoc,
  saveGuardiaCivilBuruAssoc,
  saveMossosBuruAssoc,
  updateRicestat,
  fetchCredsGuardiaCivil,
  fetchCredsRossMille,
  fetchRossMilleLocals,
  saveRossMilleBuruAssoc,
  fetchCredsSef,
  fetchV3AllLocalsSef,
  savePortugalBuruAssoc,
  saveRadarBuruAssoc,
  fetchRadarLocals,
  fetchCredsRadar,
  fetchV3AllLocalsUby,
  fetchCredsUby,
  updateUby,
  fetchCredsPaytourist,
  fetchPaytouristLocals,
  updatePaytourist,
  fetchIstatTrentoLocals,
  fetchCredsIstatTrento,
  updateIstatTrento,
  fetchCredsIstatSicilia,
  updateIstatSicilia,
  fetchCredsSesHospedajes,
  saveSesHospedajesBuruAssoc,
} from "../../shared/queries";
import { getOptionList } from "../../shared/utils/formatting";
import { BaseSelectItem, ListApiResponseV3, LocalTable } from "../../types";
import Icon from "../Icon";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import SelectInput from "../SelectInput";
import TextInput from "../TextInput";
import Checkbox from "../Checkbox";
import ReactTooltip from "react-tooltip";
import Pagination from "../Pagination";
import { moduleId } from "../../shared/utils/config";

type CredsAssociation = {
  local_key: string;
  local_name: string;
  local_city: string;
  connected_name: string;
  id: number;
  account_id: number;
  airbnb_citytax?: boolean;
};

export default function AssignApartments({
  visible,
  channel,
  onCancel,
}: {
  channel: string | undefined;
  visible: boolean;
  onCancel: () => void;
}) {
  const [associations, setAssociations] = useState<CredsAssociation[]>([]);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const limitPage = 6;
  function getLocals(): any {
    switch (channel) {
      case "ross1000":
        return fetchRossMilleLocals({limit: limitPage, page: currentPage, module_id: moduleId.pa, search: searchValue});
      case "radar":
        return fetchRadarLocals({limit: limitPage, page: currentPage, module_id: moduleId.pa, search: searchValue});
      case "sef":
        return fetchV3AllLocalsSef({limit: limitPage, page: currentPage, module_id: moduleId.pa, search: searchValue});
      case "uby":
        return fetchV3AllLocalsUby({limit: limitPage, page: currentPage, module_id: moduleId.pa, search: searchValue});
      case "paytourist":
        return fetchPaytouristLocals({limit: limitPage, page: currentPage, module_id: moduleId.paytourist, search: searchValue});
      case "istat-trento":
        return fetchIstatTrentoLocals({limit: limitPage, page: currentPage, module_id: moduleId.pa, search: searchValue});
      default:
        return fetchV3AllLocals({limit: limitPage, page: currentPage, module_id: moduleId.pa, search: searchValue});
    }
  }
  const { data: apartments, isFetching: loadingApartments } = useQuery<
    ListApiResponseV3<LocalTable>,
    Error
  >(["localsAssign", visible, currentPage, searchValue], () => getLocals(), {
    onSuccess: (data) => {
      if (data && data.results) {
        setAssociations(
          data.results.map((apartment) => {
            const element = {
              app_name: "",
              local_key: apartment.local_key,
              local_name: apartment.public_name,
              local_city: apartment.city,
              app_id: 0,
              creds_id: "",
            } as any;

            if (channel === "portale-alloggiati") {
              element.creds_id = apartment.creds_portale_alloggiati_id;
              element.app_id = apartment.alloggiati_id;
            }
            if (channel === "paytourist") {
              element.airbnb_citytax =
                apartment?.paytourist_cred[0]?.airbnb_citytax ?? false;
              element.creds_id = apartment?.paytourist_cred[0]?.id;
            }
            if (channel === "istat-trento") {
              element.creds_id = apartment?.trento_cred[0]?.id;
            }
            if (channel === "istat-sicilia") {
              element.creds_id = apartment?.sicilia_cred[0]?.sicilia_creds_id;
              element.app_id = apartment?.sicilia_cred[0]?.sicilia_camera_id;
            }
            if (channel === "ross1000") {
              element.creds_id = apartment?.ross1000_cred[0]?.id;
            }
            if (channel === "radar") {
              element.creds_id = apartment?.radar_cred[0];
            }
            if (channel === "sef") {
              element.creds_id = apartment?.sef_cred[0]?.id;
            }
            if (channel === "mossos-esquadra") {
              element.creds_id = apartment.mossos_creds_id;
              element.app_name = apartment.spain_apartment_name;
              element.app_id = apartment.spain_apartment_id;
            }
            if (channel === "guardia-civil") {
              element.creds_id = apartment.guardia_civil_creds_id;
              element.app_name = apartment.spain_apartment_name;
              element.app_id = apartment.spain_apartment_id;
            }
            if (channel === "ses-hospedajes") {
              element.creds_id = apartment?.hospedajes_cred[0]?.hospedajes_creds_id;
            }
            if (channel === "policia-nacional") {
              element.creds_id = apartment.policia_nacional_creds_id;
              element.app_name = apartment.spain_apartment_name;
              element.app_id = apartment.spain_apartment_id;
            }

            if (channel === "motourist") {
              element.airbnb_citytax =
                apartment?.ricestat_cred[0]?.airbnb_citytax ?? false;
              element.creds_id = apartment?.ricestat_cred[0]?.ricestat_creds_id;
              element.app_id = apartment?.ricestat_cred[0]?.ricestat_camera_id;
            }
            if (channel === "uby") {
              element.creds_id = apartment?.czech_uby_cred[0];
            }

            return element;
          })
        );
      }
    },
    enabled: visible,
    retry: 0
  });

  function getCredsQuery(): any {
    switch (channel) {
      case "portale-alloggiati":
        return fetchCredsPortaleAlloggiati;
      case "paytourist":
        return fetchCredsPaytourist;
      case "istat-trento":
        return fetchCredsIstatTrento;
      case "istat-sicilia":
        return fetchCredsIstatSicilia;
      case "ross1000":
        return fetchCredsRossMille;
      case "radar":
        return fetchCredsRadar;
      case "sef":
        return fetchCredsSef;
      case "mossos-esquadra":
        return fetchCredsMossos;
      case "policia-nacional":
        return fetchCredsPoliciaNational;
      case "guardia-civil":
        return fetchCredsGuardiaCivil;
      case "ses-hospedajes":
        return fetchCredsSesHospedajes;
      case "motourist":
        return fetchCredsMoTo;
      case "uby":
        return fetchCredsUby;
      default:
        break;
    }
  }

  const { data: creds, isLoading: isLoadingCreds } = useQuery<any, Error>(
    ["creds", channel, visible],
    getCredsQuery(),
    {
      initialData: [],
      enabled: visible,
    }
  );

  function isNameNeeded() {
    switch (channel) {
      case "portale-alloggiati":
        return false;
      case "paytourist":
        return false;
      case "istat-trento":
        return false;
      case "istat-sicilia":
        return false;
      case "ross1000":
        return false;
      case "radar":
        return false;
      case "sef":
        return false;
      case "mossos-esquadra":
        return true;
      case "policia-nacional":
        return true;
      case "guardia-civil":
        return true;
      case "ses-hospedajes":
        return false;
      case "motourist":
        return false;
      case "uby":
        return false;
      default:
        break;
    }
  }

  function getMutationBody(data: any) {
    if (channel === "portale-alloggiati") {
      return saveItalyBuruAssoc({
        local_key: data.local_key,
        creds_portale_alloggiati_id: data.creds_id,
        alloggiati_id: data.app_id,
      });
    } else if (channel === "sef") {
      return savePortugalBuruAssoc({
        local_key: data.local_key,
        sef_creds_id: data.creds_id,
      });
    } else if (channel === "policia-nacional") {
      return savePoliciaNacionalBuruAssoc({
        local_key: data.local_key,
        policia_nacional_creds_id: data.creds_id,
        spain_apartment_name: data.app_name,
        spain_apartment_id: data.app_id,
      });
    } else if (channel === "ross1000") {
      return saveRossMilleBuruAssoc({
        local_key: data.local_key,
        ross1000_creds_id: data.creds_id ?? null,
      });
    } else if (channel === "radar") {
      return saveRadarBuruAssoc({
        local_key: data.local_key,
        radar_creds_id: data.creds_id ?? null,
      });
    } else if (channel === "mossos-esquadra") {
      return saveMossosBuruAssoc({
        local_key: data.local_key,
        mossos_creds_id: data.creds_id,
        spain_apartment_name: data.app_name,
        spain_apartment_id: data.app_id,
      });
    } else if (channel === "guardia-civil") {
      return saveGuardiaCivilBuruAssoc({
        local_key: data.local_key,
        guardia_civil_creds_id: data.creds_id,
        spain_apartment_name: data.app_name,
        spain_apartment_id: data.app_id,
      });
    } else if (channel === "ses-hospedajes") {
      return saveSesHospedajesBuruAssoc({
        local_key: data.local_key,
        hospedajes_creds_id: data.creds_id,
      });
    } else if (channel === "motourist") {
      return updateRicestat({
        local_key: data.local_key,
        ricestat_creds_id: data.creds_id,
        ricestat_camera_id: data.app_id,
        airbnb_citytax: data.airbnb_citytax,
      });
    } else if (channel === "uby") {
      return updateUby({
        local_key: data.local_key,
        czech_uby_creds_id: data.creds_id,
      });
    } else if (channel === "paytourist") {
      return updatePaytourist({
        local_key: data.local_key,
        paytourist_creds_id: data.creds_id,
        airbnb_citytax: data.airbnb_citytax,
      });
    } else if (channel === "istat-trento") {
      return updateIstatTrento({
        local_key: data.local_key,
        trento_creds_id: data.creds_id,
      });
    }else if (channel === "istat-sicilia") {
      return updateIstatSicilia({
        sicilia_creds_id: data.creds_id,
        sicilia_camera_id: data.app_id,
        local_key: data.local_key,
      });
    }

    return new Promise(() => {});
  }

  const updateMutation = useMutation((data: any) => getMutationBody(data), {
    onSuccess: () => {},
  });

  function getOptionValue() {
    switch (channel) {
      case "portale-alloggiati":
        return "creds_portale_alloggiati_id";
      case "paytourist":
        return "id";
      case "istat-trento":
        return "id";
      case "istat-sicilia":
        return "sicilia_creds_id";
      case "ross1000":
        return "id";
      case "radar":
        return "id";
      case "mossos-esquadra":
        return "mossos_creds_id";
      case "guardia-civil":
        return "guardia_civil_creds_id";
      case "ses-hospedajes":
          return "hospedajes_creds_id";
      case "policia-nacional":
        return "policia_nacional_creds_id";
      case "motourist":
        return "ricestat_creds_id";
      case "sef":
        return "id";
      case "uby":
        return "id";
      default:
        return "key";
    }
  }

  return (
    <Modal visible={visible}>
      <ModalCard
        title={t("paymentMethods.associateApartments")}
        className={"w-full"}
        renderActions={() => (
          <ModalActions
            saveLabel={t("general.save")}
            closeLabel={t("general.cancel")}
            onSave={() => {
              onCancel();
            }}
            onClose={() => onCancel()}
          />
        )}
      >
        <div className="">
        <div className="relative bg-white rounded-xl border-gray-300 border h-8 my-4 md:w-64 ml-4">
        <input
          style={{
            height: "100%",
            width: "100%",
          }}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setCurrentPage(1);
          }}
          placeholder={t("general.searchForApartment")}
          className={
            "relative flex items-center bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl truncate"
          }
        />
        <Icon
          name={"home"}
          size={"18px"}
          className={"absolute top-1 right-1"}
        />
      </div>
          <div className="px-4 hidden lg:flex mb-6 space-x-6">
            <div
              className={`${
                isNameNeeded() ? "w-1/4" : "w-1/3"
              } text-lg font-medium text-gray-600`}
            >
              {t("navigation.apartments")}
            </div>

            <div
              className={`${
                isNameNeeded() ? "w-1/4" : "w-1/3"
              } text-lg font-medium text-gray-600`}
            >
              {t("general.account")}
            </div>

            {isNameNeeded() && (
              <div className={`w-1/4 text-lg font-medium text-gray-600`}>
                {t("apartments.name")}
              </div>
            )}

            {channel !== "sef" &&
            channel !== "ross1000" &&
            channel !== "radar" &&
            channel !== "uby" &&
            channel !== "paytourist" &&
            channel !== "ses-hospedajes" &&
            channel !== "istat-trento" ? (
              <div
                className={`${
                  isNameNeeded() ? "w-1/4" : "w-1/3"
                } text-lg font-medium text-gray-600`}
              >
                {channel === 'istat-sicilia' ? 'Camera ID' : 'ID'}
              </div>
            ) : null}

            {channel === "motourist" ? (
            // {channel === "motourist" || channel === "paytourist" ? (
              <div
                className={`${
                  isNameNeeded() ? "w-1/4" : "w-1/3"
                } text-lg font-medium text-gray-600 flex items-center`}
              >
                {t("invoices.actions")}
                <div data-tip={t("apartments.cityTaxPaidAirbnbInfo")}>
                  <Icon
                    name={"information-circle"}
                    className={"ml-2 cursor-pointer"}
                    size={"15px"}
                  ></Icon>
                </div>
                <ReactTooltip />
              </div>
            ) : null}

            <div></div>
          </div>
          {!loadingApartments && associations.map((association: any, index) => (
            <div
              className="px-4 lg:flex lg:space-y-0 space-y-3 items-center space-x-6 mb-3 border-b pb-4 mx-3"
              key={index}
            >
              <div
                className={`${
                  isNameNeeded() ? "w-1/4" : "lg:w-1/3 w-full"
                } flex flex-col`}
              >
                <div className={"font-medium text-gray-700"}>
                  {association.local_name}
                </div>
                <div
                  className={
                    "text-sm text-gray-500 flex space-x-2 items-center"
                  }
                >
                  <div>
                    <Icon name={"location-marker"} size={"14px"} />
                  </div>
                  <div>{association.local_city || "ND"}</div>
                </div>
              </div>
              <div
                className={`${
                  isNameNeeded() ? "lg:w-1/4 w-full" : "lg:w-1/3 w-full"
                } lg:block flex items-center justify-between`}
              >
                <div
                  className={`lg:hidden block text-lg font-medium text-gray-600`}
                >
                  {t("general.account")}
                </div>
                <SelectInput<BaseSelectItem, false>
                  menuPlacement={"top"}
                  isClearable={true}
                  value={getOptionList(
                    creds?.results,
                    channel === "sef"
                      ? ["chiaveacesso", "unidadehoteleira", "estabelecimento"]
                      : channel === "ross1000" || channel === "radar"
                      ? ["username", "accomodation_id"]
                      : channel === "paytourist"
                      ? ["id", "accomodation_id"]
                      : channel === "istat-trento"
                      ? "id"
                      : channel === "istat-sicilia"
                      ? ["sicilia_creds_id", "hotel_code"]
                      : "username",
                    getOptionValue(),
                    channel === "sef" ? false : true
                  ).find((d) => d.value === association.creds_id)}
                  isLoading={isLoadingCreds}
                  menuShouldScrollIntoView={false}
                  menuPortalTarget={document.body}
                  styles={{
                    container: (base) => ({ ...base, zIndex: 999 }),
                    menuPortal: (base) => ({ ...base, zIndex: 999 }),
                  }}
                  options={getOptionList(
                    creds?.results,
                    channel === "sef"
                      ? ["chiaveacesso", "unidadehoteleira", "estabelecimento"]
                      : channel === "ross1000" || channel === "radar"
                      ? ["username", "accomodation_id"]
                      : channel === "paytourist"
                      ? ["id", "accomodation_id"]
                      : channel === "istat-trento"
                      ? "id" : channel === "istat-sicilia"
                      ? "sicilia_creds_id"
                      : channel === "ses-hospedajes" ? ["username", "establecimiento_code"]
                      : "username",
                    getOptionValue(),
                    channel === "sef" ? false : true
                  )}
                  onChange={(item) => {
                    // if (item) {
                    setAssociations(
                      associations.map((ac, acIndex) => {
                        if (acIndex === index) {
                          return {
                            ...ac,
                            creds_id: item?.value,
                          };
                        }
                        return ac;
                      })
                    );

                    updateMutation.mutate({
                      ...association,
                      creds_id: item?.value,
                    });
                    // }
                  }}
                />
              </div>

              {isNameNeeded() && (
                <div
                  className={
                    "text-lg font-medium text-gray-600 lg:w-1/4 w-full lg:block flex items-center justify-between"
                  }
                >
                  <div
                    className={`block lg:hidden text-lg font-medium text-gray-600 w-full`}
                  >
                    {t("apartments.name")}
                  </div>
                  <TextInput
                    value={association.app_name}
                    onChange={(value) => {
                      setAssociations(
                        associations.map((ac, acIndex) => {
                          if (acIndex === index) {
                            return {
                              ...ac,
                              app_name: value,
                            };
                          }
                          return ac;
                        })
                      );

                      if (value) {
                        updateMutation.mutate({
                          ...association,
                          app_name: value,
                        });
                      }
                    }}
                  />
                </div>
              )}

              {channel !== "sef" &&
              channel !== "ross1000" &&
              channel !== "radar" &&
              channel !== "uby" &&
              channel !== "paytourist" &&
              channel !== "ses-hospedajes" &&
              channel !== "istat-trento" ? (
                <div
                  className={`${
                    isNameNeeded() ? "lg:w-1/4 w-full" : "lg:w-1/3 w-full"
                  } lg:block flex items-center justify-between`}
                >
                  <div
                    className={`block lg:hidden text-lg font-medium text-gray-600 w-full`}
                  >
                    ID
                  </div>
                  <div className="lg:w-full w-1/2">
                    <TextInput
                      value={association.app_id}
                      onChange={(value) => {
                        setAssociations(
                          associations.map((ac, acIndex) => {
                            if (acIndex === index) {
                              return {
                                ...ac,
                                app_id: value,
                              };
                            }
                            return ac;
                          })
                        );
                        setTimeout(() => {
                          if (value) {
                            updateMutation.mutate({
                              ...association,
                              app_id: value,
                            });
                          } else {
                            updateMutation.mutate({
                              ...association,
                              app_id: null,
                            });
                          }
                        }, 500);
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {channel === "motourist" || channel === "paytourist" ? (
                <div
                  className={`${
                    isNameNeeded() ? "lg:w-1/4 w-full" : "lg:w-1/3 w-full"
                  } lg:block flex items-center justify-between`}
                >
                  <div
                    className={`lg:hidden text-lg font-medium text-gray-600 flex items-center`}
                  >
                    {t("invoices.actions")}
                    <div data-tip={t("apartments.cityTaxPaidAirbnbInfo")}>
                      <Icon
                        name={"information-circle"}
                        className={"ml-0.5 cursor-pointer"}
                        size={"15px"}
                      ></Icon>
                    </div>
                    <ReactTooltip />
                  </div>
                  <Checkbox
                    size="sm"
                    label={t("apartments.cityTaxPaidAirbnb")}
                    checked={association.airbnb_citytax}
                    onChange={() => {
                      setAssociations(
                        associations.map((ac, acIndex) => {
                          if (acIndex === index) {
                            return {
                              ...ac,
                              airbnb_citytax: !ac.airbnb_citytax,
                            };
                          }
                          return ac;
                        })
                      );
                      setTimeout(() => {
                        updateMutation.mutate({
                          ...association,
                          airbnb_citytax: !association.airbnb_citytax,
                        });
                      }, 500);
                    }}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </div>
        {apartments && apartments?.tot_locals && apartments?.tot_locals > 1 ? (
        <div className="mt-5">
          <Pagination
            page={currentPage}
            pageSize={limitPage}
            totalItems={apartments?.tot_locals as number}
            onPageUpdate={(value) => {
              if (!loadingApartments) {
                if (value === "+") {
                  setCurrentPage((prev) => prev + 1);
                  return;
                }
                if (value === "-") {
                  setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
                  return;
                }
                setCurrentPage(+value);
              }
            }}
          />
        </div>
      ) : null}
      </ModalCard>
    </Modal>
  );
}
