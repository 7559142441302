import React, { FC, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { fetchAvailableChannels, updateAvalableChannels } from "../../shared/queries";
import { AnimatePresence, motion } from "framer-motion";
import ReactDOM from "react-dom";
import Button from "../Button";
import CircleSpinner from "../CircleSpinner";
import { useTranslation } from "react-i18next";
import Checkbox from "../Checkbox";

import { AssistantLocalChannel } from "../../types";

interface EditApartmentModalProps {
  visible: boolean;
  onClose?: () => void;
  refetchApartments?: () => void;
  local_key: string;
  context_id?: string;
  className?: string;
  noBackdropDismiss?: boolean;
}

const EditAssistantApartmentConnectedChannelModal: FC<
  EditApartmentModalProps
> = ({
  visible,
  onClose,
  refetchApartments,
  local_key,
  className = "",
  noBackdropDismiss = false,
  context_id,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { data, isLoading, error, refetch:refetch_availableChannels} = useQuery(
    ["availableChannels", local_key],
    () =>
      fetchAvailableChannels({ local_key: local_key, context_id: context_id }),
    {
      enabled: visible && !!local_key,
    }
  );

  const [channels, setChannels] = useState<AssistantLocalChannel[]>([]);

  useEffect(() => {
    if (data?.results) {
      setChannels(data.results);
    }
  }, [data]);

  const toggleActive = (index: number) => {
    setChannels((prevChannels) =>
      prevChannels.map((channel, ch_index) =>
        ch_index === index
          ? { ...channel, active: !channel.active }
          : channel
      )
    );
  };

  const handleSave = async () => {
    console.log("Saved channels:", channels);
    const response = await updateAvalableChannels({
        local_key:local_key,context_id:context_id,available_channels:channels
    })
    if (response){
        refetch_availableChannels()
        if(refetchApartments){
            refetchApartments()
        }
        if(onClose){
            onClose()
        }
    }
  };

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      !noBackdropDismiss &&
      overlayRef.current &&
      event.target === overlayRef.current &&
      onClose
    ) {
      onClose();
    }
  };

  if (!visible) return null;

  return ReactDOM.createPortal(
    <AnimatePresence>
      {visible && (
        <motion.div
          ref={overlayRef}
          className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ${className}`}
          onClick={handleBackdropClick}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <motion.div
            className="relative bg-white max-w-[90vw] max-h-[85vh] rounded-lg shadow-md overflow-auto"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-4">
                {t("assistant.editConnection")}
              </h3>

              {isLoading && (
                <div className="flex items-center justify-center h-full">
                  <CircleSpinner color="primary" />
                </div>
              )}

              {error && (
                <div className="flex items-center justify-center">
                  Error loading available channels
                </div>
              )}

              {channels.length > 0 && (
                <div>
                  <h4 className="font-medium">
                    {t("assistant.availableConnectionChannels")}
                  </h4>
                  <ul>
                    {channels.map((channel, index) => (
                      <li key={index} className="mb-2">
                        <Checkbox
                          checked={channel.active}
                          label={t("assistant.activeFor" + channel.channel)}
                          disabled={!channel.available}
                          className="text-sm xs:text-base"
                          onChange={() => toggleActive(index)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="flex justify-between items-center mt-6">
                {/* Close Button */}
                <Button
                onClick={onClose}
                color={'negative'}
                flat
                label={t("general.cancel")}
                disabled={isLoading}
                />
                {/* Save Button */}
                <Button
                onClick={handleSave}
                  loading={isLoading}
                  className="text-white bg-cyan-600 hover:bg-cyan-300 focus:outline-none shadow-md rounded-lg px-4 py-2"
                  disabled={isLoading}>{t("general.save")}</Button>

              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default EditAssistantApartmentConnectedChannelModal;
