import React, { useEffect, useState } from "react";
import Button from "../Button";
import { FaqEntry } from "../../types";
import SelectInput from "../SelectInput";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import ReactTooltip from "react-tooltip";
import Icon from "../Icon";

interface FaqListProps {
  faqs: Array<FaqEntry>;
  emptyMessage: string;
  questionLabel: string;
  answerLabel: string;
  onAvailabilityChange: (
    index: number,
    data: { id: number; label: string; value: string }[]
  ) => void;
  onQuestionChange: (index: number, value: string) => void;
  onAnswerChange: (index: number, value: string) => void;
  onDeleteFaq: (index: number, entry_id?: string) => void;
  errors: Record<number, string[]>; // Added to hold errors for each FAQ
}

const FaqList: React.FC<FaqListProps> = ({
  faqs,
  onQuestionChange,
  onAnswerChange,
  onAvailabilityChange,
  onDeleteFaq,
  emptyMessage,
  questionLabel,
  answerLabel,
  errors,
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const clearSearch = () => {
    setSearch("");
  };

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const { t } = useTranslation();
  const truncate = (str: string, length: number) => {
    return str.length > length ? str.substring(0, length) + "..." : str;
  };
  const [search, setSearch] = useState<string>();

  const reservation_available_options = [
    { id: 5, label: t("assistant.faqAvailability.all"), value: "ALL" },
    { id: 1, label: t("resv.confirmed"), value: "OK" },
    { id: 2, label: t("resv.waitingConfirmation"), value: "PEND" },
    { id: 3, label: t("resv.waitingDeposit"), value: "WAIT_DEPOSIT" },
    { id: 4, label: t("resv.notExecuted"), value: "WAIT" },
    { id: 6, label: t("resv.notNecessary"), value: "NONEED" },
  ];
  const [
    reservationAvailableStatusFilter,
    setReservationAvailableStatusFilter,
  ] = useState<string>("false");
  const [filteredFaqs, setFilteredFaqs] = useState(faqs);

  const toggleFilter = (value: string) => {
    if (value !== reservationAvailableStatusFilter) {
      setReservationAvailableStatusFilter(value);
    } else {
      setReservationAvailableStatusFilter("false");
    }
  };
  useEffect(() => {
    let filtered = faqs;

    // Filter by reservation status if it's not "false"
    if (reservationAvailableStatusFilter !== "false") {
      filtered = filtered.filter(
        (faq) =>
          Array.isArray(faq.resv_status) &&
          faq.resv_status.some(
            (resv) =>
              (typeof resv === "object" &&
                resv.resv_status === reservationAvailableStatusFilter) ||
              (typeof resv !== "object" &&
                resv === reservationAvailableStatusFilter)
          )
      );
    }

    // Apply search filtering
    if (search && search.trim() !== "") {
      filtered = filtered.filter(
        (faq) =>
          faq.question?.toLowerCase().includes(search.toLowerCase()) ||
          faq.proposed_answer?.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredFaqs(filtered);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationAvailableStatusFilter, faqs, search]);

  if (faqs.length === 0) {
    return (
      <div className="flex justify-center items-center h-32">
        <p className="text-gray-500">{emptyMessage}</p>
      </div>
    );
  }

  return (
    <>
      <div className="relative flex-grow my-2">
        <input
          type="text"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder={t("assistant.filter.search")}
          className="w-full border rounded-lg p-2 pr-10 focus:border-cyan-600 focus:outline-none min-w-[200px]"
        />

        {search && search.trim() !== "" && (
          <button
            type="button"
            onClick={clearSearch}
            className="absolute text-gray-400 right-2 top-1/2 transform -translate-y-1/2 bg-transparent border-none cursor-pointer"
          >
            <Icon name="close" size="20px" />
          </button>
        )}
      </div>
      {faqs.length > 0 && (
        <div className="flex flex-wrap gap-2 border-2 rounded-lg p-2 text-xs w-full">
          {reservation_available_options.map((option) => (
            <div
              key={option.id}
              className={`${
                reservationAvailableStatusFilter === option.value
                  ? "bg-cyan-100 text-cyan-800"
                  : ""
              } focus:outline-none hover:bg-cyan-100 rounded-lg cursor-pointer px-3 py-1 text-sm font-semibold truncate`}
              onClick={() => toggleFilter(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}

      <div>
        <AnimatePresence>
          {filteredFaqs.length === 0 ? (
            <div className="flex justify-center items-center h-32">
              <p className="text-gray-500">{emptyMessage}</p>
            </div>
          ) : (
            filteredFaqs.map((faq, index) => (
              <>
                {/* {console.log(faq)} */}
                <motion.div
                  key={faq.entry_id || index}
                  className="border border-gray-300 rounded-lg mt-2 overflow-hidden"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  {/* Accordion Header */}
                  <div
                    onClick={() => toggleAccordion(index)}
                    className={`flex flex-col lg:flex-row justify-between items-start px-4 py-2 cursor-pointer ${
                      errors[index] &&
                      (errors[index].includes("question_validation_error") ||
                        errors[index].includes("answer_validation_error"))
                        ? "bg-red-500 transition-colors hover:bg-red-600"
                        : "bg-gray-100 hover:bg-gray-200"
                    } `}
                  >
                    <h3 className="font-semibold text-gray-800 w-full">
                      {faq.question || `${questionLabel} ${index + 1}`}
                    </h3>

                    <div className="flex gap-2 mt-2 sm:mt-0 flex-row ml-auto">
                      <div className="flex flex-col text-sm">
                        <span className="font-semibold">
                          {t("assistant.faqs.selectAvailability")}
                        </span>
                        <span
                          className="text-gray-700 truncated-label"
                          data-tip={
                            reservation_available_options
                              ?.filter((opt) =>
                                faq.resv_status?.some(
                                  (resv) =>
                                    (typeof resv === "object" &&
                                      resv.resv_status === opt.value) ||
                                    (typeof resv !== "object" &&
                                      resv === opt.value)
                                )
                              )
                              .map((opt) => opt.label)
                              .join(", ") ?? ""
                          }
                        >
                          {reservation_available_options
                            ?.filter((opt) =>
                              faq.resv_status?.some(
                                (resv) =>
                                  (typeof resv === "object" &&
                                    resv.resv_status === opt.value) ||
                                  (typeof resv !== "object" &&
                                    resv === opt.value)
                              )
                            )
                            .map((opt) => opt.label)
                            .join(", ") ?? ""}
                        </span>
                        <ReactTooltip />
                      </div>

                      <Button
                        icon="trash"
                        onClick={(e) => {
                          e.stopPropagation();
                          console.log(index);
                          console.log(faq);
                          onDeleteFaq(index, faq.entry_id);
                        }}
                        className="h-9 flex-shrink-0 rounded-2xl bg-cyan-500 text-white  hover:bg-red-600 flex items-center justify-center"
                      />
                      <Button
                        icon={openIndex === index ? "collapse" : "expand"}
                        className="h-9 flex-shrink-0 rounded-2xl bg-cyan-500 text-white  hover:bg-cyan-300 flex items-center justify-center"
                      />
                    </div>
                  </div>

                  {/* Accordion Content */}
                  {openIndex === index && (
                    <>
                      <div className="p-4">
                        <label className="block font-semibold">
                          {t("assistant.faqs.selectAvailability")}
                        </label>
                        <SelectInput
                          styles={{
                            container: (base) => ({ ...base, zIndex: 50 }),
                            menuPortal: (base) => ({ ...base, zIndex: 50 }),
                          }}
                          isMulti={true}
                          menuShouldScrollIntoView={false}
                          menuShouldBlockScroll
                          menuPortalTarget={document.body}
                          options={reservation_available_options}
                          defaultValue={reservation_available_options?.filter(
                            (opt) =>
                              faq.resv_status?.some((status) =>
                                typeof status === "object"
                                  ? status.resv_status === opt.value
                                  : status === opt.value
                              )
                          )}
                          value={reservation_available_options?.filter((opt) =>
                            faq.resv_status?.some((status) =>
                              typeof status === "object"
                                ? status.resv_status === opt.value
                                : status === opt.value
                            )
                          )}
                          onChange={(item: any) =>
                            onAvailabilityChange(index, item)
                          }
                        />
                      </div>

                      <div className="p-4 bg-white">
                        <div className="mb-4">
                          <label className="block font-semibold">
                            {questionLabel}
                          </label>
                          <textarea
                            className={`w-full rounded-lg p-2 pr-12 bg-gray-50 border-b-2 shadow-md ${
                              errors[index] &&
                              errors[index].includes(
                                "question_validation_error"
                              )
                                ? "border-red-500 transition-colors"
                                : "border-gray-300"
                            } drop-shadow-lg focus:outline-none focus:ring-2 ${
                              errors[index] &&
                              errors[index].includes(
                                "question_validation_error"
                              )
                                ? "focus:ring-red-500"
                                : "focus:ring-cyan-600"
                            } focus:bg-white`}
                            value={faq.question}
                            onChange={(e) =>
                              onQuestionChange(index, e.target.value)
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block font-semibold">
                            {answerLabel}
                          </label>
                          <textarea
                            className={`w-full rounded-lg p-2 pr-12 bg-gray-50 border-b-2 shadow-md ${
                              errors[index] &&
                              errors[index].includes("answer_validation_error")
                                ? "border-red-500 transition-colors"
                                : "border-gray-300"
                            } drop-shadow-lg focus:outline-none focus:ring-2 ${
                              errors[index] &&
                              errors[index].includes("answer_validation_error")
                                ? "focus:ring-red-500"
                                : "focus:ring-cyan-600"
                            } focus:bg-white`}
                            value={faq.proposed_answer}
                            onChange={(e) =>
                              onAnswerChange(index, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </motion.div>
              </>
            ))
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default FaqList;
