import React, { useState } from "react";
import PageWrapper from "../PageWrapper";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import CircleSpinner from "../CircleSpinner";
import AssistantApartmentConnection from "./AssistantApartmentConnection";
import { fetchLocalsMini } from "../../shared/queries";
import Pagination from "../Pagination";

interface AssistantConnectionsProps {
  context_id?: string;
}

const AssistantConnections: React.FC<AssistantConnectionsProps> = ({
  context_id,
}) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterAptAss, setFilterAptAss] = useState<string>("false");

  const {
    data: locals,
    isFetching: isFetchingLocals,
    refetch: refetchAllLocals,
  } = useQuery(["localsv3", page, pageSize, searchTerm, filterAptAss], () =>
    fetchLocalsMini({
      action: "assistant_locals",
      page: page,
      limit: pageSize,
      search: searchTerm,
      filter: filterAptAss,
    })
  );

  const {
    data: assistantLocals,
    refetch: refetchAllConnectedLocals,
    isFetching: isFetchingconnectedLocals,
  } = useQuery(["assistantLocals"], () =>
    fetchLocalsMini({ action: "assistant_locals", context_id: context_id })
  );
  // console.log("assistant locals ", assistantLocals);
  const isSameOrigin = (item_context_id: string) => {
    return item_context_id === context_id;
  };

  const refetchAll = () => {
    refetchAllLocals();
    refetchAllConnectedLocals();
  };

  const isSameLocal = (local_key: string) => {
    const connected_local = assistantLocals?.results
      ?.flat()
      .find((local) => local.local_key === local_key);
    if (connected_local) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="flex flex-wrap items-center gap-4 mb-4 w-full">
        {/* Search Input with Spinner */}
        <div className="relative flex-grow">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => {
              setPage(1);
              setSearchTerm(e.target.value);
            }}
            placeholder={t("assistant.filter.search")}
            className="w-full border rounded-lg p-2 pr-10 focus:border-cyan-600 focus:outline-none min-w-[200px]"
          />
          {isFetchingLocals && (
            <div className="absolute inset-y-0 right-2 flex items-center">
              <CircleSpinner color={"primary"} />
            </div>
          )}
        </div>

        {/* Filter Options */}
        <div className="flex flex-wrap gap-2 border-2 rounded-lg p-2 text-xs w-full">
          <div
            className={`${
              filterAptAss === "false" && "bg-cyan-100 text-cyan-800"
            } focus:outline-none hover:bg-cyan-100 rounded-lg cursor-pointer px-3 py-1 text-sm font-semibold truncate`}
            onClick={() => {
              setFilterAptAss("false");
              setPage(1);
            }}
          >
            {t("reservations.all")}
          </div>
          <div
            className={`${
              filterAptAss === "associated" && "bg-cyan-100 text-cyan-800"
            } focus:outline-none hover:bg-cyan-100 rounded-lg cursor-pointer px-3 py-1 text-sm font-semibold truncate`}
            onClick={() => {
              setFilterAptAss("associated");
              setPage(1);
            }}
          >
            {t("general.filterAptAssociated")}
          </div>
          <div
            className={`${
              filterAptAss === "notassociated" && "bg-cyan-100 text-cyan-800"
            } focus:outline-none hover:bg-cyan-100 rounded-lg cursor-pointer px-3 py-1 text-sm font-semibold truncate`}
            onClick={() => {
              setFilterAptAss("notassociated");
              setPage(1);
            }}
          >
            {t("general.filterAptNotAssociated")}
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div
        className="flex flex-col w-full overflow-y-auto"
        style={{ maxHeight: "60vh" }}
      >
        {!isFetchingLocals && !isFetchingconnectedLocals && (
          <>
            {locals?.results?.map((local: any) => (
              <div key={local.local_key} className="w-full mb-4">
                <AssistantApartmentConnection
                  local_key={local.local_key}
                  name={local.name}
                  color={
                    isSameLocal(local.local_key) ? "green-100" : "gray-200"
                  }
                  active_channels={local.active_channels}
                  context_id={context_id}
                  refetchApartments={refetchAll}
                />
              </div>
            ))}
          </>
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-end mt-4">
        <Pagination
          page={page}
          pageSize={pageSize}
          totalItems={locals?.tot_locals || 0}
          onPageUpdate={(value: any) => {
            if (value === "+") {
              setPage((prev) => prev + 1);
              return;
            }
            if (value === "-") {
              setPage((prev) => (prev > 1 ? prev - 1 : 1));
              return;
            }
            setPage(+value);
          }}
        />
      </div>
    </>
  );
};

export default AssistantConnections;
