import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FormField from "../FormField";
import TextInput from "../TextInput";
import Button from "../Button";
import {
  createMessageContextData,
  fetchAssistantTemplates,
} from "../../shared/queries";
import CircleSpinner from "../CircleSpinner";
import { MessageContextDefaultTemplateMini } from "../../types";
import Icon from "../Icon";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

export default function AssistantWizard({
  onComplete,
  color = "primary",
  flat = true,
}: {
  onComplete: () => void;
  color?: string;
  flat?: boolean;
}) {
  const { t } = useTranslation();
  const { profile } = useSelector((state: RootState) => state.login);


  const [name, setName] = useState("");
  const [validationError, setValidationError] = useState<string | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);

  const { data: assistantTemplates, isLoading: isLoadingAssistantTemplates } =
    useQuery(["assistantTemplates"], fetchAssistantTemplates, {
      staleTime: 600,
      retry: 3,
    });

  function getLoadingColor() {
    return color === "primary"
      ? flat
        ? "primary"
        : "white"
      : flat
      ? "negative"
      : "white";
  }

  const mutation = useMutation(() => createMessageContextData(name,selectedTemplate, profile?.lang), {
    onSuccess: () => {
      toast.success(t("assistant.wizard.created"));
      onComplete();
    },
    onError: () => {
      toast.error(t("general.error"));
    },
  });

  function handleSubmit() {
    if (!name.trim()) {
      setValidationError(t("assistant.wizard.nameRequired"));
      toast.error(t("assistant.wizard.invalidFields"));
      return;
    }
    setValidationError(null);
    mutation.mutate();
  }

  return (
    <div className="w-full p-6">
      <div className="font-bold text-2xl mb-4">
        {t("assistant.wizard.addAssistant")}
      </div>
      <div className="text-gray-600 mb-6">
        {t("assistant.wizard.enterAssistantDetails")}
      </div>

      <FormField
        label={t("assistant.wizard.name")}
        errors={validationError ? { name: validationError } : null}
      >
        <TextInput
          value={name}
          onChange={(val) => setName(val)}
          placeholder={t("assistant.wizard.namePlaceholder")}
        />
      </FormField>

      {/* Template Selection */}
      <div className="text-lg font-semibold mt-6 mb-3">
        {t("assistant.wizard.selectTemplate")}
      </div>
      {isLoadingAssistantTemplates ? (
        <div className="flex justify-center items-center h-20">
          <CircleSpinner color={getLoadingColor()} />
        </div>
      ) : (
        <div className="flex">
          <div className="flex flex-wrap gap-4">
            {/* None option */}
            <div
              key="none"
              className={`bg-white border cst-shadow hover:bg-gray-100 rounded-2xl p-4 w-32 flex flex-col items-center justify-center font-medium text-gray-700 cursor-pointer transition-all
              ${
                selectedTemplate === null
                  ? "border-gray-300 bg-cyan-100"
                  : "border-gray-300 hover:border-gray-500"
              }`}
              onClick={() => setSelectedTemplate(null)}
            >
              <Icon name="none" size="50px" className="mb-2" />
              <span className="text-lg text-center">
                {t("general.labels.none")}
              </span>
            </div>

            {/* Template options */}
            {assistantTemplates?.results?.map(
              (template: MessageContextDefaultTemplateMini, index) => (
                <div
                  key={template.id}
                  className={`bg-white border cst-shadow hover:bg-gray-100 rounded-2xl p-4 w-32 flex flex-col items-center justify-center font-medium text-gray-700 cursor-pointer transition-all

                  ${
                    selectedTemplate === template.id
                      ? "border-gray-300 bg-cyan-100"
                      : "border-gray-300 hover:border-gray-500"
                  }`}
                  onClick={() =>
                    setSelectedTemplate(
                      selectedTemplate === template.id ? null : template.id
                    )
                  }
                >
                  <Icon name="template" size="50px" className="mb-2" />
                  <span className="text-lg text-center">{template.name}</span>

                  {/* Show "Recommended" label if only one template exists */}
                  {assistantTemplates?.results &&
                    assistantTemplates?.results.length === 1 &&(
                      <span className="text-sm text-cyan-600 font-semibold mt-1 text-center">
                        {t("general.labels.recommendedLabel") + "*"}
                      </span>
                    )}
                </div>
              )
            )}
          </div>
        </div>
      )}
      <div className="mt-2">
        {assistantTemplates?.results &&
          assistantTemplates?.results.length === 1 && (
            <span className="text-sm text-cyan-600 font-semibold mt-1">
              {"*" + t("assistant.labels.recommendedReasons")}
            </span>
          )}
      </div>
      <Button
        loading={mutation.isLoading}
        disabled={mutation.isLoading}
        className="mt-6 float-right"
        label={t("assistant.wizard.createAssistant")}
        onClick={handleSubmit}
      />
    </div>
  );
}
