import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom"; // Portal support
import Button from "../Button";
import { MessageContextDataLocal } from "../../types";
import { assistantStatusStyle } from "../../shared/utils/CommonStyles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

type AssistantBubbleProps = {
  data?: MessageContextDataLocal[];
  onClick?: (item: MessageContextDataLocal) => void;
  className?: string;
};

export const AssistantBubble: React.FC<AssistantBubbleProps> = ({
  data = [],
  onClick,
  className = "",
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={`grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 ${className}`}>
      {data.map((item) => (
        <div
          key={item.context_id}
          className="bg-white border cst-shadow hover:bg-gray-100 rounded-2xl p-4 w-full font-medium text-gray-700 cursor-pointer"
          onClick={() => (onClick ? onClick(item) : history.push(`/assistants/${item.context_id}/details`))}
        >
          {item.name}
          {item.status && (
            <div className="flex items-center gap-2 mt-2">
              <span
                className={`px-2 py-1 text-xs font-medium rounded-full ${
                  assistantStatusStyle[
                    item.status.toLowerCase() as keyof typeof assistantStatusStyle
                  ] || assistantStatusStyle.waiting
                }`}
              >
                {t(`assistant.status_${item.status}`)}
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
