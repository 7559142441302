import Icon from "./Icon";

type PageTitleProps = {
  title: string;
  subtitle?: string;
  noMargin?: boolean;
  className?: string;
  isBetaFunction?: boolean;
  betaIconSize?:string
};

export default function PageTitle({
  title,
  noMargin = false,
  subtitle,
  className = "",
  isBetaFunction,
  betaIconSize = "25px"
}: PageTitleProps) {
  return (
    <div className={`${noMargin ? "mb-1" : "mb-6"} ${className}`}>
      <div className="text-2xl font-bold text-gray-700 flex items-center gap-2">
        {title}
        {isBetaFunction && (
          <span>
            <Icon name="beta" size={betaIconSize} />
          </span>
        )}
      </div>

      {subtitle && <div className={"mt-1 text-gray-600"}>{subtitle}</div>}
    </div>
  );
}
