import React, { useState } from "react";
import ReactDOM from "react-dom";
import Icon from "./Icon";

// Define the props for TooltipModal
interface TooltipModalProps {
  content?: string;
  strokeColor?: string;
  icon?: string;
  children?: any;
  className?: string;
}

const TooltipModal: React.FC<TooltipModalProps> = ({
  content,
  strokeColor,
  icon,
  className = "",
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleInteraction = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement>
      | React.TouchEvent<HTMLDivElement>
  ) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      x: rect.right + 10,
      y: rect.top + window.scrollY,
    });
    setShowTooltip(!showTooltip);
  };

  const closeTooltip = () => setShowTooltip(false);

  // Tooltip content rendered using createPortal
  const tooltipContent = showTooltip ? (
    <div
      className="fixed flex bg-white border border-gray-300 rounded-lg shadow-lg p-3 text-sm text-gray-800"
      style={{
        top: tooltipPosition.y,
        left: tooltipPosition.x,
        zIndex: 1000,
      }}
    >
      <div
        style={{
          maxWidth: "300px",
          wordWrap: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        {content}
      </div>
      {children}
    </div>
  ) : null;

  return (
    <div className={"relative inline-block " + className}>
      {/* Tooltip trigger for click/touch */}
      <div
        onClick={handleInteraction}
        onTouchStart={handleInteraction}
        className="cursor-pointer"
      >
        <Icon
          name={icon ? icon : "information-circle"}
          size="20px"
          strokeColor={strokeColor}
        />
      </div>

      {/* Render tooltip content with createPortal */}
      {ReactDOM.createPortal(tooltipContent, document.body)}

      {/* Overlay for closing the tooltip on mobile */}
      {showTooltip && (
        <div
          className="fixed inset-0"
          style={{ zIndex: 999 }}
          onClick={closeTooltip}
          onTouchStart={closeTooltip}
        />
      )}
    </div>
  );
};

export default TooltipModal;
