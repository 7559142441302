import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  sendCommand,
  updateApartmentV3,
  updateCommand,
} from "../../shared/queries";
import { Local, LocalTool } from "../../types";
import Button from "../Button";
import FormField from "../FormField";
import FormSectionTitle from "../FormSectionTitle";
import TextInput from "../TextInput";
import Checkbox from "../Checkbox";

type ApartmentAutomationProps = {
  data: Local;
};

export default function ApartmentAutomation({
  data,
}: ApartmentAutomationProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [currentLoading, setCurrentLoading] = useState<any>();
  const [tools, setTools] = useState<LocalTool[]>();
  const [newMajor, setNewMajor] = useState("");

  useEffect(() => {
    if (data && data.tools) {
      setTools([...data.tools]);
    }
  }, [data]);

  const mutation = useMutation(
    (data: any) =>
      sendCommand({
        tool_key: data.tool_key,
        local_key: data.local_key,
        top: data.top,
      }),
    {
      onSuccess: () => {
        setCurrentLoading(undefined);
        toast.success(t("reservationAutomation.commandSent"));
      },
      onError: (error: Error) => {
        setCurrentLoading(undefined);
        toast.error(error.message || t("general.requestError"));
      },
    }
  );

  const keyboxMutation = useMutation(
    (data: any) =>
      updateCommand({
        tool_key: data.tool_key,
        local_key: data.local_key,
        top: data.top,
        major: newMajor,
      }),
    {
      onSuccess: () => {
        setCurrentLoading(undefined);
        toast.success(t("reservationAutomation.commandSent"));
      },
      onError: (error: Error) => {
        setCurrentLoading(undefined);
        toast.error(error.message || t("general.requestError"));
      },
    }
  );

  const majorChanged = (tool: LocalTool, val: string) => {
    setTools(
      tools?.map((e) => (e.uuid === tool.uuid ? { ...tool, major: val } : e))
    );
    setNewMajor(val);
  };

  const updateAccessMutation = useMutation(
    (data: any) =>
      updateApartmentV3(data.local_key || "", {
        access_button_1time: data["access_1time"],
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        queryClient.invalidateQueries(["apartment", data.local_key]);
      },
    }
  );

  return (
    <div>
      <div className={"flex flex-row"}>
        <div className="w-full">
          <FormSectionTitle
            borderless
            title={t("apartmentAutomation.devices")}
          ></FormSectionTitle>
        </div>
        {!data.device_mac && (
          <Button
            icon="refresh"
            size="small"
            color="info"
            iconSize="10px"
            flat
            className={"mb-8 cursor-pointer"}
            onClick={() => {
              setCurrentLoading(data.local_key);
              mutation.mutate({
                local_key: data.local_key,
                top: "RELOAD",
              });
            }}
            loading={mutation.isLoading && currentLoading === data.local_key}
          ></Button>
        )}
      </div>
      {data && data.tools && data.tools.length > 0 && (
        <div className="cst-shadow bg-white rounded-2xl p-4 lg:p-6 mb-6 lg:px-8 border w-full">
          <Checkbox
            loading={updateAccessMutation.isLoading}
            disabled={updateAccessMutation.isLoading}
            checked={data.access_button_1time}
            label={t("apartmentAutomation.tempAccessDevices")}
            hint={t("apartmentAutomation.tempAccessDevicesDesc")}
            className="max-w-max"
            onChange={() => {
              updateAccessMutation.mutate({
                access_1time: !data.access_button_1time,
                local_key: data.local_key,
              });
            }}
          />
        </div>
      )}
      <div className={"grid grid-cols-1 lg:grid-cols-2 gap-4"}>
        {data &&
          data.tools &&
          data.tools.map((tool: LocalTool, index: number) => (
            <div key={index}>
              <div
                className={"shadow rounded-2xl border border-gray-300 bg-white"}
              >
                <div className={"p-4"}>
                  <div className={"font-semibold text-gray-800 text-lg"}>
                    {t("deviceTypes." + tool.translate_title)}
                  </div>
                  <div className={"mt-1 text-sm font-medium text-gray-600"}>
                    {tool.type}
                  </div>
                  <div className={"mt-1 text-xs font-normal text-gray-600"}>
                    {tool.tool_key}
                  </div>
                  <div className={"text-disambuigation pt-2 h-7"}>
                    {tool?.tool_type_extended?.tool_type === "CILINDRO" && (
                      <div>
                        {t("apartmentAutomation.battery")}
                        <div className="inline-block px-2 py-1 rounded-2xl ml-2 bg-cyan-100 font-medium">
                          <span>{tool.major}%</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={"p-4"}>
                  {tool?.tool_type_extended?.tool_type !== "KEYBOX" ? (
                    <Button
                      onClick={() => {
                        setCurrentLoading(tool.tool_key);
                        mutation.mutate({
                          local_key: data.local_key,
                          tool_key: tool.tool_key,
                          top: "TOGGLE",
                        });
                      }}
                      loading={
                        mutation.isLoading && currentLoading === tool.tool_key
                      }
                      label={t("apartmentAutomation.open")}
                    />
                  ) : (
                    <FormField label={"Codice"} className="w-1/3">
                      <TextInput
                        className="mb-2 rounded-2xl"
                        value={
                          tools?.find((e) => e.tool_key === tool.tool_key)
                            ?.major ?? ""
                        }
                        onChange={(val) => majorChanged(tool, val)}
                      />
                      <Button
                        onClick={() => {
                          setCurrentLoading(tool.tool_key);
                          keyboxMutation.mutate({
                            local_key: data.local_key,
                            tool_key: tool.tool_key,
                            major: tools?.find(
                              (e) => e.tool_key === tool.tool_key
                            )?.major,
                          });
                        }}
                        loading={
                          keyboxMutation.isLoading &&
                          currentLoading === tool.tool_key
                        }
                        label={t("apartmentAutomation.set")}
                      />
                    </FormField>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
