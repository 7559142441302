import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import {
  ReservationTable,
  TableFilterColumn,
  TableResponse,
  TableSort,
  TableSortAPI,
} from "../types";
import { useTranslation } from "react-i18next";
import { SortingRule } from "react-table";
import { fetchReservations, fetchPerms } from "../shared/queries";
import { getHumanDateFromISO } from "../shared/utils/formatting";
import { getReservationColor } from "../shared/utils/reservation";
import BaseTable from "../components/BaseTable";
import PageWrapper from "../components/PageWrapper";
import Icon from "../components/Icon";
import Modal from "../components/Modal";
import ModalCard from "../components/ModalCard";
import ModalAction from "../components/ModalAction";
import ModalSection from "../components/ModalSection";
import ReservationExport from "../components/reservation/ReservationExport";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import Button from "../components/Button";
import i18n from "../langs";

export default function Reservations() {
  const history = useHistory();
  const { t } = useTranslation();
  const optionsStatusCheckin = [
    { label: t("resv.notExecuted"), value: "WAIT" },
    { label: t("resv.waitingDeposit"), value: "WAIT_DEPOSIT" },
    { label: t("resv.notNecessary"), value: "NONEED" },
    { label: t("resv.confirmed"), value: "OK" },
    { label: t("resv.waitingConfirmation"), value: "PEND" },
  ];
  const defaultFilters = [
    {
      key: "1",
      label: t("reservations.arrival"),
      active: false,
      value: null,
      type: "dateRange",
    },
    {
      key: "2",
      label: t("reservations.departure"),
      active: false,
      value: null,
      type: "dateRange",
    },
    {
      key: "3",
      label: t("reservations.code"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "4",
      label: t("reservations.channel"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "5",
      label: t("reservations.externalKey"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "12",
      label: t("reservations.apartment"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "20",
      label: t("reservations.date"),
      active: false,
      value: null,
      type: "dateRange",
    },
    {
      key: "13",
      label: t("reservations.guest"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "14",
      label: t("reservations.email"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "17",
      label: t("general.city"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "18",
      label: t("reservations.numberOfGuests"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "19",
      label: t("reservations.language"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "16",
      label: t("reservations.state"),
      active: false,
      value: null,
      type: "select",
      options: [
        { label: t("reservations.past"), value: "PAST" },
        { label: t("reservations.active"), value: "ACTIVE" },
        { label: t("reservations.future"), value: "FUTURE" },
      ],
    },
    {
      key: "21",
      label: t("reservations.color"),
      active: false,
      value: null,
      type: "select",
      options: [
        { label: t("reservations.white"), value: "1" },
        { label: t("reservations.green"), value: "2" },
        { label: t("reservations.blue"), value: "3" },
        { label: t("reservations.yellow"), value: "4" },
        { label: t("reservations.orange"), value: "5" },
        { label: t("reservations.red"), value: "6" },
      ],
    },
    {
      key: "22",
      label: t("reservations.canceled"),
      active: true,
      regex: true,
      value: "0",
      type: "select",
      options: [
        { label: t("general.yes"), value: 1 },
        { label: t("general.no"), value: 0 },
      ],
    },
    {
      key: "23",
      label: t("reservations.source"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "25",
      label: t("reservations.firstOpening"),
      active: false,
      value: null,
      type: "dateRange",
    },
    {
      key: "50",
      label: t("reservations.checkinStatus"),
      active: false,
      value: null,
      type: "select",
      options: optionsStatusCheckin,
    },
  ];
  const [sorting, setSorting] = useState<TableSortAPI[]>([]);
  const [sortingAPI, setSortingAPI] = useState<TableSort[]>([]);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [filters, setFilters] = useState<TableFilterColumn[] | undefined>(
    defaultFilters
  );
  const [currentStatus, setCurrentStatus] = useState<
    "ALL" | "PEND" | "TODAY" | "TODAYCHECKIN" | "TOMORROWCHECKIN"
  >("ALL");
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([
    "channelId",
    "externalKey",
    "guestactive",
    "Portale allog.",
    "Policia Nacio.",
    "Mossos",
    "MOTouristOffice",
    "Istat",
    t("reservations.account"),
    "guest_email",
    "guest_phone",
    "city",
    "guests",
    "language",
    "date",
    "tot_price",
    "first_opening_date",
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [accounts, setAccounts] = useState<{}>({});

  const { isLoading: isLoadingAccount } = useQuery("accounts", fetchPerms, {
    onSuccess: (data) => {
      const obj = {} as any;
      if (data && data.results) {
        data.results.forEach((account) => {
          obj[account.o_user_key] = account.o_name;
        });
        setAccounts(obj);
      }
    },
  });

  const { data, isFetching } = useQuery<TableResponse<ReservationTable>, Error>(
    ["reservations", currentPage, filters, sortingAPI],
    () => fetchReservations(filters, (currentPage - 1) * 15, sortingAPI),
    {
      keepPreviousData: true,
      onSuccess: () => {},
    }
  );

  function handleSetStatus(
    status: "ALL" | "PEND" | "TODAY" | "TODAYCHECKIN" | "TOMORROWCHECKIN"
  ) {
    setCurrentStatus(status);
    localStorage.setItem("vikey_res_status", status);
    if (filters) {
      localStorage.setItem(
        "vikey_res_filters",
        JSON.stringify(
          filters.map((f) => {
            if (f.key === "50") {
              if (status !== "ALL") {
                return {
                  key: "50",
                  label: t("reservations.state"),
                  active: true,
                  value: optionsStatusCheckin.find((v) => v.value === status)
                    ?.value,
                  type: "select",
                  options: optionsStatusCheckin,
                };
              } else {
                return {
                  key: "50",
                  label: t("reservations.state"),
                  active: false,
                  value: null,
                  type: "select",
                  options: optionsStatusCheckin,
                };
              }
            } else if (f.key === "1") {
              if (status === "TODAYCHECKIN") {
                return {
                  ...f,
                  active: true,
                  value:
                    dayjs().format("YYYY-MM-DD") +
                    " >>> " +
                    dayjs().format("YYYY-MM-DD"),
                };
              } else if (status === "TOMORROWCHECKIN") {
                return {
                  ...f,
                  active: true,
                  value:
                    dayjs().add(1, "days").format("YYYY-MM-DD") +
                    " >>> " +
                    dayjs().add(1, "days").format("YYYY-MM-DD"),
                };
              } else {
                return {
                  key: "1",
                  label: t("reservations.arrival"),
                  active: false,
                  value: null,
                  type: "dateRange",
                };
              }
            }
            return f;
          })
        )
      );
      setFilters(
        filters.map((f) => {
          if (f.key === "50") {
            if (status !== "ALL") {
              return {
                key: "50",
                label: t("reservations.state"),
                active: true,
                value: optionsStatusCheckin.find((v) => v.value === status)
                  ?.value,
                type: "select",
                options: optionsStatusCheckin,
              };
            } else {
              return {
                key: "50",
                label: t("reservations.state"),
                active: false,
                value: null,
                type: "select",
                options: optionsStatusCheckin,
              };
            }
          } else if (f.key === "1") {
            if (status === "TODAYCHECKIN") {
              return {
                ...f,
                active: true,
                value:
                  dayjs().format("YYYY-MM-DD") +
                  " >>> " +
                  dayjs().format("YYYY-MM-DD"),
              };
            } else if (status === "TOMORROWCHECKIN") {
              return {
                ...f,
                active: true,
                value:
                  dayjs().add(1, "days").format("YYYY-MM-DD") +
                  " >>> " +
                  dayjs().add(1, "days").format("YYYY-MM-DD"),
              };
            } else {
              return {
                key: "1",
                label: t("reservations.arrival"),
                active: false,
                value: null,
                type: "dateRange",
              };
            }
          }
          return f;
        })
      );
    }
  }
  /* function setTodayCheckin() {
    if (filters) {
      setFilters(
        filters.map((f) => {
          if (f.key === "1") {
            return {
              ...f,
              active: true,
              value:
                dayjs().format("YYYY-MM-DD") +
                " >>> " +
                dayjs().format("YYYY-MM-DD"),
            };
          }
          return f;
        })
      );
    }
  } */

  const handleSortingOnMount = (data: SortingRule<any>[], sorted?: boolean) => {
    const cols = [] as TableSortAPI[];
    const colsApi = [] as TableSort[];
    let dataTest: any = data;
    let dataTestAPI: any = data;
    if (data && data.length > 0) {
      dataTest?.forEach((f: any) => {
        const columnKey = defaultFilters.find((df) => df.label === f.id);
        if (columnKey) {
          cols.push({
            id: columnKey.label,
            desc: f.desc,
          });
        }
      });
      dataTestAPI?.forEach((f: any) => {
        const columnKey = defaultFilters.find((df) => df.label === f.id);
        if (columnKey) {
          colsApi.push({
            columnIndex: columnKey.key,
            desc: f.desc,
          });
        }
      });
    }

    if (!sorted) {
      localStorage.setItem("vikey_res_sorting", JSON.stringify(cols));
      localStorage.setItem("vikey_res_sorting_API", JSON.stringify(colsApi));
    }
    setSorting(cols);
    setSortingAPI(colsApi);
  };

  const handleSorting = () => {};

  /* useBreadcrumbs([
    { label: t("reservations.reservations"), route: "/reservations" },
  ]); */

  function getColumns() {
    return [
      {
        Header: t("reservations.code"),
        accessor: (row: ReservationTable) => (
          <div className={"flex items-center space-x-2"}>
            <div
              className={
                "w-3 h-3 rounded-2xl " + getReservationColor(row.color)
              }
            />
            <div>{row.code}</div>
          </div>
        ),
      },
      {
        Header: t("reservations.arrival"),
        accessor: (row: ReservationTable) => (
          <>{getHumanDateFromISO(row.check_in, false)}</>
        ),
      },
      {
        Header: t("reservations.departure"),
        accessor: (row: ReservationTable) => (
          <>{getHumanDateFromISO(row.check_out, false)}</>
        ),
      },
      { Header: t("reservations.channel") as string, accessor: "channelId" },
      {
        Header: t("reservations.externalKey") as string,
        accessor: "externalKey",
      },
      {
        Header: t("reservations.activeGuest") as string,
        disableSortBy: true,
        accessor: (row: ReservationTable) => (
          <>
            {row.guestactive && (
              <div
                className={
                  "bg-green-100 text-gray-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("general.yes")}
              </div>
            )}
            {!row.guestactive && (
              <div
                className={
                  "bg-gray-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("general.no")}
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Portale allog.",
        accessor: (row: ReservationTable) => (
          <>
            {row.portaleAlloggiati === 1 && (
              <div
                className={
                  "bg-green-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Ok
              </div>
            )}
            {row.portaleAlloggiati === 0 && (
              <div
                className={
                  "bg-gray-100 text-gray-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                No
              </div>
            )}
            {row.portaleAlloggiati === 2 && (
              <div
                className={
                  "bg-red-100 text-red-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Error
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Policia Nacio.",
        accessor: (row: ReservationTable) => (
          <>
            {row.policiaNacional === 1 && (
              <div
                className={
                  "bg-green-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Ok
              </div>
            )}
            {row.policiaNacional === 0 && (
              <div
                className={
                  "bg-gray-100 text-gray-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                No
              </div>
            )}
            {row.policiaNacional === 2 && (
              <div
                className={
                  "bg-red-100 text-red-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Error
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Mossos",
        accessor: (row: ReservationTable) => (
          <>
            {row.mossos === 1 && (
              <div
                className={
                  "bg-green-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Ok
              </div>
            )}
            {row.mossos === 0 && (
              <div
                className={
                  "bg-gray-100 text-gray-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                No
              </div>
            )}
            {row.mossos === 2 && (
              <div
                className={
                  "bg-red-100 text-red-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Error
              </div>
            )}
          </>
        ),
      },
      {
        Header: "MOTouristOffice",
        accessor: (row: ReservationTable) => (
          <>
            {row.istat === 1 && (
              <div
                className={
                  "bg-green-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Ok
              </div>
            )}
            {row.istat === 0 && (
              <div
                className={
                  "bg-gray-100 text-gray-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                No
              </div>
            )}
            {row.istat === 2 && (
              <div
                className={
                  "bg-red-100 text-red-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Error
              </div>
            )}
          </>
        ),
      },
      {
        Header: t("general.account") as string,
        disableSortBy: true,
        accessor: (row: ReservationTable) => (
          <>
            {
              <div
                className={
                  "bg-cyan-100 text-cyan-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {(accounts as any)[row.account]}
              </div>
            }
          </>
        ),
      },
      { Header: t("reservations.guest") as string, accessor: "guest" },
      // { Header: t('reservations.apartment') as string, accessor: 'local' },
      {
        Header: t("reservations.apartment"),
        accessor: (row: ReservationTable) => <>{row.local}</>,
      },
      { Header: t("reservations.email") as string, accessor: "guest_email" },
      { Header: t("reservations.phone") as string, accessor: "guest_phone" },
      { Header: t("general.city") as string, accessor: "city" },
      {
        Header: t("reservations.numberOfGuests") as string,
        accessor: "guests",
      },
      { Header: t("reservations.language") as string, accessor: "language" },
      {
        Header: t("reservations.date"),
        accessor: (row: ReservationTable) => (
          <>{getHumanDateFromISO(row.date, true)}</>
        ),
      },

      {
        Header: t("reservations.canceled"),
        disableSortBy: true,
        accessor: (row: ReservationTable) => (
          <>
            {row.canceled && (
              <div
                className={
                  "bg-gray-100 text-gray-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("general.yes")}
              </div>
            )}
            {!row.canceled && (
              <div
                className={
                  "bg-green-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("general.no")}
              </div>
            )}
          </>
        ),
      },
      {
        Header: t("reservations.state"),
        disableSortBy: true,
        accessor: (row: ReservationTable) => (
          <>
            {row.status === "FUTURE" && (
              <div
                className={
                  "bg-cyan-100 text-cyan-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("reservations.future")}
              </div>
            )}
            {row.status === "PAST" && (
              <div
                className={
                  "bg-gray-100 text-gray-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("reservations.past")}
              </div>
            )}
            {row.status === "ACTIVE" && (
              <div
                className={
                  "bg-green-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("reservations.active")}
              </div>
            )}
          </>
        ),
      },
      {
        Header: t("reservations.checkinStatus"),
        disableSortBy: true,
        accessor: (row: ReservationTable) => (
          <>
            {row.checkin_status === "WAIT" && (
              <div
                className={
                  "bg-red-200 text-red-800 flex items-center text-xs font-medium px-2 rounded-2xl py-0.5 border-2 border-red-800"
                }
              >
                <div className={"w-3 h-3 bg-red-800 mr-2 rounded-full"}></div>{" "}
                {t("resv.notExecuted")}
              </div>
            )}
            {row.checkin_status === "NONEED" && (
              <div
                className={
                  "bg-gray-100 text-gray-800 text-xs font-medium px-2 rounded-2xl py-0.5  border-2 border-gray-800 flex items-center"
                }
              >
                <div className={"w-3 h-3 bg-gray-800 mr-2 rounded-full"}></div>{" "}
                {t("resv.notNecessary")}
              </div>
            )}
            {row.checkin_status === "WAIT_DEPOSIT" && (
              <div
                className={
                  "font-medium text-xs flex items-center text-yellow-800 bg-yellow-300 rounded-full p-0.5 border-2 border-yellow-800"
                }
              >
                <div
                  className={"w-3 h-3 bg-yellow-800 mr-2 rounded-full"}
                ></div>{" "}
                {t("resv.waitingDeposit")}
              </div>
            )}
            {row.checkin_status === "OK" && (
              <div
                className={
                  "bg-green-100 text-green-800 flex items-center text-xs font-medium px-2 rounded-2xl py-0.5 border-2 border-green-800"
                }
              >
                <div className={"w-3 h-3 bg-green-800 mr-2 rounded-full"}></div>{" "}
                {t("resv.confirmed")}
              </div>
            )}
            {row.checkin_status === "PEND" && (
              <div
                className={
                  "bg-yellow-300 text-yellow-800 flex items-center text-xs font-medium px-2 rounded-2xl py-0.5 border-2 border-yellow-800"
                }
              >
                <div
                  className={"w-3 h-3 bg-yellow-800 mr-2 rounded-full"}
                ></div>{" "}
                {t("resv.waitingConfirmation")}
              </div>
            )}
          </>
        ),
      },
      {
        Header: t("reservations.source"),
        accessor: (row: ReservationTable) => <div>{row.source}</div>,
      },
      {
        Header: t("reservationNotes.price") as string,
        accessor: (row: ReservationTable) => (
          <div className="flex justify-end w-full mr-7">
            {row.tot_price ? row.tot_price.toFixed(2) : (0).toFixed(2)}{" "}
            {row.currency_symbol}
          </div>
        ),
      },
      {
        Header: t("reservations.first_opening_date"),
        accessor: (row: ReservationTable) => (
          <>
            {row.first_opening_date &&
              getHumanDateFromISO(row.first_opening_date, true)}
          </>
        ),
      },
    ];
  }

  useEffect(() => {
    try {
      const hiddenColumns = localStorage.getItem("vikey_res_hidden_cols");
      const pastFilters = localStorage.getItem("vikey_res_filters");
      const pageFilters = localStorage.getItem("vikey_res_page");
      const currentState = localStorage.getItem("vikey_res_status");

      // const pastFilters=undefined
      if (pastFilters) {
        setFilters(JSON.parse(pastFilters));
      } else {
        setFilters(defaultFilters);
      }
      if (
        currentState &&
        (currentState === "ALL" ||
          currentState === "PEND" ||
          currentState === "TODAY" ||
          currentState === "TODAYCHECKIN" ||
          currentState === "TOMORROWCHECKIN")
      ) {
        setCurrentStatus(currentState);
      }
      if (hiddenColumns) {
        setHiddenColumns(JSON.parse(hiddenColumns));
      }
      if (pageFilters) {
        setCurrentPage(parseInt(pageFilters));
      }
    } catch (e) {
      console.error("can not resolve past filters");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      {i18n.language === "it" && (
        <div className="lg:flex lg:space-x-2 space-x-0 space-y-2 lg:space-y-0 mb-6">
          <div>
            <a
              href="https://youtu.be/7SUpTZcb-pA"
              target={"_blank"}
              style={{
                boxShadow:
                  "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
              }}
              // className={`bg-cyan-600 flex items-center px-2 py-1 rounded-2xl text-white text-sm font-bold clic_videoguida_channelmanager`}
              className={`bg-cyan-600 flex items-center px-2 py-1 rounded-2xl text-white text-sm font-bold clic_videoguida_prenotazioni`}
              rel="noreferrer"
            >
              {t("channelManager.watchVideo")}
              <Icon name="play" size="16px" className="ml-1" />
            </a>
          </div>
          <div>
            <a
              href="https://support.vikey.it/it/articles/8009803-creare-una-nuova-prenotazione"
              target={"_blank"}
              style={{
                boxShadow:
                  "0px 2px 4px rgba(0,0,0,0.06), 0px 0px 0px 1px rgba(0,0,0,0.07)",
              }}
              // className={`bg-cyan-600 flex items-center px-2 py-1 rounded-2xl text-white text-sm font-bold clic_leggiguida_channelmanager`}
              className={`bg-cyan-600 flex items-center px-2 py-1 rounded-2xl text-white text-sm font-bold clic_leggiguida_prenotazioni`}
              rel="noreferrer"
            >
              {t("channelManager.readGuide")}
              <Icon name="book-open" size="16px" className="ml-1" />
            </a>
          </div>
        </div>
      )}
      <BaseTable
        currentPage={currentPage}
        pageCount={Math.ceil(parseInt(data?.recordsFiltered || "0") / 15)}
        onSortChanged={handleSortingOnMount}
        identifierKey={"code"}
        // title={t("reservations.reservations")}
        onSorting={handleSorting}
        initialData={sorting}
        renderQuickFilters={() => (
          <div
            className={
              "flex lg:flex-row flex-col space-x-1 lg:space-y-0 space-y-2"
            }
          >
            <div className="rounded-2xl flex lg:mr-1 self-end ">
              <div className="relative w-32 h-10 border-gray-300 border-l border-t border-b bg-white rounded-l-xl">
                <input
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  value={filters?.find((f) => f.key === "3")?.value ?? ""}
                  onChange={(e) => {
                    if (filters) {
                      setFilters(
                        filters.map((f) => {
                          if (f.key === "3") {
                            return {
                              ...f,
                              active: true,
                              value: e.target.value,
                            };
                          }
                          return f;
                        })
                      );
                      setCurrentPage(1);
                      localStorage.setItem("vikey_res_page", "1");
                      localStorage.setItem(
                        "vikey_res_filters",
                        JSON.stringify(
                          filters.map((f) => {
                            if (f.key === "3") {
                              return {
                                ...f,
                                active: true,
                                value: e.target.value,
                              };
                            }
                            return f;
                          })
                        )
                      );
                    }
                  }}
                  placeholder={t("reservations.code")}
                  className={
                    "relative pl-8 flex items-center bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl truncate"
                  }
                ></input>
                <Icon
                  name={"hashtag"}
                  size={"18px"}
                  className={"absolute top-1 left-1"}
                />
              </div>
              <div className="relative w-32 bg-white rounded-r-xl border-gray-300 border-r border-t border-b">
                <input
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  value={filters?.find((f) => f.key === "13")?.value ?? ""}
                  onChange={(e) => {
                    if (filters) {
                      setFilters(
                        filters.map((f) => {
                          if (f.key === "13") {
                            return {
                              ...f,
                              active: true,
                              value: e.target.value,
                            };
                          }
                          return f;
                        })
                      );
                      setCurrentPage(1);
                      localStorage.setItem("vikey_res_page", "1");
                      localStorage.setItem(
                        "vikey_res_filters",
                        JSON.stringify(
                          filters.map((f) => {
                            if (f.key === "13") {
                              return {
                                ...f,
                                active: true,
                                value: e.target.value,
                              };
                            }
                            return f;
                          })
                        )
                      );
                    }
                  }}
                  placeholder={t("reservations.guest")}
                  className={
                    "relative pl-8 flex items-center bg-white focus:outline-none bg-white-600 px-3 text-sm font-medium border-gray-300 rounded-2xl truncate"
                  }
                ></input>
                <Icon
                  name={"user"}
                  size={"18px"}
                  className={"absolute top-1 left-1"}
                />
              </div>
            </div>
            <button
              onClick={() => {
                handleSetStatus("ALL");
              }}
              className={`${
                currentStatus === "ALL" && "bg-cyan-100 text-cyan-800"
              } focus:outline-none hover:bg-cyan-100 cursor-pointer px-3 py-1 rounded-2xl text-sm font-semibold truncate`}
            >
              {t("reservations.all")}
            </button>
            <button
              onClick={() => {
                handleSetStatus("PEND");
                setCurrentPage(1);
                localStorage.setItem("vikey_res_page", "1");
              }}
              className={`${
                currentStatus === "PEND" && "bg-cyan-100 text-cyan-800"
              } focus:outline-none hover:bg-cyan-100 cursor-pointer px-3 py-1 rounded-2xl text-sm font-semibold truncate`}
            >
              {t("reservations.toConfirm")}
            </button>
            <button
              onClick={() => {
                handleSetStatus("TODAYCHECKIN");
                setCurrentPage(1);
                localStorage.setItem("vikey_res_page", "1");
              }}
              className={`${
                currentStatus === "TODAYCHECKIN" && "bg-cyan-100 text-cyan-800"
              } focus:outline-none hover:bg-cyan-100 cursor-pointer px-3 py-1 rounded-2xl text-sm font-semibold truncate`}
            >
              {t("reservations.todayCheckins")}
            </button>
            <button
              onClick={() => {
                handleSetStatus("TOMORROWCHECKIN");
                setCurrentPage(1);
                localStorage.setItem("vikey_res_page", "1");
              }}
              className={`${
                currentStatus === "TOMORROWCHECKIN" &&
                "bg-cyan-100 text-cyan-800"
              } focus:outline-none hover:bg-cyan-100 cursor-pointer px-3 py-1 rounded-2xl text-sm font-semibold truncate`}
            >
              {t("reservations.tomorrowCheckins")}
            </button>
          </div>
        )}
        data={!isLoadingAccount && !isFetching && data ? data.data : []}
        loading={isLoadingAccount || isFetching}
        totalRows={parseInt(data?.recordsFiltered || "0")}
        displayRows={15}
        renderButtons={() => (
          <div className="flex lg:items-center">
            <Button
              onClick={() => setFilters(defaultFilters)}
              className={"flex items-center text-sm font-medium rounded-2xl"}
              icon="trash"
              size="small"
              color="info"
              label={t("")}
            />
            <Button
              onClick={() => setShowExportModal(true)}
              className={"flex items-center text-sm font-medium rounded-2xl"}
              icon="document-download"
              size="small"
              color="info"
              label={t("general.export")}
            />
            <Button
              onClick={() => history.push("/reservations/new")}
              icon="document-add"
              color="primary"
              size="medium"
              padding="sm"
              label={t("reservations.newReservation")}
            />
          </div>
        )}
        onColumnChange={(columns) => {
          setHiddenColumns(columns);
          localStorage.setItem(
            "vikey_res_hidden_cols",
            JSON.stringify(columns)
          );
        }}
        onRemoveFilters={() => {
          handleSetStatus("ALL");
          setFilters(
            defaultFilters.map((f) => {
              return {
                ...f,
                value: null,
                active: false,
              };
            })
          );
          setCurrentPage(1);
          localStorage.setItem("vikey_res_page", "1");
        }}
        onFilterChange={(filters, page) => {
          setCurrentPage(page || 1);
          setFilters(filters);
          localStorage.setItem("vikey_res_filters", JSON.stringify(filters));
          localStorage.setItem("vikey_res_page", (page || 1).toString());
        }}
        filtersValue={filters}
        filters={filters}
        hiddenColumns={hiddenColumns}
        columns={getColumns()}
        onEdit={(id) => {
          history.push("/reservations/" + id + "#general");
        }}
      />

      <Modal visible={showExportModal}>
        <ModalCard className={"w-full max-w-xl"}>
          <ModalSection className={"w-full max-w-xl"}>
            <ReservationExport />
          </ModalSection>
          <ModalAction
            closeLabel={t("general.exit")}
            saveLabel={t("general.confirm")}
            onClose={() => {
              setShowExportModal(false);
            }}
          />
        </ModalCard>
      </Modal>
    </PageWrapper>
  );
}
