import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setModalValueOverride } from "../../../features/channelManager/channelManagerSlice";
import dayjs from "dayjs";
import { CalendarDay } from "../../../types";

type Props = {
  keys: string;
  days: CalendarDay[];
  tempObj: any;
  localName: string;
  isDragging: boolean;
  setIsDragging: any;
};

const Availability = (props: Props) => {
  const { keys, days, tempObj, localName, isDragging, setIsDragging } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const containerRef = useRef(null);
  const [selectedDivs, setSelectedDivs] = useState<any>([]);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });

  // Funzione per avviare la selezione
  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setStartPoint({ x: e.clientX, y: e.clientY });
    setSelectedDivs([]); // Resetta la selezione corrente
  };

  // Funzione per aggiornare la selezione durante il trascinamento
  const handleMouseMove = (e: any) => {
    if (!isDragging) return;
    const container: any = containerRef.current;
    if (!container) return;
    // Coordinate del trascinamento relative al container
    const currentPoint = { x: e.clientX, y: e.clientY };
    const left = Math.min(startPoint.x, currentPoint.x);
    const right = Math.max(startPoint.x, currentPoint.x);
    const top = Math.min(startPoint.y, currentPoint.y);
    const bottom = Math.max(startPoint.y, currentPoint.y);
    const selectedIds: any = [...selectedDivs];
    // Controlla quali div sono all'interno dell'area di selezione
    Array.from(container.children).forEach((div: any, index) => {
      const rect = div.getBoundingClientRect();
      if (
        rect.left < right &&
        rect.right > left &&
        rect.top < bottom &&
        rect.bottom > top
      ) {
        const idsFinded = selectedIds.find(
          (id: string) => id === e.target.textContent
        );
        if (idsFinded) {
          const isBefore = dayjs(e.target.textContent).isBefore(
            selectedIds[selectedIds.length - 1]
          );
          if (isBefore) {
            selectedIds.pop();
          }
        } else {
          selectedIds.push(e.target.textContent);
        }
      }
    });
    setSelectedDivs(selectedIds); // Aggiorna la selezione
  };

  // Funzione per terminare la selezione
  const handleMouseUp = () => {
    if (!isDragging) return;
    const dateFromSelected = selectedDivs[0];
    const dateToSelected = selectedDivs[selectedDivs.length - 1];
    const isBefore = dayjs(dateFromSelected).isBefore(dateToSelected);
    let dateFrom = "";
    let dateTo = "";
    if (isBefore) {
      dateFrom = dateFromSelected;
      dateTo = dateToSelected;
    } else {
      dateFrom = dateToSelected;
      dateTo = dateFromSelected;
    }
    dispatch(
      setModalValueOverride({
        display: true,
        type: "availability",
        roomType: localName,
        ratePlan: null,
        restriction: "availability",
        currentPrice: 0,
        priceValue: 0,
        availabilityValue: false,
        dateRange: {
          from: dateFrom,
          to: dateTo,
        },
        roomTypeId: tempObj?.room_type_id,
        propertyId: tempObj?.property_id,
        stopSellValue: false,
        closedToArrivalValue: false,
        closedToDepartureValue: false,
        ratePlanId: "",
        minStayValue: 0,
        maxStayValue: 0,
      })
    );
    setIsDragging(false);
    setSelectedDivs([]);
  };

  return (
    <div
      className="hover:bg-white flex"
      key={1}
      style={{
        height: "25px",
      }}
    >
      <div
        className={
          "bg-gray-100 z-50 md:text-sm text-xs md:space-x-2 font-medium text-gray-600 flex items-start md:w-52 w-20 border-t border-r truncate md:px-5"
        }
      >
        <div className="w-full text-center">{t(`apartment.${keys}`)}</div>
      </div>
      <div className="flex-1 border-t flex relative place-content-center">
        <div
          className="absolute w-full h-full top-0 left-0 flex"
          ref={containerRef}
          style={{ position: "relative", userSelect: "none" }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          {days.map((day) => {
            const specificDate = `${day.date.slice(6, 10)}-${day.date.slice(
              3,
              5
            )}-${day.date.slice(0, 2)}`;
            return (
              <div
                key={specificDate}
                onClick={() => {
                  dispatch(
                    setModalValueOverride({
                      display: true,
                      type: keys,
                      roomType: localName,
                      ratePlan: null,
                      restriction: keys,
                      currentPrice: 0,
                      priceValue: 0,
                      availabilityValue: tempObj[keys][specificDate],
                      dateRange: {
                        from: specificDate,
                        to: specificDate,
                      },
                      roomTypeId: tempObj?.room_type_id,
                      propertyId: tempObj?.property_id,
                      stopSellValue: false,
                      closedToArrivalValue: false,
                      closedToDepartureValue: false,
                      ratePlanId: "",
                      minStayValue: 0,
                      maxStayValue: 0,
                    })
                  );
                }}
                className={`flex-1 relative h-full border-r cursor-pointer text-center align-middle w-full truncate items-center ${
                  tempObj[keys][specificDate]
                    ? selectedDivs.includes(specificDate) ||
                      (selectedDivs.length > 0 &&
                        dayjs(specificDate).isAfter(selectedDivs[0]) &&
                        dayjs(specificDate).isBefore(
                          selectedDivs[selectedDivs.length - 1]
                        ))
                      ? "blue"
                      : "bg-green-300 hover:bg-green-400"
                    : selectedDivs.includes(specificDate) ||
                      (selectedDivs.length > 0 &&
                        dayjs(specificDate).isAfter(selectedDivs[0]) &&
                        dayjs(specificDate).isBefore(
                          selectedDivs[selectedDivs.length - 1]
                        ))
                    ? "blue"
                    : "bg-red-300 hover:bg-red-400"
                }`}
              >
                <div className="w-8 h-8  m-auto rounded-full pointToCenter text-transparent">
                  {specificDate}
                </div>
                {/* {tempObj[key][specificDate]} */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Availability;
