import {
  AlloggiatiTable,
  LocalTable,
  MapMossos,
  MapGuardiaCivil,
  OrderTable,
  PoliciaTable,
  ReceiptTable,
  ReservationTable,
  RiceStatTable,
  ServiceTable,
  RossMilleTable,
  SefTable,
  RadarTable,
  UbyTable,
  PaytouristTable,
  IstatTrentoTable,
  IstatSiciliaTable,
  SesHospedajesResultTable,
  Thread,
  Message,
  UploadedFile,
  MessageAttachments,
  DraftThread,
} from "../../types";
import { uploadFile } from "../queries";

export function mapLocal(data: any): LocalTable {
  return {
    control: data[0],
    external_key: data[1],
    user_key: data[2],
    name: data[3],
    public_name: data[4],
    city: data[5],
    address: data[6],
    monitor_status: data[7],
    bootbill_status: data[8],
    voip_buddy_number: data[9],
    voip_username: data[10],
    hour_sched_forced: data[11],
    is_bridge: data[12],
    local_key: data[14],
    usersinvoices_id: data[15],
    paymentStatus: data[17],
  } as LocalTable;
}

export function mapService(data: any): ServiceTable {
  return {
    id: data[0],
    user_key: data[1],
    title: data[2],
    amount: data[3],
    type: data[4],
    flg_bkengine: data[5],
  } as ServiceTable;
}

export function mapReservation(data: any): ReservationTable {
  return {
    id: data[0],
    check_in: data[1],
    check_out: data[2],
    code: data[3],
    channelId: data[4],
    externalKey: data[5],
    guestactive: data[6],
    portaleAlloggiati: data[7],
    policiaNacional: data[8],
    mossos: data[9],
    istat: data[10],
    account: data[11],
    local: data[12],
    guest: data[13],
    guest_email: data[14],
    guest_phone: data[15],
    status: data[16],
    city: data[17],
    guests: data[18],
    language: data[19],
    date: data[20],
    color: data[21],
    canceled: data[22],
    source: data[23],
    first_opening_date: data[24],
    tot_price: data[48],
    currency_symbol: data[49],
    checkin_status: data[50],
  } as ReservationTable;
}

export function mapIcalEvents(data: any) {
  return {
    check_in: data[1],
    check_out: data[2],
    source: data[23],
    local_key: data.local_key,
  };
}

export function mapOrder(data: any): OrderTable {
  return {
    id: data[1],
    amount: data[9],
    apartment: data[3],
    check_in: data[5],
    creation_date: data[6],
    payment_date: data[7],
    status: data[8],
    guest: data[10],
    resv_key: data[4],
    service_type: data[16],
  } as OrderTable;
}

export function mapReceipt(data: any): ReceiptTable {
  return {
    id: data[0],
  } as ReceiptTable;
}

export function mapRiceStat(data: any): RiceStatTable {
  return {
    id: data[0],
    date: data[1],
    resv_key: data[2],
    sent: data[3],
    received: data[4],
    status: data[5],
  } as RiceStatTable;
}

export function mapAlloggiati(data: any): AlloggiatiTable {
  return {
    username: data[2],
    account: data[1],
    date: data[3],
    link: data[4],
    filename: data[5],
  } as AlloggiatiTable;
}
export function mapPaytourist(data: any): PaytouristTable {
  return {
    day: data[2],
    file_sent: data[3],
    result: data[4],
    raw_result: data[5],
    resv_key: data[6],
    successfully: data[7],
  } as PaytouristTable;
}
export function mapIstatTrento(data: any): IstatTrentoTable {
  return {
    id: data[0],
    trento_results_id: data[1],
    day: data[2],
    file_sent: data[3],
    result: data[4],
    raw_result: data[5],
    successfully: data[6],
    date: data[7],
  } as IstatTrentoTable;
}
export function mapIstatSicilia(data: any): IstatSiciliaTable {
  return {
    id: data[0],
    sicilia_creds_id: data[1],
    resv_key: data[3],
    date: data[2],
    successfully: data[6],
    raw_result: data[5],
    request_sent: data[4],
  } as IstatSiciliaTable;
}
export function mapRossMille(data: any): RossMilleTable {
  return {
    id: data[0],
    ross1000_creds_id: data[1],
    day: data[2],
    file_sent: data[3],
    result: data[4],
    raw_result: data[5],
    successfully: data[6],
    date: data[7],
  } as RossMilleTable;
}
export function mapRadar(data: any): RadarTable {
  return {
    id: data[0],
    radar_creds_id: data[1],
    day: data[2],
    file_sent: data[3],
    result: data[4],
    raw_result: data[5],
    successfully: data[6],
    date: data[7],
  } as RadarTable;
}
export function mapUby(data: any): UbyTable {
  return {
    id: data[0],
    date: data[1],
    resv_key: data[2],
    successfully: data[3],
    result: data[4],
    data_sent: data[5],
    raw_result: data[6],
    receipt_link: data[7],
  } as UbyTable;
}
export function mapSef(data: any): SefTable {
  return {
    id: data[0],
    sef_creds_id: data[1],
    file_sent: data[2],
    result: data[3],
    raw_result: data[4],
    successfully: data[5],
    date: data[6],
    resv_key: data[7],
  } as SefTable;
}

export function mapPolicia(data: any): PoliciaTable {
  return {
    date: data[1],
    resv_key: data[2],
    file: data[4],
    result: data[3],
    status: data[5],
    content: data[6],
  } as PoliciaTable;
}

export function mapMossos(data: any): MapMossos {
  return {
    date: data[1],
    resv_key: data[2],
    result: data[3],
    file: data[4],
    status: data[5],
    link: data[6],
    content: data[7],
  } as MapMossos;
}

export function mapGuardiaCivil(data: any): MapGuardiaCivil {
  return {
    date: data[1],
    resv_key: data[2],
    result: data[3],
    file: data[4],
    status: data[5],
    link: data[6],
    content: data[7],
  } as MapGuardiaCivil;
}

export function mapSesHospedajes(data: any): SesHospedajesResultTable {
  return {
    id: data[0],
    hospedajes_creds_id: data[1],
    resv_key: data[2],
    date: data[3],
    status: data[4],
    successfully: data[5],
    communication_type: data[6],
    result_id: data[7],
    result: data[8],
    raw_result: data[9],
    request_sent: data[10],
    request_content: data[11],
    communication_deleted: data[12],
  } as SesHospedajesResultTable;
}
export function mapMessageThreads(data: any): Thread {
  return {
    thread_id: data["thread_id"],
    name: data["name"],
    resv_key: data["resv_key"],
    property_id: data["property_id"],
    ext_message_thread_id: data["ext_message_thread_id"],
    pinned: data["pinned"],
    has_been_read: data["has_been_read"],
    archived: data["archived"],
    last_message: data["last_message"],
    created_on: data["created_on"],
    updated_on: data["updated_on"],
    muted: data["muted"],
    source: data["source"],
    subtitle: data["subtitle"],
    fetched_data:false,
    can_send_no_need_to_reply:data["can_send_no_need_to_reply"],
    skip_ai_intervention:data["skip_ai_intervention"]
  };
}
export function mapDraftMessageThread(data: any): DraftThread {
  return {
    local_key: data["local_key"],
    channex_ref: data["channex_ref"],
    name: data["name"],
    resv_key: data["resv_key"],
    source: data["source"],
  };
}
export function mapMessageChannexAttachment(data: any): MessageAttachments {
  return {
    id: data["id"],
    message_id: data["message_id"],
    file_mime_type: data["file_mime_type"],
    file_extension: data["file_extension"],
    file_name: data["file_name"],
    relative_path: data["relative_path"] || "",
    is_fetching: true,
  };
}

export function mapMessage(data: any): Message {
  return {
    message_id: data["message_id"],
    content: data["content"],
    sender: data["sender"],
    DEL: data["DEL"],
    type: data["type"],
    message_thread_id: data["message_thread_id"],
    sent_on: data["sent_on"],
    ext_ota_message_id: data["ext_ota_message_id"],
    answered: data["answered"],
    attachments: data["attachments"]
      ? JSON.parse(data["attachments"]).map(mapMessageChannexAttachment)
      : [],
    has_attachments: data["has_attachments"],
    approval_status:data["approval_status"],
    replies_to:data["replies_to"],
    translations: data["translations"],
  };
}

export function mapUploadedFile(data: any): UploadedFile {
  return {
    file_id: data["file_id"],
    context_id: data["context_id"],
    file_name: data["file_name"],
    file_mime_type: data["file_mime_type"],
    file_extension: data["context_id"],
    generate_faqs: data["generate_faqs"],
    firestore_document_id: data["firestore_document_id"],
    file_content: data["file_content"],
  };
}

export function mapMessageContextDataListItem(data: any) {
  return {
    name: data["name"],
    context_id: data["context_id"],
    status: data["status"],
    locals: data["locals"],
    updated_on: new Date(data["updated_on"]),
    created_on: new Date(data["updated_on"]),
  };
}

export function mapMessageThreadAssistantStatus(data:any){
  return{
    skip_ai_intervention: data["skip_ai_intervention"],
    module_active:data["module_active"]
  }
}
export function mapSingleMessageContextData(data: any) {
  return {
    name: data["name"],
    context_id: data["context_id"],
    status: data["status"],
    locals: data["locals"],
    files: data["files"] ? JSON.parse(data["files"]).map(mapUploadedFile) : [],
    DEL: data["DEL"],
    updated_on: new Date(data["updated_on"]),
    created_on: new Date(data["updated_on"]),
    last_generate_faqs: data["last_generate_faqs"]
      ? new Date(data["last_generate_faqs"])
      : null,
    running_mode: data["running_mode"],
    response_mode: data["response_mode"],
    learning_mode: data["learning_mode"],
    is_last_generate_faqs_old: data["is_last_generate_faqs_old"],
  };
}
