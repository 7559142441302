import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { sendCommand, updateReservationV3 } from "../../shared/queries";
import { LocalTool, ReservationV3 } from "../../types";
import Button from "../Button";
import FormSectionTitle from "../FormSectionTitle";
import Icon from "../Icon";
import Checkbox from "../Checkbox";

type ReservationAutomationProps = {
  reservation: ReservationV3;
  onSave: () => void;
};

export default function ReservationAutomation({
  reservation,
  onSave,
}: ReservationAutomationProps) {
  const { t } = useTranslation();

  const [currentLoading, setCurrentLoading] = useState<any>();

  const mutation = useMutation(
    (data: any) =>
      sendCommand({
        tool_key: data.tool_key,
        local_key: reservation.local.local_key,
        top: data.top,
      }),
    {
      onSuccess: () => {
        setCurrentLoading(undefined);
        toast.success(t("reservationAutomation.commandSent"));
      },
      onError: (error: Error) => {
        setCurrentLoading(undefined);
        toast.error(error.message || "Impossibile inviare il comando");
      },
    }
  );

  const updateReservation = useMutation(
    (data: any) =>
      updateReservationV3({
        resv_key: reservation.resv_key,
        skip_access_button_1time: data.skip_1time,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onSave) onSave();
      },
    }
  );

  function getTempPassCode(uuid: string | null | undefined): string {
    let temppass_codes = reservation.temppass_codes;
    if (typeof temppass_codes === "string") {
      temppass_codes = JSON.parse(temppass_codes);
    }
    if (temppass_codes && uuid) {
      const codes = temppass_codes[uuid];
      return codes;
    }
    return "--";
  }

  return (
    <div>
      <FormSectionTitle
        borderless
        title={t("reservationAutomation.installedDevices")}
      />
      {reservation && reservation.local && reservation.local.tools && reservation.local.tools?.length > 0 && reservation.local.access_button_1time && (
        <div className="cst-shadow bg-white rounded-2xl p-4 lg:p-6 mb-6 lg:px-8 border">
          <Checkbox
            loading={updateReservation.isLoading}
            disabled={updateReservation.isLoading}
            checked={reservation.skip_access_button_1time}
            label={t("reservationAutomation.unliAccessDevices")}
            hint={t("reservationAutomation.unliAccessDevicesDesc")}
            className="max-w-max"
            onChange={() => {
              updateReservation.mutate({
                skip_1time: !reservation.skip_access_button_1time,
              });
            }}
          />
        </div>
      )}

      <div className="flex flex-col lg:flex-row flex-wrap -mx-2 -my-2">
        {reservation &&
          reservation.local.tools &&
          reservation.local.tools.map((tool: LocalTool, index: number) => (
            <div className={"w-full lg:w-1/2 px-2 py-2"} key={index}>
              <div className={" rounded-2xl border border-gray-200 bg-white"}>
                <div className={"p-4"}>
                  <div
                    className={
                      "font-semibold text-gray-800 text-lg overflow-ellipsis w-full"
                    }
                  >
                    <div className={"flex justify-between items-center"}>
                      <div>
                        {t("deviceTypes." + tool.translate_title, "Device")}
                      </div>
                      <Icon
                        name={
                          tool.translate_title === "TTApartmentDoor"
                            ? "home"
                            : "office-building"
                        }
                        size={"20px"}
                      ></Icon>
                    </div>
                  </div>
                  <div className={"mt-1 text-sm font-medium text-gray-600"}>
                    {tool.type}
                    <div
                      className={"text-disambuigation border-t pt-2 mt-2 h-7"}
                    >
                      {tool?.tool_type_extended?.tool_type === "CILINDRO" && (
                        <div>
                          {t("reservationAutomation.temporaryCode")}
                          <div className="inline-block px-2 py-1 rounded-2xl ml-2 bg-cyan-100 font-medium">
                            <span>{getTempPassCode(tool.uuid)}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={"p-4"}>
                  <Button
                    onClick={() => {
                      setCurrentLoading(tool.tool_key);
                      mutation.mutate({
                        tool_key: tool.tool_key,
                        top: "TOGGLE",
                      });
                    }}
                    disabled={
                      mutation.isLoading && tool.tool_key !== currentLoading
                    }
                    loading={
                      mutation.isLoading && tool.tool_key === currentLoading
                    }
                    label={t("reservationAutomation.active")}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
