import dayjs from "dayjs";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { createReservationNote, fetchReservatioNotes } from "../../shared/queries";
import { ReservationNote, ReservationV3, ReservationNoteChangeLog } from "../../types";
import Button from "../Button";
import FormField from "../FormField";
import Icon from "../Icon";
import TextInput from "../TextInput";
import RelativeTime from 'dayjs/plugin/relativeTime'
import { useTranslation } from "react-i18next";
import { object } from "joi";



dayjs.extend(RelativeTime)

type ReservationNoteProps = {
  reservation: ReservationV3
}

export default function ReservationNoteList ({
  reservation
} : ReservationNoteProps) {

  const {
    data,
    refetch
  } = useQuery(['resnotes', reservation.resv_key], () => fetchReservatioNotes(reservation.resv_key))
  
  const { t } = useTranslation()


  const mutation = useMutation(() => createReservationNote(reservation.resv_key || '', newNote), {
    onSuccess: () => {
      refetch()
      toast.success(t('general.requestCompleted'))
      setNewNote('')
    },
    onError: () => {
      toast.error(t('general.requestError'))
    }
  })

  const [viewMore, setViewMore] = useState<boolean>(false)
  const [newNote, setNewNote] = useState<string>('')
  const [fullDate, setFullDate] = useState<boolean>(false)

  const iconMap = {
    CREATED: {icon: 'plus', class: 'bg-blue-600 text-white'},
    UPDATED: {icon: 'pencil', class: 'bg-yellow-600 text-white'},
    UPDATED_LOCAL: {icon: 'pencil', class: 'bg-yellow-600 text-white'},
    NOTIFYGUEST: {icon: 'bell', class: 'bg-green-600 text-white'},
    AIRBNB_NOTIFYGUEST_OK: {icon: 'bell', class: 'bg-green-600 text-white'},
    PA_SEND_OK: {icon: 'scale', class: 'bg-cyan-600'},
    PA_SEND_KO: {icon: 'exclamation', class: 'bg-red-600'},
    POLICIANACIONAL_SEND_OK: {icon: 'truck', class: 'bg-cyan-600'},
    POLICIANACIONAL_SEND_KO: {icon: 'exclamation', class: 'bg-red-600'},
    MOSSOS_SEND_OK: {icon: 'finger-print', class: 'bg-cyan-600'},
    MOSSOS_SEND_KO: {icon: 'exclamation', class: 'bg-red-600'},
    FILLUP: {icon: 'paper-airplane', class: 'bg-green-600 text-white'},
    CONFIRM: {icon: 'check', class: 'bg-green-600 text-white'},
    SAVEDOCS: {icon: 'document-add', class: 'bg-blue-300'},
    NEXMOREQ: {icon: 'phone', class: 'bg-indigo-600'},
    MARK: {icon: 'color-swatch', class: 'text-white bg-gray-600'},
    PARTIAL: {icon: 'paper-airplane', class: 'bg-green-200 text-green-800'},
    SMS_NOTIFYGUEST_OK: {icon: 'commenting', class: 'bg-green-600 text-white'},
    SMS_NOTIFYGUEST_KO: {icon: 'commenting', class: 'bg-red-600'},
    PENCIL: {icon: 'pencil', class: 'bg-pink-300'},
    DOCS_CONFIRMATION_REMOVED: {icon:'exclamation', class: 'bg-yellow-600 text-white'},      
    DOC_MANUALLY_DELETED: {icon:'identification', class: 'bg-red-600 text-white'},
    DOC_MANUALLY_UPDATED: {icon:'identification', class: 'bg-yellow-600 text-white'} ,
    GUEST_START_VIDEOCALL: {icon:'videocall', class: 'bg-yellow-600 text-white'},  
    HOST_JOIN_VIDEOCALL: {icon:'videocall', class: 'bg-yellow-600 text-white'}  
  }
  function parseAux3(aux3:string){
    try{
      return JSON.parse(aux3)

    }catch(e){
      if (typeof(aux3) == 'object'){
        return aux3
      }
      return []
    }
  }


  function getIconData (key: string) {
    if (key === 'CREATED' ||
      key === 'UPDATED' ||
      key === 'UPDATED_LOCAL' ||
      key === 'NOTIFYGUEST' ||
      key === 'AIRBNB_NOTIFYGUEST_OK' ||
      key === 'PA_SEND_OK' ||
      key === 'PA_SEND_KO' ||
      key === 'POLICIANACIONAL_SEND_OK' ||
      key === 'POLICIANACIONAL_SEND_KO' ||
      key === 'MOSSOS_SEND_OK' ||
      key === 'MOSSOS_SEND_KO' ||
      key === 'FILLUP' ||
      key === 'CONFIRM' ||
      key === 'SAVEDOCS' ||
      key === 'PENCIL' ||
      key === 'NEXMOREQ' ||
      key === 'MARK' ||
      key === 'PARTIAL' ||
      key === 'SMS_NOTIFYGUEST_OK' ||
      key === 'SMS_NOTIFYGUEST_KO' ||
      key === 'DOCS_CONFIRMATION_REMOVED'||
      key === 'DOC_MANUALLY_DELETED' ||
      key === 'GUEST_START_VIDEOCALL' ||
      key === 'HOST_JOIN_VIDEOCALL' ||
      key === 'DOC_MANUALLY_UPDATED' ) {
      return iconMap[key]
    }
    return {
      icon: 'PENCIL',
      class: ''
    }
  }

  return (
    <div>

      <div className={'flex mb-4'}>
        <FormField
          className={'px-2 w-full'}
          label={t('resv.newNote')}>
          <TextInput
            placeholder={t('general.add')}
            value={newNote}
            className={'pr-8 rounded-2xl'}
            onChange={val => setNewNote(val)}
            renderButton={() => (
              <button
                onClick={() => mutation.mutate()}  
                className={'focus:outline-none hover:text-cyan-600 px-1 mr-1'} style={{
                  transform: 'rotate(40deg)'
                }}>
                <Icon name={'paper-airplane'} size={'1.1rem'}></Icon>
              </button>
            )}></TextInput>
        </FormField>
      </div>
      
      <div className={'bg-white rounded-2xl border mx-2 p-4'}>
        {
          (data && data.results) && data.results.slice(0, viewMore ? 200 : 10).map((note: ReservationNote, index: number) => (
            <div
              className={`text-sm flex ${((data.results.length - 1) !== index) ? 'mb-4' : ''}`}
              key={index}>
              
              <div className={'relative'}>
                <div
                  className={`mr-4 w-8 h-8 flex items-center justify-center rounded-2xl bg-gray-500 ${note.key === 'AUTO' ? getIconData(note.body || '').class : getIconData('PENCIL').class}`}>
                  {note.key === 'AUTO' &&
                    <Icon
                      name={getIconData(note.body || '').icon}
                      size={'16px'}></Icon>
                  }
                  {note.key === 'MANUAL' &&
                    <Icon
                      name={getIconData('PENCIL').icon}
                      size={'16px'}></Icon>
                  }
                </div>
                { (index < (viewMore ? data.results.length - 1 : 9) && (data.results.length - 1) !== index) && <div className={'border-r-2 h-full absolute'} style={{ left: '30%' }}></div> }
              </div>

              <div>
                {
                  note.key === 'AUTO' && <div className={'font-medium'}>{t('reservationNotes.' + note.body)}</div>
                }

                {
                  note.key === 'MANUAL' && note.body
                }

                {
                  (note.body === 'NOTIFYGUEST' || note.body === 'AIRBNB_NOTIFYGUEST_OK' || note.body === 'AIRBNB_NOTIFYGUEST_KO') && (
                    <div className={'text-gray-500'}>
                      { note.aux1 && t('reservationMailTypes.' + note.aux1)}
                      { note.aux2 && 'email: ' + note.aux2 }
                    </div>
                  )
                }

                {
                  (note.body === 'SMS_NOTIFYGUEST_OK' || note.body === 'SMS_NOTIFYGUEST_KO') && (
                    <span>
                      { note.aux1 && t('reservationSmsTypes.' +note.aux1) + ' '}
                      { note.aux3 && 'phone: ' + note.aux3 }
                    </span>
                  )
                }

                {
                  note.body === 'MARK' && (
                    <span className={'text-gray-500 flex items-center'}>
                      nuovo colore  
                      { note.aux1 == '1' && <div className={'ml-2 w-4 h-4 rounded-2xl bg-gray-200 border border-gray-300'}></div> }
                      { note.aux1 == '2' && <div className={'ml-2 w-4 h-4 rounded-2xl bg-green-600'}></div> }
                      { note.aux1 == '3' && <div className={'ml-2 w-4 h-4 rounded-2xl bg-blue-600'}></div> }
                      { note.aux1 == '4' && <div className={'ml-2 w-4 h-4 rounded-2xl bg-yellow-600'}></div> }
                      { note.aux1 == '5' && <div className={'ml-2 w-4 h-4 rounded-2xl bg-yellow-700'}></div> }
                      { note.aux1 == '6' && <div className={'ml-2 w-4 h-4 rounded-2xl bg-red-600'}></div> }
                    </span>
                  )
                }

                {
                  (note.body !== 'MARK' && note.body !== 'SMS_NOTIFYGUEST_OK' && note.body !== 'NOTIFYGUEST' && note.body !== 'AIRBNB_NOTIFYGUEST_OK' && note.body !== 'AIRBNB_NOTIFYGUEST_KO') && (
                    <div className={'text-gray-500'}>              
                      { note.aux1 && ' ' + note.aux1 }
                      { note.aux2 && ' | ' + note.aux2 }

                      {/* {
                        (note.aux3) && parseAux3(note.aux3).map((val:ReservationNoteChangeLog, index:number) => (
                          val.admin == false ? index > 0 ? ' | '  + t('reservationNotes.' + val.key) + ' ' + t('reservationNotes.from') + ' ' + val.a + ' ' + t('reservationNotes.to') + ' ' + val.v + t('reservationNotes.changed_by_' + val.from_integr) : t('reservationNotes.' + val.key) + ' ' + t('reservationNotes.from') + ' ' + ' ' + val.a + ' ' + ' ' + t('reservationNotes.to') + ' ' + val.v + t('reservationNotes.changed_by_' + val.from_integr) : ' '
                      ))}
                      { note.aux3 && typeof(note.aux3 === 'string') && parseAux3(note.aux3).length === 0 && ' | ' + note.aux3 } */}
                    </div>
                  )
                }
                <div
                  onClick={() => setFullDate(!fullDate)}
                  className={'text-gray-600 mt-1'}>
                  {
                    dayjs(note.date).format('YYYY-MM-DD HH:mm:ss')
                  }
                </div>
              </div>

            </div>
          ))
        }
      </div>

      {
        (data && data.results && data.results.length > 10) &&
        <div className={'flex items-center -mx-1'}>
          <div className={'mt-2'}>
            <Button
              icon={viewMore ? 'eye-off' : 'eye'}
              onClick={() => setViewMore(!viewMore)}>
                { viewMore ? t('general.hide') : t('general.showAll')}
            </Button>
          </div>
        </div>
      }

    </div>
  )

}