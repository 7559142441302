import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  fetchAllModules,
  fetchCustomEmails,
  sendEmail,
  updateReservationV3,
} from "../../shared/queries";
import { moduleId } from "../../shared/utils/config";
import { BaseSelectItem, ReservationV3 } from "../../types";
import Button from "../Button";
import Checkbox from "../Checkbox";
import FormField from "../FormField";
import FormSectionTitle from "../FormSectionTitle";
import SelectInput from "../SelectInput";
import ReservationNoteList from "./ReservationNoteList";

type ReservationNotificationsProps = {
  reservation: ReservationV3;
  onSave: () => void;
};

export default function ReservationNotifications({
  reservation,
  onSave,
}: ReservationNotificationsProps) {
  const { t } = useTranslation();
  const isFirstRun = useRef<boolean>(true);
  const [data, setData] = useState<ReservationV3>(reservation);
  const [sendStatusEmail, setSendStatusEmail] = useState("");

  const mutation = useMutation(
    () =>
      updateReservationV3({
        chk_cc: data.chk_cc,
        notify_mail_guest_active: data.notify_mail_guest_active,
        chk_mail: data.chk_mail,
        chk_sms: data.chk_sms,
        chk_whatsapp: data.chk_whatsapp,
        chk_sms_done: data.chk_sms_done,
        chk_whatsapp_done: data.chk_whatsapp_done,
        resv_key: data.resv_key,
        guest_email: reservation.guest_email,
        notify_now: data.notify_now,
        notify_1days: data.notify_1days,
        notify_3days: data.notify_3days,
        notify_7days: data.notify_7days,
        notify_done_1days: data.notify_done_1days,
        cust_email_ptr: data.cust_email_ptr,
      }),
    {
      onSuccess: () => {
        console.log("success");
        toast.success(t("general.updated"));
        if (onSave) onSave();
      },
      onError: () => {
        console.log("error");
        toast.error(t("general.requestError"));
      },
    }
  );

  useEffect(() => {
    if (isFirstRun.current === true) {
      isFirstRun.current = false;
      return;
    }
    if (!data) return;
    const timer = setTimeout(() => {
      mutation.mutate();
    }, 600);
    return () => clearTimeout(timer);
  }, [data]);

  const { data: emails, isLoading: isLoadingEmails } = useQuery(
    ["emails"],
    () => fetchCustomEmails(undefined, true)
  );

  function getEmailOptions() {
    return emails?.results
      ? [
          ...emails.results.map((c) => {
            return {
              label: c.objkey,
              value: c.id,
            };
          }),
          {
            label: "Vikey default",
            value: 0,
          },
        ]
      : [];
  }

  const mutateSendEmail = useMutation(
    () => sendEmail(sendStatusEmail, reservation?.resv_key ?? ""),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );

  function sendEmailOptions() {
    return [
      { label: t("resv.F_TODO"), value: "F_TODO" },
      { label: t("resv.F_DONE"), value: "F_DONE" },
      // { label: t("resv.DEPOSIT_GUEST_REMIND"), value: "DEPOSIT_GUEST_REMIND" },
      ];
  }

  const { data: dataModules } = useQuery<any>([reservation.local_key], () => {
    if (reservation.local_key) {
      return fetchAllModules(reservation.local_key);
    }
  });

  return (
    <>
      <FormSectionTitle title={t("resv.notificationSettings")} borderless />

      {dataModules?.modules?.map((module: any) => {
        if (module.id === moduleId.sms) {
          return (
            <Checkbox
              label={`${t("resv.sendRemindSMS")} ${
                module.date_closed
                  ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                      module.date_closed
                    ).format("DD-MM-YYYY")})`
                  : ""
              }`}
              className={"mb-2 max-w-max"}
              hint={t("resv.twoDaySMSRemind")}
              disabled={!module.active}
              onChange={() => {
                setData({
                  ...data,
                  chk_sms: !data.chk_sms,
                });
              }}
              checked={data.chk_sms || false}
            />
          );
        }
        return null;
      })}

      {dataModules?.modules?.map((module: any) => {
        if (module.id === moduleId.whatsapp) {
          return (
            <Checkbox
              label={`${t("resv.sendRemindWHATSAPP")} ${
                module.date_closed
                  ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                      module.date_closed
                    ).format("DD-MM-YYYY")})`
                  : ""
              }`}
              className={"mb-2 max-w-max"}
              hint={t("resv.threeDayWHATSAPPRemind")}
              disabled={!module.active}
              onChange={() => {
                setData({
                  ...data,
                  chk_whatsapp: !data.chk_whatsapp,
                });
              }}
              checked={data.chk_whatsapp || false}
            />
          );
        }
        return null;
      })}
      {dataModules?.modules?.map((module: any) => {
        if (module.id === moduleId.sms_done) {
          return (
            <Checkbox
              label={`${t("resv.sendRemindSMSdone")} ${
                module.date_closed
                  ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                      module.date_closed
                    ).format("DD-MM-YYYY")})`
                  : ""
              }`}
              className={"mb-2 max-w-max"}
              disabled={!module.active}
              onChange={() => {
                setData({
                  ...data,
                  chk_sms_done: !data.chk_sms_done,
                });
              }}
              checked={data.chk_sms_done || false}
            />
          );
        }
        return null;
      })}
      {dataModules?.modules?.map((module: any) => {
        if (module.id === moduleId.whatsapp_done) {
          return (
            <Checkbox
              label={`${t("resv.sendRemindWHATSAPPdone")} ${
                module.date_closed
                  ? `(${t("resv.activeUntil").toLowerCase()} ${dayjs(
                      module.date_closed
                    ).format("DD-MM-YYYY")})`
                  : ""
              }`}
              className={"mb-2 max-w-max"}
              disabled={!module.active}
              onChange={() => {
                setData({
                  ...data,
                  chk_whatsapp_done: !data.chk_whatsapp_done,
                });
              }}
              checked={data.chk_whatsapp_done || false}
            />
          );
        }
        return null;
      })}

      <Checkbox
        checked={data.chk_mail || false}
        className={"mb-2 max-w-max"}
        label={t("resv.enableEmails")}
        hint={t("resv.enableEmailsText")}
        onChange={() => {
          setData({
            ...data,
            chk_mail: !data.chk_mail,
          });
        }}
      />

      {data.chk_mail && (
        <div className="space-y-4 ml-10 mt-4 mb-4 p-4 border border-gray-200 rounded-2xl bg-white">
          <FormField label={t("resv.emailToBeSent")}>
            <SelectInput<BaseSelectItem, false>
              value={getEmailOptions().find(
                (e) => e.value === data.cust_email_ptr
              )}
              onChange={(item) => {
                if (item) {
                  setData({
                    ...data,
                    cust_email_ptr: item.value,
                  });
                }
              }}
              placeholder={t("resv.chooseEmail")}
              isLoading={isLoadingEmails}
              options={getEmailOptions()}
            />
          </FormField>
          <Checkbox
            label={t("resv.createNotification")}
            checked={data.notify_now}
            onChange={() =>
              setData({
                ...data,
                notify_now: !data.notify_now,
              })
            }
          />

          <Checkbox
            label={t("resv.oneDayNotification")}
            checked={data.notify_1days}
            onChange={() =>
              setData({
                ...data,
                notify_1days: !data.notify_1days,
              })
            }
          />

          <Checkbox
            label={t("resv.threeDayNotification")}
            checked={data.notify_3days}
            onChange={() =>
              setData({
                ...data,
                notify_3days: !data.notify_3days,
              })
            }
          />

          <Checkbox
            label={t("resv.sevenDayNotification")}
            checked={data.notify_7days}
            onChange={() =>
              setData({
                ...data,
                notify_7days: !data.notify_7days,
              })
            }
          />

          <Checkbox
            label={t("integration.completeNotification")}
            checked={data.notify_done_1days}
            onChange={() =>
              setData({
                ...data,
                notify_done_1days: !data.notify_done_1days,
              })
            }
          />
        </div>
      )}

      <Checkbox
        checked={data.chk_cc || false}
        className={"mb-2 max-w-max"}
        label={t("resv.enableCCEmail")}
        hint={t("resv.enableCCEmailText")}
        onChange={() => {
          setData({
            ...data,
            chk_cc: !data.chk_cc,
          });
        }}
      />

      <Checkbox
        checked={data.notify_mail_guest_active || false}
        className={"mb-2 max-w-max"}
        label={t("resv.enableGuestActive")}
        onChange={() => {
          setData({
            ...data,
            notify_mail_guest_active: !data.notify_mail_guest_active,
          });
        }}
      />

      <FormSectionTitle title={t("resv.notificationSettings")} />

      <div className="space-y-4 mt-4 mb-4 p-4 border border-gray-200 rounded-2xl bg-white">
        <FormField label={t("resv.emailToBeSent")}>
          <SelectInput<BaseSelectItem, false>
            // value={sendEmailOptions().find(
            //   (e) => e.value === data.cust_email_ptr
            // )}
            onChange={(item) => {
              if (item) {
                setSendStatusEmail(item.value);
              }
            }}
            placeholder={t("resv.chooseEmail")}
            isLoading={isLoadingEmails}
            options={sendEmailOptions()}
          />
        </FormField>
        <Button
          icon="send"
          label={t("resv.send")}
          onClick={() => {
            mutateSendEmail.mutate();
          }}
        />
      </div>

      <div className={"w-full"}>
        <FormSectionTitle title={t("resv.notifsAndEvents")} />
        <ReservationNoteList reservation={reservation} />
      </div>
    </>
  );
}
