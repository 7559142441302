import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  activateModule,
  deactivateModule,
  fetchAllModules,
  fetchReasonDeactivation,
} from "../../shared/queries";
import FormSectionTitle from "../FormSectionTitle";
import Modal from "../Modal";
import ModalActions from "../ModalAction";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import ModuleCard from "../module/ModuleCard";
import TextInput from "../TextInput";
import { moduleId } from "../../shared/utils/config";
import FormField from "../FormField";
import Checkbox from "../Checkbox";

const ApartmentModularity = (local: any) => {
  const { t } = useTranslation();
  const confirmationKey = t("general.iConfirm");

  const { data: reasonList } = useQuery<any>(["reason"], () =>
    fetchReasonDeactivation()
  );

  const [confirmValue, setConfirmValue] = useState<string>("");
  const [showConfirmDialog, setShowConfirmDialog] = useState<any>({
    display: false,
    id: null,
    name: null,
    active: false,
    price: 0,
  });
  const [reasonDeactivaton, setReasonDeactivaton] = useState<any>([]);
  const [textDisable, setTextDisable] = useState<boolean>(true);
  const [other, setOther] = useState<{
    isActive: boolean;
    text: string;
  }>({
    isActive: false,
    text: "",
  });

  const { data: dataModules, refetch: refetchModules } = useQuery<any>([], () =>
    fetchAllModules(local.data.local_key)
  );

  const activateMutation = useMutation(
    (data: { local_keys: string[]; module_id: number }) =>
      activateModule(data.local_keys, data.module_id),
    {
      onSuccess: () => {
        window.dataLayer.push({
          event: "modulo_attivato_appartamenti_moduli",
          gtm: {
            id_modulo: showConfirmDialog.id,
            value: showConfirmDialog.price
              ? showConfirmDialog.price > 0
                ? (showConfirmDialog.price / 100).toFixed(2)
                : 0
              : 0,
          },
        });
        toast.success(t("general.requestCompleted"));
        refetchModules();
        setShowConfirmDialog({
          display: false,
          id: null,
          name: null,
          active: false,
          price: 0,
        });
      },
      onError: (error: Error) => {
        toast.error(t("general.requestError"));
      },
    }
  );

  const deactivateMutation = useMutation(
    (data: {
      local_keys: string[];
      module_id: number;
      reason: any;
      other: string | null;
    }) =>
      deactivateModule(
        data.local_keys,
        data.module_id,
        data.reason,
        data.other
      ),
    {
      onSuccess: () => {
        toast.success(t("general.requestCompleted"));
        refetchModules();
        setShowConfirmDialog({
          display: false,
          id: null,
          name: null,
          active: false,
        });
        setReasonDeactivaton([]);
        setOther({
          isActive: false,
          text: "",
        });
      },
      onError: (error: Error) => {
        toast.error(t("general.requestError"));
      },
    }
  );

  const getBundleSpecsArray = (
    bundles: { id: number; date_closed: string }[]
  ) => {
    const bundleSpecs: { id: any; date_closed: string }[] = [];
    bundles?.map((bundle) =>
      bundleSpecs.push({ id: bundle.id, date_closed: bundle.date_closed })
    );
    return bundleSpecs;
  };

  const renderHint = (d: any) => {
    if (d.id === moduleId.paytourist) {
      return (
        <div className={"leading-relaxed font-normal text-xs text-gray-500"}>
          {t(`module.id${d.id}Info`)}
          <div>
            Elenco città: Castello/Sicilia, Agerola/Campania, Agropoli/Campania,
            Alberobello/Puglia, Amalfi/Campania, Ascea/Campania, Bari/Puglia,
            Bari Sardo/Sardegna, Bracciano/Lazio, Budoni/Sardegna,
            Camerota/Campania, Capaccio Paestum/Campania, Casal Velino/Campania,
            Caserta/Campania, Castellabate/Campania, Castellammare del Golfo/Sicilia,
            Castelmola/Sicilia, Centola/Campania, Fasano/Puglia, Formia/Lazio,
            Ischia/Campania, Locorotondo/Puglia, Maiori/Campania, Minori/Campania,
            Monopoli/Puglia, Montecorice/Campania, Padula/Campania,
            Perledo/Lombardia, Peschiera del Garda/Veneto, Pimonte/Campania,
            Polignano a Mare/Puglia, Pollica/Campania, Ravello/Campania,
            Rieti/Lazio, Roseto degli Abruzzi/Abruzzo, San Giovanni a
            Piro/Campania, San Mauro Cilento/Campania, Sapri/Campania,
            Specchia/Puglia, Taormina/Sicilia, Tertenia/Sardegna, Tramonti/Campania,
            Vercana/Lombardia, Vibo Valentia/Calabria, Vibonati/Campania,
            Vietri sul Mare/Campania
          </div>
        </div>
      );
    }
    return (
      <div className={"leading-relaxed font-normal text-xs text-gray-500"}>
        {t(`module.id${d.id}Info`)}
      </div>
    );
  };

  return (
    <>
      <FormSectionTitle
        borderless
        title={t("navigation.modules")}
      ></FormSectionTitle>
      {dataModules?.modules?.length > 0 ? (
        dataModules?.modules?.map((d: any) => (
          <ModuleCard
            key={d.id}
            moduleName={t(`module.id${d.id}`)}
            active={d.active}
            dateClosed={d.date_closed}
            price={d.self_price}
            defaultPrice={d.default_price}
            onChange={() =>
              setShowConfirmDialog({
                display: true,
                id: d.id,
                name: `${t(`module.id${d.id}`)}`,
                active: d.active,
                date_closed: d.date_closed,
                price:
                  d.self_price !== null
                    ? d.self_price >= 0
                      ? d.self_price
                      : d.default_price
                    : d.default_price,
              })
            }
            bundled={d.activated_by_modules?.length > 0}
            bundlesSpecs={getBundleSpecsArray(d.activated_by_modules)}
            hint={renderHint(d)}
            freeMonthRemain={d.self_free_months_remains}
            blockedActivation={d.self_blocked_activation}
            blockedDeactivation={d.self_blocked_deactivation}
            isApartmentView
            modId={d.id}
          />
        ))
      ) : (
        <div className={"text-sm mt-2 ml-2 text-gray-600"}>
          Non sono disponibili moduli
        </div>
      )}
      <Modal visible={showConfirmDialog.display}>
        <ModalCard
          title={
            showConfirmDialog.active && !showConfirmDialog.date_closed
              ? t("general.confirmDeactivation") +
                " - " +
                showConfirmDialog.name
              : t("general.confirmActivation") + " - " + showConfirmDialog.name
          }
        >
          <ModalSection>
            {showConfirmDialog.active && !showConfirmDialog.date_closed && (
              <div className={`p-4 mb-5`}>
                <FormField label={t("module.selectMotifs")}>
                  {reasonList.results?.map((option: any) => (
                    <Checkbox
                      checked={reasonDeactivaton.find(
                        (first: any) => first.id === option.id
                      )}
                      label={t(`module.motif${option.id}`)}
                      onChange={() =>
                        setReasonDeactivaton((prev: any) => {
                          const previousArray = [...prev];
                          const findedPrev = previousArray.find(
                            (prev) => prev.id === option.id
                          );
                          if (findedPrev) {
                            const dataNew = previousArray?.filter(
                              (prev) => prev.id !== option.id
                            );
                            return dataNew;
                          }
                          previousArray.push({
                            id: option.id,
                            text: `module.motif${option.id}`,
                          });
                          return previousArray;
                        })
                      }
                    />
                  ))}
                  <div className="md:flex gap-2">
                    <Checkbox
                      checked={other.isActive}
                      label={t("cats.altro")}
                      onChange={() => {
                        setOther(
                          (prev: { isActive: boolean; text: string }) => {
                            return {
                              ...prev,
                              isActive: !prev.isActive,
                            };
                          }
                        );
                      }}
                    />
                    {other.isActive && (
                      <TextInput
                        placeholder={t("serviceQuestionItem.freeText")}
                        value={other.text}
                        onChange={(val) => {
                          if (!val) {
                            setTextDisable(true);
                          } else {
                            setTextDisable(false);
                          }
                          setOther(
                            (prev: { isActive: boolean; text: string }) => {
                              return {
                                ...prev,
                                text: val,
                              };
                            }
                          );
                        }}
                      />
                    )}
                  </div>
                </FormField>
              </div>
            )}
            <div className="text-gray-700 p-4 pt-0 border-gray-300">
              {t("general.write1")}
              <span className={"font-bold"}>{confirmationKey}</span>{" "}
              {t("general.write2")}
              <div className="mt-2">
                <TextInput
                  value={confirmValue}
                  onChange={(val) => setConfirmValue(val)}
                />
              </div>
            </div>
            <ModalActions
              onSave={() => {
                if (confirmValue === confirmationKey) {
                  setConfirmValue("");
                  if (
                    showConfirmDialog.active &&
                    !showConfirmDialog.date_closed
                  ) {
                    const reasonsValue: any = [];
                    reasonDeactivaton?.forEach(
                      (element: { id: any; text: any }) => {
                        reasonsValue.push(element.id);
                      }
                    );
                    deactivateMutation.mutate({
                      local_keys: [local.data.local_key],
                      module_id: showConfirmDialog.id,
                      reason: reasonsValue,
                      other: other.isActive
                        ? other.text === ""
                          ? null
                          : other.text
                        : null,
                    });
                  } else {
                    activateMutation.mutate({
                      local_keys: [local.data.local_key],
                      module_id: showConfirmDialog.id,
                    });
                  }
                  setReasonDeactivaton([]);
                }
              }}
              saveDisabled={
                (showConfirmDialog.active &&
                  !showConfirmDialog.date_closed &&
                  reasonDeactivaton.length === 0 &&
                  ((other.isActive && !other.text) || !other.isActive)) ||
                (showConfirmDialog.active &&
                  !showConfirmDialog.date_closed &&
                  reasonDeactivaton.length > 0 &&
                  other.isActive &&
                  textDisable)
              }
              saveLabel={
                showConfirmDialog.active && !showConfirmDialog.date_closed
                  ? t("general.disable")
                  : t("general.enable")
              }
              onClose={() => {
                setShowConfirmDialog({
                  display: false,
                  id: null,
                  name: null,
                  active: false,
                  price: 0,
                });
                setReasonDeactivaton([]);
                setOther({
                  isActive: false,
                  text: "",
                });
              }}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
    </>
  );
};

export default ApartmentModularity;
