import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageWrapper from "../PageWrapper";
import PreviousPage from "../PreviousPage";
import PageTitle from "../PageTitle";
import FileUploadComponent from "./FileUploadComponent";
import {
  fetchSingleMessageContext,
  quickSettingsAction,
} from "../../shared/queries";
import CircleSpinner from "../CircleSpinner";
import Button from "../Button";
import { useQuery } from "react-query";
import Icon from "../Icon";
import { assistantStatusStyle } from "../../shared/utils/CommonStyles";
import AssistantConnections from "../assistant/AssistantConnections";
import { toast } from "react-toastify";

import TooltipModal from "../TooltipModal";
import Glossary from "../common/Glossary";
import { handleFileContentEdit } from "../../shared/utils/common";
import { KnowledgeBaseEdit } from "../assistant/KnowledgeBaseEdit";
import ReactTooltip from "react-tooltip";

interface MessageContextDetailParams {
  context_id: string;
}

export default function MessageContextDetail() {
  const { t } = useTranslation();
  const { context_id } = useParams<MessageContextDetailParams>();

  const {
    data: messageContextData,
    isLoading: isMessageContextLoading,
    isError: isMessageContextError,
    error: messageContextError,
    refetch: refetch_message_context_data,
  } = useQuery(
    ["messageContextData", context_id],
    () => fetchSingleMessageContext({ context_id }),
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );
  // console.log("message context data ", messageContextData);

  const [fileContent, setFileContent] = useState("");

  const [fileSavingStatus, setFileSavingStatus] = useState(false);

  const [assisantStatusLoading, setAssistantStatusLoading] = useState(false);

  const [fetchError, setFetchError] = useState<string | null>(null);
  const history: any = useHistory();
  const handleQuickSetting = async (action: string) => {
    setAssistantStatusLoading(true);
    try {
      const response = await quickSettingsAction({ context_id, action });

      if (response) {
        toast.success(t(response.msg));
      } else {
        toast.error(t("general.KO"));
      }
    } catch (error: any) {
      toast.error(t(error.message));
    }

    refetch_message_context_data();
    setAssistantStatusLoading(false);
  };

  let assistantRunningModes = [
    {
      label: t("assistant.running_mode.faq"),
      type: "faq_mode",
      onClick: () => {
        handleQuickSetting("faq_mode");
      },
    },
    {
      label: t("assistant.running_mode.file"),
      type: "file_mode",
      onClick: () => {
        handleQuickSetting("file_mode");
      },
    },
  ];
  let assistantLearningModes = [
    {
      label: t("assistant.learning_mode.nonautonomous_learning"),
      type: "nonautonomous_learning",
      onClick: () => {
        handleQuickSetting("nonautonomous_learning");
      },
    },
    {
      label: t("assistant.learning_mode.autonomous_learning"),
      type: "autonomous_learning",
      onClick: () => {
        handleQuickSetting("autonomous_learning");
      },
    },
  ];
  let assistantResponseMode = [
    {
      label: t("assistant.response_mode.nonautonomous"),
      type: "nonautonomous",
      onClick: () => {
        handleQuickSetting("nonautonomous_response");
      },
    },
    {
      label: t("assistant.response_mode.autonomous"),
      type: "autonomous",
      onClick: () => {
        handleQuickSetting("autonomous_response");
      },
    },
  ];
  let assistantDeploy = [
    {
      label: t("assistant.deploy"),
      type: "deploy_assistant",
      onClick: () => {
        handleQuickSetting("deploy_assistant");
      },
    },
    {
      label: t("assistant.cancel_deploy"),
      type: "cancel_deploy",
      onClick: () => {
        handleQuickSetting("cancel_deploy");
      },
    },
  ];
  let assistantFileModeButton = {
    label: fileSavingStatus ? t("general.saving") : t("general.save"),
    onClick: (
      context_id: string,
      file_id: string | undefined,
      firestore_document_id: string | undefined,
      new_content: string
    ) => {
      handleFileContentEdit(
        context_id,
        file_id,
        firestore_document_id,
        new_content,
        setFileSavingStatus,
        refetch_message_context_data,
        t
      );
    },
  };

  let unlockAssistantButton = {
    label: t("assistant.unlockAssistant"),
    onClick: () => {
      handleQuickSetting("unlock_message_context");
    },
  };

  useEffect(() => {
    if (messageContextData?.files) {
      const uniqueContent = Array.from(
        new Set(messageContextData.files.map((file) => file.file_content))
      ).join("");
      setFileContent(uniqueContent);
    }
  }, [messageContextData?.files]);

  return (
    <PageWrapper>
      <PreviousPage className="mb-4" label={t("general.goBack")} />
      <div className="w-full">
        {isMessageContextLoading ? (
          <div className="flex justify-center items-center h-full">
            <CircleSpinner color={"primary"} />
          </div>
        ) : fetchError ? (
          <div className="text-red-600 text-center">{fetchError}</div>
        ) : (
          <>
            <div className="bg-white border border-gray-300 shadow rounded-2xl p-4 grid grid-cols-1 lg:grid-cols-9 gap-3">
              {/* Left Side */}
              <div className="flex flex-col lg:col-span-6">
                {/* PageTitle and Running Modes */}
                <div className="flex items-center gap-4 mb-4">
                  <PageTitle
                    title={messageContextData ? messageContextData.name : ""}
                    noMargin={true}
                  />

                  {messageContextData?.status && (
                    <div className="flex items-center gap-2">
                      <span
                        className={`px-2 py-1 text-xs font-medium rounded-full ${
                          assistantStatusStyle[
                            messageContextData.status.toLowerCase() as keyof typeof assistantStatusStyle
                          ] || assistantStatusStyle.waiting
                        }`}
                      >
                        {t(`assistant.status_${messageContextData.status}`)}
                      </span>
                      {assisantStatusLoading && (
                        <CircleSpinner color={"primary"} />
                      )}
                    </div>
                  )}

                  {messageContextData?.status.toLocaleLowerCase() ===
                    "error" && (
                    <Button
                      onClick={unlockAssistantButton.onClick}
                      label={unlockAssistantButton.label}
                      color="info"
                    />
                  )}
                  <TooltipModal strokeColor="#e8a317" icon="questionMark">
                    <Glossary target={"ASS"} />
                  </TooltipModal>
                </div>

                <h2 className="font-semibold">
                  {t("assistant.running_mode_label")}
                </h2>
                <div className="border border-gray-300 overflow-hidden shadow rounded-2xl flex flex-wrap w-full mt-2">
                  {assistantRunningModes.map((running_mode) => (
                    <button
                      key={running_mode.label}
                      onClick={running_mode.onClick}
                      className={`${
                        messageContextData?.running_mode === running_mode.type
                          ? "bg-cyan-400 text-white"
                          : "hover:bg-gray-100"
                      } focus:outline-none font-medium py-2 text-sm cursor-pointer flex-1 flex items-center justify-start gap-2`}
                    >
                      <div className="w-20 flex justify-center">
                        <Icon name={running_mode.type} size="25px" />
                      </div>
                      <span>{running_mode.label}</span>
                    </button>
                  ))}
                </div>

                <div className="border border-gray-300 overflow-hidden shadow rounded-2xl flex flex-wrap w-full mt-2">
                  {assistantResponseMode.map((response_mode) => (
                    <button
                      key={response_mode.label}
                      onClick={response_mode.onClick}
                      data-tip={response_mode.type === "autonomous" ? t("general.NotAvailable") : ""}
                      disabled={response_mode.type === "autonomous"}
                      className={`${ response_mode.type === "autonomous" ? "bg-gray-300":
                        messageContextData?.response_mode === response_mode.type
                          ? "bg-cyan-400 text-white"
                          : "hover:bg-gray-100"
                      } focus:outline-none font-medium py-2 text-sm cursor-pointer flex-1 flex items-center justify-start gap-2`}
                    >
                      <div className="w-20 flex justify-center">
                        <Icon name={response_mode.type} size="25px" />
                      </div>
                      <span>{response_mode.label}</span>
                    </button>
                    
                  ))}
                  <ReactTooltip />

                </div>
                <div className="border border-gray-300 overflow-hidden shadow rounded-2xl flex flex-wrap w-full mt-2">
                  {assistantLearningModes.map((learning_mode) => (
                    <button
                      key={learning_mode.label}
                      onClick={learning_mode.onClick}
                      className={`${
                        messageContextData?.learning_mode === learning_mode.type
                          ? "bg-cyan-400 text-white"
                          : "hover:bg-gray-100"
                      } focus:outline-none font-medium py-2 text-sm cursor-pointer flex-1 flex items-center justify-start gap-2`}
                    >
                      <div className="w-20 flex justify-center">
                        <Icon name={learning_mode.type} size="25px" />
                      </div>
                      <span>{learning_mode.label}</span>
                    </button>
                  ))}
                </div>
                <h2 className="font-semibold mt-2">
                  {t("assistant.deploy_status")}
                </h2>
                <div className="border border-gray-300 overflow-hidden shadow rounded-2xl flex flex-wrap w-full mt-2">
                  {messageContextData?.status.toLocaleLowerCase() ===
                  "approved" ? (
                    <button
                      key={assistantDeploy[1].label}
                      onClick={assistantDeploy[1].onClick}
                      className="hover:bg-gray-100 focus:outline-none font-medium py-2 text-sm cursor-pointer flex-1 flex items-center justify-start gap-2"
                    >
                      <div className="w-20 flex justify-center">
                        <Icon name={assistantDeploy[1].type} size="25px" />
                      </div>
                      <span>{assistantDeploy[1].label}</span>
                    </button>
                  ) : (
                    <button
                      key={assistantDeploy[0].label}
                      onClick={assistantDeploy[0].onClick}
                      className="hover:bg-gray-100 focus:outline-none font-medium py-2 text-sm cursor-pointer flex-1 flex items-center justify-start gap-2"
                    >
                      <div className="w-20 flex justify-center">
                        <Icon name={assistantDeploy[0].type} size="25px" />
                      </div>
                      <span>{assistantDeploy[0].label}</span>
                    </button>
                  )}
                </div>
                {/* Header section with file controls */}

                <h2 className="font-semibold mt-2">
                  {t("assistants.local_files")}
                </h2>
                <FileUploadComponent
                  context_id={context_id}
                  initialFiles={messageContextData?.files}
                  file_element="ASSISTANT-CONTEXT"
                  className="max-h-60 overflow-auto mt-4"
                  running_mode={messageContextData?.running_mode}
                  refetch={refetch_message_context_data}
                />

                <KnowledgeBaseEdit
                  refetch_message_context_data={refetch_message_context_data}
                  isMessageContextLoading={isMessageContextLoading}
                  messageContextData={messageContextData}
                  t={t}
                />
              </div>

              {/* Right Side */}
              <div className="flex flex-col lg:col-span-3">
                <h2 className="font-semibold mb-4">
                  {t("assistant.connections")}
                </h2>
                <div className="border border-gray-300 shadow rounded-2xl flex flex-col p-4">
                  <AssistantConnections
                    context_id={messageContextData?.context_id}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
}
