import React, { useState, useRef, useEffect } from "react";
import PageWrapper from "../PageWrapper";
import Icon from "../Icon";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { MessageContextData, UploadedFile } from "../../types";
import {
  uploadFile,
  deleteSingleFile,
  editFirestoreDocumentFileContent,
} from "../../shared/queries";
import Checkbox from "../Checkbox";
import { toast } from "react-toastify";
import CircleSpinner from "../CircleSpinner";

interface FileUploadComponentProps {
  context_id: string;
  local_key?: string;
  className?: string;
  file_element: string;
  initialFiles: UploadedFile[] | null | undefined;
  maxFilesUploadable?: number;
  running_mode?: string | null | undefined | "faq_mode" | "file_mode";
  refetch?: () => void;

  context?: MessageContextData;
}

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  context_id,
  local_key,
  className,
  file_element,
  initialFiles,
  maxFilesUploadable,
  running_mode,
  refetch,
  context,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const retryUpload = async (file: UploadedFile, index: number) => {
    if (!file.originalFile) {
      console.error(`Original file not found for ${file.file_name}`);
      return;
    }

    try {
      if (file) {
        const fileBase64 = await encodeFileToBase64(file.originalFile);
        const file_name = file.originalFile.name;
        const uploadedFile = await uploadFile(
          context_id,
          fileBase64,
          file_name,
          file.file_mime_type,
          file.file_extension,
          file.originalFile.size
        );

        setFiles((prevFiles) =>
          prevFiles.map((f, f_index) =>
            f_index === index
              ? {
                  ...f,
                  file_id: uploadedFile.file_id,
                  uploadFailed: false,

                  firestore_document_id: uploadedFile.firestore_document_id,
                }
              : f
          )
        );
      }
    } catch (error:any) {
      // console.log(error)
      // console.error(`Error re-uploading file ${file.file_name}:`, error);
      toast.error(t(error));
      setFiles((prevFiles) =>
        prevFiles.map((f, f_index) =>
          f_index === index ? { ...f, uploadFailed: true } : f
        )
      );
    }
  };

  const encodeFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const processAndUploadFiles = async (filesToUpload: File[]) => {
    for (const file of filesToUpload) {
      console.log(file);
      let file_name = file.name;
      let extension = file_name.substring(file_name.lastIndexOf(".") + 1);
      let file_mime_type = file.type;

      const placeholderFile: UploadedFile = {
        file_name: file.name,
        context_id,
        file_mime_type: file_mime_type,
        file_extension: extension,
        uploadFailed: false,
        originalFile: file,
      };

      try {
        const fileBase64 = await encodeFileToBase64(file);
        const uploadedFile = await uploadFile(
          context_id,
          fileBase64,
          file_name,
          file_mime_type,
          extension,
          file.size
        );

        setFiles((prevFiles) => [
          ...prevFiles,
          {
            ...placeholderFile,
            file_id: uploadedFile.file_id,
            firestore_document_id: uploadedFile.firestore_document_id,
          },
        ]);
      } catch (error: any) {
        console.error(`Error uploading file ${file.name}:`, error);
        toast.error(t(error));
        setFiles((prevFiles) => [
          ...prevFiles,
          { ...placeholderFile, uploadFailed: true },
        ]);
      }
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = Array.from(event.target.files || []);
    await processAndUploadFiles(selectedFiles);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    console.log(event.dataTransfer.files);
    const droppedFiles = Array.from(event.dataTransfer.files).filter((file) =>
      /\.(pdf|docx|pptx|txt)$/i.test(file.name)
    );
    console.log("DROPPED FILE AAAAAAAAAA ", droppedFiles);
    await processAndUploadFiles(droppedFiles);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleRemoveFile = async (
    fileToRemove: UploadedFile,
    index: number
  ) => {
    let deleted = false;
    try {
      if (fileToRemove.file_id) {
        deleted = await deleteSingleFile(fileToRemove);
      }
      if (deleted) {
        toast.success(t("assistant.deleted_file"));
        setFiles((prevFiles) =>
          prevFiles.filter((file) => file.file_id !== fileToRemove.file_id)
        );

        if (refetch) {
          refetch();
        }
      }
    } catch (error: any) {
      toast.error(t(error));
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileGenerateFaqs = async (
    file_id: string | undefined,
    firestore_document_id: string | undefined,
    context_id: string,
    action: string
  ) => {
    if (!file_id || !context_id) {
      toast.error(t("assistant.missingFile"));
    }
    // console.log("loaded file ",file_id)

    // console.log("loaded file ",firestore_document_id)
    setIsLoading(true);
    try {
      let response: any = await editFirestoreDocumentFileContent({
        context_id,
        firestore_document_id,
        file_id,
        action,
      });

      if (response.msg) {
        toast.success(t(`${response.msg}`));
        setIsLoading(false);
        if (refetch) {
          refetch();
        }
      }
    } catch (error: any) {
      toast.error(t(error));
      setIsLoading(false);
    }
  };
  const handleGenerateFaqsOptions = [
    {
      label: t("assistant.faq_mode.generate_faqs_prompt"),
      onClick: (
        file_id: string | undefined,
        firestore_document_id: string | undefined,
        context_id: string
      ) =>
        handleFileGenerateFaqs(
          file_id,
          firestore_document_id,
          context_id,
          "generate_faqs"
        ),
    },
    {
      label: t("assistant.faq_mode.remove_generate_faqs"),
      onClick: (
        file_id: string | undefined,
        firestore_document_id: string | undefined,
        context_id: string
      ) =>
        handleFileGenerateFaqs(
          file_id,
          firestore_document_id,
          context_id,
          "remove_generate_faqs"
        ),
    },
  ];
  useEffect(() => {
    if (initialFiles) {
      setFiles(initialFiles);
    }
  }, [initialFiles]);

  return (
    <PageWrapper className={className}>
      {/* Header section with file controls */}

      {/* Main content area */}
      <div
        className={`flex items-center min-h-[200px] p-2 xs:p-4 transition-all duration-200 ${
          isDragging ? "border-cyan-500 bg-cyan-50" : "border-gray-300"
        }`}
      >
        {files.length === 0 ? (
          <div className="flex flex-col items-center justify-center w-full cursor-pointer xs:p-4">
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={handleFileChange}
              accept=".pdf, .docx, .pptx, .txt"
              multiple={maxFilesUploadable ? true : false}
            />
            <span
              className="text-gray-500 text-center flex flex-col items-center justify-center"
              onClick={handleUploadButtonClick}
            >
              <Icon
                name="add-file"
                size="40px"
                className="text-gray-400 mb-2"
              />
              <span className="text-sm xs:text-base">
                {t("file_upload.prompt")}
              </span>
            </span>

            {isDragging && (
              <span className="text-cyan-600 text-sm xs:text-base">
                {t("assistant.local_files.drag_file_prompt")}
              </span>
            )}
          </div>
        ) : (
          <div className="w-full space-y-2">
            {files.map((file, index) => (
              <div
                key={index}
                className="flex flex-col xs:flex-row items-center justify-between border-b py-2 gap-2 xs:gap-0"
              >
                <div className="flex items-center w-full xs:w-auto justify-between">
                  <span className="text-gray-500 truncate text-sm xs:text-base w-full xs:w-auto text-center xs:text-left">
                    {file.file_name}
                  </span>
                  <div className="flex items-center ml-auto">
                    {file.uploadFailed ? (
                      <>
                        <Button
                          icon="refresh"
                          onClick={() => retryUpload(file, index)}
                          className="bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
                        />
                        <Button
                          icon="trash"
                          onClick={() => handleRemoveFile(file, index)}
                          className="bg-red-500 text-white rounded-lg hover:bg-red-600 ml-2"
                        />
                      </>
                    ) : (
                      <>
                        {running_mode === "faq_mode" &&
                          files.length > 0 &&
                          !context?.is_last_generate_faqs_old &&
                          !files[0].generate_faqs && (
                            <Button
                              onClick={() =>
                                handleGenerateFaqsOptions[0].onClick(
                                  files[0].file_id,
                                  files[0].firestore_document_id,
                                  context_id
                                )
                              }
                              disabled={
                                files.length === 0 || files[0]?.uploadFailed
                              }
                              icon="magic_wand"
                              loading={isLoading}
                              dataTip={t("fileUpload.assistant.generateFaqs")}
                              className="bg-cyan-500 text-white rounded-lg hover:bg-cyan-600"
                            />
                          )}
                        {running_mode === "faq_mode" &&
                          files.length > 0 &&
                          files[0].generate_faqs && (
                            <Button
                              onClick={() =>
                                handleGenerateFaqsOptions[1].onClick(
                                  files[0].file_id,
                                  files[0].firestore_document_id,
                                  context_id
                                )
                              }
                              disabled={
                                files.length === 0 || files[0]?.uploadFailed
                              }
                              icon="magic_wand_empty"
                              loading={isLoading}
                              dataTip={t(
                                "fileUpload.assistant.remove_faq_generation"
                              )}
                              className="bg-cyan-500 text-white rounded-lg hover:bg-cyan-600"
                            />
                          )}
                        <Button
                          icon="trash"
                          onClick={() => handleRemoveFile(file, index)}
                          className="bg-cyan-500 text-white rounded-lg hover:bg-red-600 ml-2"
                          dataTip={t("fileUpload.permanentlyDeleteFile")}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default FileUploadComponent;
