import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";

type Props = {
  code: any;
  language: "html" | "javascript";
};

const Codebox = (props: Props) => {
  const { code, language } = props;
  return (
    <SyntaxHighlighter language={language} style={dark}>
      {String(code)}
    </SyntaxHighlighter>
  );
};

export default Codebox;
