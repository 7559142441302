import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom"; // Portal support
import Button from "./Button";
import { MessageContextDataLocal } from "../types";
import { AssistantBubble } from "./assistant/AssistantBubble";
import Modal from "./Modal";
import PageWrapper from "./PageWrapper";
import PageTitle from "./PageTitle";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { fetchSingleMessageContext } from "../shared/queries";
import { KnowledgeBaseEdit } from "./assistant/KnowledgeBaseEdit";

type EditAssistantModalProps = {
  visible: boolean;
  contexts: MessageContextDataLocal[];
  onClose?: () => void;
  className?: string;
  noBackdropDismiss?: boolean;
};

export const EditAssistantModal: React.FC<EditAssistantModalProps> = ({
  visible,
  onClose,
  contexts,
  className = "",
  noBackdropDismiss = false,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [currentAssistantLocal, setCurrentAssistantLocal] =
    useState<MessageContextDataLocal>();
  const { t } = useTranslation();

  const {
    data: messageContextData,
    isLoading: isMessageContextLoading,
    isError: isMessageContextError,
    error: messageContextError,
    refetch: refetch_message_context_data,
  } = useQuery(
    ["messageContextData", currentAssistantLocal],
    async () => {
      if (!currentAssistantLocal) return null; 
      return fetchSingleMessageContext({
        context_id: currentAssistantLocal.context_id, 
      });
    },
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );
  

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      !noBackdropDismiss &&
      overlayRef.current &&
      event.target === overlayRef.current &&
      onClose
    ) {
      onClose();
    }
  };
  const currentItemToggle = (item: MessageContextDataLocal | undefined) => {
    if (currentAssistantLocal) {
      setCurrentAssistantLocal(undefined);
    } else {
      setCurrentAssistantLocal(item);
    }
  };

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);

  if (!visible) return null;

  return (
    <Modal visible={visible} className="w-full" skipPreventDefualt={true}>
      <PageWrapper
        className="bg-white p-4 rounded-2xl w-full lg:w-2/3 overflow-auto"
        style={{ maxHeight: "85vh" }}
      >
        {!currentAssistantLocal ? (
          <>
            {/* Row container for button and assistant bubble */}
            <div className="flex flex-col space-y-4">
              {/* Button row */}
              <div className="flex justify-between items-center">
                <PageTitle
                  title={t("assistant.assistants")}
                  className="w-full sm:w-auto"
                />
                <Button
                  onClick={onClose}
                  className="text-gray-600 bg-white hover:bg-gray-100 focus:outline-none shadow-md rounded-3xl p-2"
                  icon="close"
                />
              </div>

              {/* Assistant Bubble */}
              {visible && contexts && (
                <motion.div
                  ref={overlayRef}
                  onClick={handleBackdropClick}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <AssistantBubble
                    data={contexts}
                    onClick={currentItemToggle}
                  />
                </motion.div>
              )}
            </div>
          </>
        ) : (
          <motion.div
            ref={overlayRef}
            onClick={handleBackdropClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Button
              onClick={() => setCurrentAssistantLocal(undefined)}
              label={t("general.goBack")}
              color="info"
              icon="arrow-narrow-left"
            ></Button>
            <KnowledgeBaseEdit
              refetch_message_context_data={refetch_message_context_data}
              isMessageContextLoading={isMessageContextLoading}
              messageContextData={messageContextData}
              t={t}
            />
          </motion.div>
        )}
      </PageWrapper>
    </Modal>
  );
};
