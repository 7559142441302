import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Button from "../components/Button";
import ConfirmDialog from "../components/common/ConfirmDialog";
import {
  deleteStripeAccount,
  fetchStripeAccounts,
  setStripeAccount,
  setStripeExpressAccount,
  setStripeExpressAccountV3,
} from "../shared/queries";
import { getOldToken } from "../shared/utils/tokenStore";
import { RootState } from "../reducers";
import { useSelector } from "react-redux";
import Icon from "../components/Icon";

export default function StripeConnect() {
  const { t } = useTranslation();

  const { data } = useQuery("stripeAccounts", () =>
    fetchStripeAccounts(undefined)
  );

  const { profile } = useSelector((state: RootState) => state.login);
  const [currentAccount, setCurrentAccount] = useState<number | undefined>();
  const [showSetAll, setShowSetAll] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [accountId, setAccountId] = useState("");

  const setAllMutation = useMutation(
    (id: string | number) =>
      setStripeAccount({
        action: "SETALL",
        stripe_account: id,
      }),
    {
      onSuccess: () => {
        setShowSetAll(false);
        toast.success(t("general.operationCompleted"));
      },
    }
  );
  const stipeExpressMutation = useMutation(() => setStripeExpressAccount(), {
    onSuccess: (data) => {
      window.open(data.results.url, "_self");
    },
  });

  useQuery(
    ["stipeExpressMutation", accountId],
    () => setStripeExpressAccountV3(accountId),
    {
      onSuccess: (data) => {
        if (data.results.url) {
          window.open(data.results.url, "_blank");
        }
      },
      enabled: accountId !== "",
    }
  );

  const deleteAccountMutation = useMutation(
    (id: string | number) => deleteStripeAccount(id),
    {
      onSuccess: () => {
        setShowDelete(false);
        toast.success(t("general.operationCompleted"));
      },
    }
  );

  return (
    <div>
      {profile &&
        profile.user_key &&
        [
          "VT1xfjPoskpbCWGj0000000000000000",
          "DV8FOzJ5cjMSI67t0000000000000000",
          "FV1D9yGU1Ct5tWEA0000000000000000",
          "CC25u7xWn6HgHBep0000000000000000",
          "AL6KsXYdsiuSPLmh0000000000000000",
          "AI2IMDsuOkvHHwuD0000000000000000",
          "SG1xfQ1NnLSzAVXz0000000000000000",
          "JR1N6fdIXVjUwW4k0000000000000000",
          "Ls88kabA1e7AEayz0000000000000000",
          "Gm77lv7VjNnseULI0000000000000000",
          "Pd1dYe5qwUJpDiSi0000000000000000",
          "HS2dOSouzJ0B8N7a0000000000000000",
          "NC0Yh8Squms61a190000000000000000",
          "FM0JivEZP3lhScE60000000000000000",
          "PD2i5dcP7h5gY3m70000000000000000"
        ].includes(profile.user_key) && (
          <>
            <div className="flex">
              <Icon name="stripe-express" size="30px" />
              <p className="font-extrabold text-3xl">Stripe Express</p>
            </div>

            <p className={"text-gray-600 mt-8"}>
              {t("stripe.expressFirstTitle")}
            </p>
            <p className={"text-gray-600 mt-4 text-lg font-bold"}>
              {t("stripe.expressTryIt")}
            </p>
            <ul className="mt-2">
              {t("stripe.expressTryItDescription")
                .split("///")
                .map((desc) => (
                  <li className="flex text-md text-gray-600" key={desc}>
                    <Icon
                      name="clipboard-check"
                      size="20px"
                      className="text-green-500"
                    />
                    {desc}
                  </li>
                ))}
            </ul>

            <Button
              className={"sm:mt-8 mt-3 sm:ml-1 ml:0"}
              disabled={stipeExpressMutation.isLoading}
              loading={stipeExpressMutation.isLoading}
              onClick={() => {
                stipeExpressMutation.mutate();
              }}
              color={"primary"}
              label={t("Stripe Express")}
            />

            <div className="my-8">
              <div className="flex items-center space-x-8 mb-6">
                <div className="font-semibold text-xl">
                  Account Stripe Express
                </div>
                <div className="border-b flex-1" />
              </div>
              <div className="mt-2 space-y-1">
                {data &&
                  data.results &&
                  data.results
                    .filter((account: any) => account.express)
                    .map((account: any) => (
                      <div
                        className={
                          "flex flex-col lg:flex-row lg:items-center lg:space-x-2 justify-between"
                        }
                        key={account.id}
                      >
                        <div className="flex space-x-2">
                          <div className={"text-gray-600"}>
                            {account.customer_id}
                          </div>

                          <div className={"font-medium"}>{account.name}</div>
                        </div>

                        <div
                          className={"lg:space-x-2 flex flex-col lg:flex-row"}
                        >
                          <Button
                            color={"info"}
                            className="bg-green-600 text-white"
                            flat
                            onClick={() => {
                              setAccountId(account.id);
                            }}
                            label={t("Express")}
                          />
                          <Button
                            color={"primary"}
                            flat
                            loading={setAllMutation.isLoading}
                            label={t("stripe.useForAll")}
                            onClick={() => {
                              setCurrentAccount(account.id);
                              setShowSetAll(true);
                            }}
                          />
                          <Button
                            color={"negative"}
                            flat
                            loading={deleteAccountMutation.isLoading}
                            onClick={() => {
                              setCurrentAccount(account.id);
                              setShowDelete(true);
                            }}
                            label={t("general.delete")}
                          />
                        </div>
                      </div>
                    ))}
              </div>
            </div>

            <div className="border-b-2 flex-1 mb-8 border-cyan-600" />
          </>
        )}

      <img
        className={"h-10"}
        src="/images/logos/stripe.svg"
        alt="Stripe Connect"
      />

      <p className={"text-gray-600 mt-8"}>
        {t("stripe.ifEnable")} <br></br>
        {t("stripe.ifNot")}
      </p>
      <p className={"text-gray-600 mt-4"}>{t("stripe.commission")}</p>

      <Button
        className={"mt-8"}
        onClick={() => {
          window.open(
            "https://connect.stripe.com/oauth/authorize?scope=read_write&state=" +
              getOldToken() +
              "&response_type=code&client_id=ca_E4TfkUxhtE5y3yGeByWH3QoXineu2ixm",
            "_blank"
          );
        }}
        color={"primary"}
        label={t("stripe.activate")}
      />

      <div className="mt-8">
        <div className="flex items-center space-x-8 mb-6">
          <div className="font-semibold text-xl">Account Stripe</div>
          <div className="border-b flex-1" />
        </div>
        <div className="mt-2">
          {data &&
            data.results &&
            data.results
              .filter((account: any) => !account.express)
              .map((account: any) => (
                <div
                  className={
                    "flex flex-col lg:flex-row lg:items-center lg:space-x-2 justify-between"
                  }
                >
                  <div className="flex space-x-2">
                    <div className={"text-gray-600"}>{account.customer_id}</div>

                    <div className={"font-medium"}>{account.name}</div>
                  </div>

                  <div className={"lg:space-x-2 flex flex-col lg:flex-row"}>
                    <Button
                      color={"primary"}
                      flat
                      loading={setAllMutation.isLoading}
                      label={t("stripe.useForAll")}
                      onClick={() => {
                        setCurrentAccount(account.id);
                        setShowSetAll(true);
                      }}
                    />
                    <Button
                      color={"negative"}
                      flat
                      loading={deleteAccountMutation.isLoading}
                      onClick={() => {
                        setCurrentAccount(account.id);
                        setShowDelete(true);
                      }}
                      label={t("general.delete")}
                    />
                  </div>
                </div>
              ))}
        </div>
      </div>

      <ConfirmDialog
        askConfirmation={true}
        message={
          "Sei sicuro di voler associare questo account Stripe Connect a tutti gli appartamenti?"
        }
        confirmationKey={t("general.iConfirm")}
        visible={showSetAll}
        onCancel={() => {
          setShowSetAll(false);
        }}
        onConfirm={() => {
          if (currentAccount && data) setAllMutation.mutate(currentAccount);
        }}
      />

      <ConfirmDialog
        askConfirmation={true}
        message={"Sei sicuro di voler eliminare questo account Stripe Connect?"}
        confirmationKey={t("general.iConfirm")}
        visible={showDelete}
        onCancel={() => {
          setShowDelete(false);
        }}
        onConfirm={() => {
          if (currentAccount && data)
            deleteAccountMutation.mutate(currentAccount);
        }}
      />
    </div>
  );
}
