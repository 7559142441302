import { useEffect, useState } from "react";
import { GuestDocument, ReservationV3, SingleApiResponseV3 } from "../../types";
import AsyncImage from "../AsyncImage";
import Button from "../Button";
import Modal from "../Modal";
import ModalCard from "../ModalCard";
import ModalSection from "../ModalSection";
import ModalActions from "../ModalAction";
import { useMutation, useQuery } from "react-query";
import {
  fetchReservationv3,
  markReservationDocuments,
  fetchReservationAction,
  updateReservationV3,
  removeDocConfirmation,
  importDocsFromResvKey,
} from "../../shared/queries";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReservationDocument from "./ReservationDocument";
import ReservationDocumentBuroCard from "./ReservationDocumentBuroCard";
import FormSectionTitle from "../FormSectionTitle";
import Checkbox from "../Checkbox";
import FormField from "../FormField";
import TextInput from "../TextInput";

type ReservationDocumentsProps = {
  reservation: ReservationV3;
  onSave: () => void;
};

export default function ReservationDocuments({
  reservation,
  onSave,
}: ReservationDocumentsProps) {
  const { t } = useTranslation();
  const confirmationKey = t("general.iConfirm");

  const [documents, setDocuments] = useState<GuestDocument[]>([]);
  const [resv, setResv] = useState<ReservationV3>(reservation);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [
    showConfirmDialogRemoveDocConfirm,
    setShowConfirmDialogRemoveDocConfirm,
  ] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [confirmValue, setConfirmValue] = useState<string>("");
  const [resValue, setResValue] = useState<string>("");

  var { data, refetch } = useQuery<SingleApiResponseV3<ReservationV3>, Error>(
    ["reservation", reservation.resv_key],
    () => fetchReservationv3(reservation.resv_key)
  );

  const updateRsvMutation = useMutation(
    (res: ReservationV3) =>
      updateReservationV3({
        resv_key: resv.resv_key,
        enable_pa: res.enable_pa,
        enable_t5: res.enable_t5,
        enable_auto_declaration_citytax: res.enable_auto_declaration_citytax,
      }),
    {
      onSuccess: () => {
        toast.success(t("general.updated"));
        if (onSave) onSave();
      },
    }
  );

  const mutation = useMutation(
    () => markReservationDocuments(reservation.resv_key, documents),
    {
      onSuccess: () => {
        onSave();
        setShowConfirmDialog(false);
        toast.success(t("general.operationCompleted"));
      },
      onError: () => {
        toast.error(t("general.requestError"));
      },
    }
  );

  const removeDocConfirmationMutation = useMutation(
    () => removeDocConfirmation(reservation.resv_key),
    {
      onSuccess: () => {
        onSave();
        setShowConfirmDialogRemoveDocConfirm(false);
        toast.success(t("general.operationCompleted"));
      },
      onError: (error: Error) => {
        toast.error(error.message || t("general.requestError"));
      },
    }
  );

  const update_reservation = useMutation(() => refetch());

  const importDocumentResvKey = useMutation(
    (data: { from_resv_key: string; to_resv_key: string }) =>
      importDocsFromResvKey(data.from_resv_key, data.to_resv_key),
    {
      onSuccess: () => {
        toast.success(t("general.operationCompleted"));
        setModalVisible(false);
        setConfirmValue("");
        setResValue("");
        refetch();
      },
      onError: () => {
        toast.error(t("general.requestError"));
        setModalVisible(false);
        setConfirmValue("");
        setResValue("");
      },
    }
  );

  useEffect(() => {
    if (data && data.results && data.results.ndocs) {
      try {
        setDocuments(JSON.parse(data.results.ndocs || "[}"));
      } catch (e) {
        console.error("error parsing ndcos list");
      }
    }
  }, [data]);

  return (
    <div className={""}>
      <FormSectionTitle
        borderless
        title={t("configuration.buro")}
        // edit={true}
        onEdit={() => {
          // setUpdateDetails(true);
        }}
      ></FormSectionTitle>
      <div className="mb-8">
        <Checkbox
          checked={resv.enable_pa ?? false}
          className={"mb-2 max-w-max"}
          label={t("resv.enablePA")}
          onChange={() => {
            setResv({ ...resv, enable_pa: !resv.enable_pa });
            updateRsvMutation.mutate({ ...resv, enable_pa: !resv.enable_pa });
          }}
        />
        <Checkbox
          checked={resv.enable_t5 ?? false}
          className={"mb-2 max-w-max"}
          label={t("resv.enableT5")}
          onChange={() => {
            setResv({ ...resv, enable_t5: !resv.enable_t5 });
            updateRsvMutation.mutate({ ...resv, enable_t5: !resv.enable_t5 });
          }}
        />
        { reservation.local?.paytourist_cred[0]?.id && (
          <Checkbox
          checked={resv.enable_auto_declaration_citytax ?? false}
          className={"mb-2 max-w-max"}
          label={t("resv.enable_auto_declaration_citytax")}
          onChange={() => {
            setResv({ ...resv, enable_auto_declaration_citytax: !resv.enable_auto_declaration_citytax });
            updateRsvMutation.mutate({ ...resv, enable_auto_declaration_citytax: !resv.enable_auto_declaration_citytax });
          }}
        />
        )}
      </div>
      {resv.chk_documents_confirm &&
        (resv.local?.creds_portale_alloggiati_id !== null ||
          resv.local?.alloggiati_id != null) &&
        <ReservationDocumentBuroCard rsv={reservation} type="portale-alloggiati"/>
      }
      {/* {resv.chk_documents_confirm &&
        resv.local?.paytourist_creds_id !== null &&
        <ReservationDocumentBuroCard rsv={reservation} type="paytourist"/>
      }
      {resv.chk_documents_confirm &&
        resv.local?.trento_cred[0] !== null &&
        <ReservationDocumentBuroCard rsv={reservation} type="istat-trento"/>
      } */}
      { resv.chk_documents_confirm && resv.local?.turismo5_id !== null &&
        <ReservationDocumentBuroCard rsv={reservation} type="ross1000"/>
      }
      { resv.chk_documents_confirm && resv.local?.radar_cred[0] !== null &&
        <ReservationDocumentBuroCard rsv={reservation} type="radar"/>
      }
      { resv.chk_documents_confirm && resv.local?.czech_uby_cred[0] !== null && resv.local?.czech_uby_cred[0] !== undefined &&
        <ReservationDocumentBuroCard rsv={reservation} type="uby"/>
      }
      { resv.chk_documents_confirm && resv.local?.hospedajes_cred[0] !== null && resv.local?.hospedajes_cred[0] !== undefined &&
        <ReservationDocumentBuroCard rsv={reservation} type="ses-hospedajes"/>
      }
      { resv.chk_documents_confirm &&
        resv.local?.sef_cred[0]?.id &&
        <ReservationDocumentBuroCard rsv={reservation} type="sef"/>
      }
      { resv.chk_documents_confirm &&
      (resv.local?.ricestat_cred[0]?.ricestat_creds_id) &&
          <ReservationDocumentBuroCard rsv={reservation} type="motourist"/>
      }
      { resv.chk_documents_confirm &&
      (resv.local?.mossos_creds_id !== null) &&
          <ReservationDocumentBuroCard rsv={reservation} type="mossos-esquadra"/>
      }
      { resv.chk_documents_confirm &&
      (resv.local?.guardia_civil_creds_id !== null) &&
          <ReservationDocumentBuroCard rsv={reservation} type="guardia-civil"/>
      }
      { resv.chk_documents_confirm &&
      (resv.local?.policia_nacional_creds_id !== null) &&
          <ReservationDocumentBuroCard rsv={reservation} type="policia-nacional"/>
      }
      <FormSectionTitle borderless title={t('resv.documents')} dense subtitle={reservation.docs_auto_confirmed ? t('resv.doc_autoconfirmed') : ''} />
      {reservation.docs_auto_confirmed && reservation.resv_status === 'FUTURE' &&
        <Button
          onClick={() =>
            fetchReservationAction({
              resv_key: reservation.resv_key,
              action: "DOWNLOAD_RESV_ZIP",
            })
          }
          className={"mt-2"}
          label={t("reservationDocuments.download")}
        />}
      <Modal visible={showConfirmDialog} isResvDocs>
        <ModalCard title={t("reservationDocuments.documentApproval")}>
          <ModalSection>
            <div className="p-4">
              {t("reservationDocuments.confirmApproval")}
            </div>
            <ModalActions
              onClose={() => setShowConfirmDialog(false)}
              onSave={() => mutation.mutate()}
              saveLabel={t("reservationDocuments.approve")}
              isLoading = {mutation.isLoading}
            />
          </ModalSection>
        </ModalCard>
      </Modal>

      <Modal visible={showConfirmDialogRemoveDocConfirm} isResvDocs>
        <ModalCard title={t("reservationDocuments.removeDocConfimation")}>
          <ModalSection>
            <div className="p-4">
              {t("reservationDocuments.removeDocConfimationInfo")}
            </div>
            <ModalActions
              onClose={() => setShowConfirmDialogRemoveDocConfirm(false)}
              onSave={() => removeDocConfirmationMutation.mutate()}
              saveLabel={t("reservationDocuments.removeDocConfimation")}
            />
          </ModalSection>
        </ModalCard>
      </Modal>
      {!reservation.chk_documents_confirm &&
        reservation.local.user_key === "EV29eWR4MeVtKSE90000000000000000" && (
        <>
          <div
            style={{
              boxShadow:
                "0px 2px 4px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1)",
            }}
            className={"rounded-2xl p-4 bg-white m-2 mb-5 space-x-10 lg:w-1/3"}
          >
            <>
              <FormField
                label={t(
                  "Importa documenti inserendo il codice di prenotazione"
                )}
              >
                <div className="border-b border-gray-200 my-5"></div>

                <TextInput
                  value={resValue}
                  onChange={(value) => {
                    setResValue(value);
                  }}
                ></TextInput>
              </FormField>
              <div className="flex justify-end">
                <Button
                  onClick={() => {
                    setModalVisible(true);
                  }}
                  className={"mt-2"}
                  label={t("Importa documenti")}
                />
              </div>
            </>
          </div>
          {/* MODALE */}
          <Modal visible={modalVisible} isResvDocs>
            <ModalCard title={`Importa documenti da ${resValue.toUpperCase()}`}>
              <ModalSection>
                {/* <div className="p-4 mb-5">&euro;{(module?.module?.price / 100).toFixed(2)}</div> */}
                <div className="text-gray-700 p-4 pt-0 border-gray-300">
                  {t("general.write1")}
                  <span className={"font-bold"}>{confirmationKey}</span>{" "}
                  {t("general.write2")}
                  <div className="mt-2">
                    <TextInput
                      value={confirmValue}
                      onChange={(val) => setConfirmValue(val)}
                    />
                  </div>
                </div>
              </ModalSection>
              <ModalActions
                onSave={() => {
                  importDocumentResvKey.mutate({
                    from_resv_key: resValue,
                    to_resv_key: reservation.resv_key,
                  });
                }}
                saveLabel={"SALVA"}
                onClose={() => {
                  setModalVisible(false);
                }}
              />
            </ModalCard>
          </Modal>
        </>
      )}
      {documents.length === 0 && (
        <div className={"flex w-full flex-col items-center justify-center"}>
          <AsyncImage isApi={false} url={"/search.svg"} className={"h-52"} />
          <div className={"text-center"}>
            <div className={"text-lg font-medium text-gray-800"}>
              {t("reservationDocuments.noDocumentsFound")}
            </div>
            <div className={"text-gray-600"}>
              {t("reservationDocuments.noDocumentsFoundText")}
            </div>
          </div>
        </div>
      )}

      <div className={"grid md:grid-cols-2 grid-cols-1 "}>
        {!reservation.chk_documents_confirm &&
          reservation.chk_documents_done &&
          documents &&
          documents.length > 0 && (
            <div
              style={{
                boxShadow:
                  "0px 2px 4px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1)",
              }}
              className={
                "rounded-2xl p-4 bg-white items-center flex m-2 space-x-10"
              }
            >
              <AsyncImage
                isApi={false}
                url={"/confirm.svg"}
                className={"h-72"}
              />
              <div>
                <div className="text-xl font-bold mb-4">
                  {t("reservationDocuments.confirmDocuments")}
                </div>
                <p className={"block mb-4"}>
                  {t("reservationDocuments.reviewDocuments")}
                </p>
                <div
                  className={"flex w-full flex-col items-center justify-center"}
                >
                  <Button
                    onClick={() =>
                      fetchReservationAction({
                        resv_key: reservation.resv_key,
                        action: "DOWNLOAD_RESV_ZIP",
                      })
                    }
                    className={"mt-2"}
                    label={t("reservationDocuments.download")}
                  />
                  <Button
                    onClick={() => setShowConfirmDialog(true)}
                    className={"mt-2"}
                    label={t("reservationDocuments.approve")}
                  />
                </div>
              </div>
            </div>
          )}

        {documents.map(
          (document: GuestDocument, index: number) =>
            reservation.guests_num &&
            index < reservation.guests_num && (
              <ReservationDocument
                document={document}
                chk_documents_confirm={reservation.chk_documents_confirm}
                reservation={reservation}
                resv_key={reservation.resv_key}
                key={index}
                onUpdateReservation={() => update_reservation.mutate()}
              />
            )
        )}
        {reservation.chk_documents_confirm &&
          reservation.local.user_key === "EV29eWR4MeVtKSE90000000000000000" && (
            <div
              style={{
                boxShadow:
                  "0px 2px 4px rgba(0,0,0,0.1), 0px 0px 0px 1px rgba(0,0,0,0.1)",
              }}
              className={
                "rounded-2xl p-4 bg-white items-center flex m-2 space-x-10"
              }
            >
              <div>
                <div className="text-xl font-bold mb-4">
                  {t("reservationDocuments.removeDocConfimation")}
                </div>
                <p className={"block mb-4"}>
                  {t("reservationDocuments.removeDocConfimationInfo")}
                </p>
                <div
                  className={"flex w-full flex-col items-center justify-center"}
                >
                  <Button
                    color="negative"
                    onClick={() => setShowConfirmDialogRemoveDocConfirm(true)}
                    className={"mt-2"}
                    label={t("reservationDocuments.removeDocConfimation")}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
