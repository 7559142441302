import React, { useState, useRef, useEffect } from "react";
import PageWrapper from "../PageWrapper";
import Button from "../Button";
import FaqList from "./FaqList";
import { FaqEntry } from "../../types";
import { useTranslation } from "react-i18next";
import {
  createOrUpdateFaqs,
  fetchFaqData,
  fetchSingleMessageContext,
} from "../../shared/queries";
import { toast } from "react-toastify";
import Select from "react-select/dist/declarations/src/Select";
import SelectInput from "../SelectInput";
import { useQuery } from "react-query";
import CircleSpinner from "../CircleSpinner";

interface ApartmentFaqsProps {
  context_id?: string;
  local_key?: string;
  faqs?: Array<FaqEntry> | null;
  refetch?: () => any;
  maxHeight?: string;
}
export const ApartmentFaqs: React.FC<ApartmentFaqsProps> = ({
  context_id,
  local_key,
  refetch,
  maxHeight = "100vh",
}) => {
  const faqEnd = useRef<null | HTMLDivElement>(null);
  const {
    data: faqs,
    isLoading: isFaqDataLoading,
    isError: isFaqDataError,
    error: faqDataError,
    refetch: refetchFaqData,
  } = useQuery(["faqData", context_id], () => fetchFaqData(context_id), {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<number, string[]>>({});
  const [faqData, setFaqData] = useState<FaqEntry[]>(
    faqs?.results ? faqs.results : []
  );

  // Separate state for created, updated, and deleted FAQs

  const [sessionFaqs, setSessionFaqs] = useState<{
    created: FaqEntry[];
    updated: FaqEntry[];
    deleted: FaqEntry[];
  }>({
    created: [],
    updated: [],
    deleted: [],
  });

  const scrollToBottom = () => {
    if (faqEnd.current) {
      setTimeout(() => {
        faqEnd.current?.scrollTo({
          top: faqEnd.current.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  const handleQuestionChange = (index: number, value: string) => {
    if (!faqs?.results) return;
    const updatedFaqs = [...faqData];
    updatedFaqs[index].question = value;

    if (updatedFaqs[index].entry_id) {
      setSessionFaqs((prev) => ({
        ...prev,
        updated: prev.updated.some(
          (faq) => faq.entry_id === updatedFaqs[index].entry_id
        )
          ? prev.updated.map((faq) =>
              faq.entry_id === updatedFaqs[index].entry_id
                ? updatedFaqs[index]
                : faq
            )
          : [...prev.updated, updatedFaqs[index]],
      }));
    }
    setFaqData(updatedFaqs);
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[index];
      return updatedErrors;
    });
  };
  const handleAvailabilityChange = (
    index: number,
    data: { id: number; label: string; value: string }[]
  ) => {
    const valuesOnly = data.map((item) => item.value);
    // console.log(valuesOnly)
    const updatedFaqs = [...faqData];
    updatedFaqs[index].resv_status = valuesOnly;
    if (updatedFaqs[index].entry_id) {
      setSessionFaqs((prev) => ({
        ...prev,
        updated: prev.updated.some(
          (faq) => faq.entry_id === updatedFaqs[index].entry_id
        )
          ? prev.updated.map((faq) =>
              faq.entry_id === updatedFaqs[index].entry_id
                ? updatedFaqs[index]
                : faq
            )
          : [...prev.updated, updatedFaqs[index]],
      }));
    }
    setFaqData(updatedFaqs);
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[index];
      return updatedErrors;
    });
  };

  const handleAnswerChange = (index: number, value: string) => {
    const updatedFaqs = [...faqData];
    updatedFaqs[index].proposed_answer = value;

    if (updatedFaqs[index].entry_id) {
      setSessionFaqs((prev) => ({
        ...prev,
        updated: prev.updated.some(
          (faq) => faq.entry_id === updatedFaqs[index].entry_id
        )
          ? prev.updated.map((faq) =>
              faq.entry_id === updatedFaqs[index].entry_id
                ? updatedFaqs[index]
                : faq
            )
          : [...prev.updated, updatedFaqs[index]],
      }));
    }

    setFaqData(updatedFaqs);
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[index];
      return updatedErrors;
    });
  };

  const addFaq = () => {
    const newFaq: FaqEntry = {
      entry_id: "",
      question: "",
      proposed_answer: "",
      created_on: new Date(),
      DEL: false,
    };

    setFaqData((prevFaqs) => [...prevFaqs, newFaq]);
    setSessionFaqs((prev) => ({
      ...prev,
      created: [...prev.created, newFaq],
    }));
    // console.log("CREATED ", sessionFaqs);
    scrollToBottom();
  };

  const deleteFaq = (index: number, entry_id?: string) => {
    var faqToDelete: FaqEntry | undefined = undefined;
    if (entry_id) {
      faqToDelete = faqData.filter((faq) => faq.entry_id === entry_id)[0];
    } else if (index >= 0) {
      faqToDelete = faqData.filter((faq, _index) => _index === index)[0];
    }

    if (faqToDelete !== undefined) {
      if (faqToDelete.entry_id) {
        // Add to deletedFaqs if it exists
        setSessionFaqs((prev) => ({
          ...prev,
          deleted: [...prev.deleted, faqToDelete as FaqEntry],
        }));
      } else {
        // const filtered = sessionFaqs.created.filter((p,_index) => _index !== index)
        setSessionFaqs((prev) => ({
          ...prev,
          created: prev.created.filter((p, _index) => _index !== 0),
        }));
        // console.log(sessionFaqs)
      }
    }
    if (entry_id) {
      setFaqData((prevFaqs) => prevFaqs.filter((_) => _.entry_id !== entry_id));
    } else if (index >= 0) {
      setFaqData((prevFaqs) =>
        prevFaqs.filter((_, _index) => _index !== index)
      );
    }
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[index];
      return updatedErrors;
    });
  };

  const validateFaqs = () => {
    const newErrors: Record<number, string[]> = {};
    faqData.forEach((faq, index) => {
      if (!faq.DEL) {
        if (!faq.question.trim()) {
          newErrors[index] = [
            ...(newErrors[index] || []),
            "question_validation_error",
          ];
        }
        if (!faq.proposed_answer.trim()) {
          newErrors[index] = [
            ...(newErrors[index] || []),
            "answer_validation_error",
          ];
        }
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast.error(t("assistant.faq_mode.validationError"));
    }
    return Object.keys(newErrors).length === 0;
  };
  const resetSessionFaqs = () => {
    setSessionFaqs({
      created: [],
      updated: [],
      deleted: [],
    });
  };
  const resetErrors = () => {
    setErrors({});
  };

  const saveAllFaqs = async () => {
    setLoading(true);
    if (!validateFaqs()) {
      setLoading(false);
      return;
    }

    try {
      // Send created, updated, and deleted FAQs to the server
      await createOrUpdateFaqs({
        createdFaqs: sessionFaqs.created,
        updatedFaqs: sessionFaqs.updated,
        deletedFaqs: sessionFaqs.deleted,
        context_id,
      });
      toast.success(t("assistant.faq_mode.saveSuccess"));
    } catch (error) {
      console.error("Error saving FAQs:", error);
      toast.error(t("assistant.faq_mode.saveError"));
    } finally {
      setLoading(false);
      resetSessionFaqs();
      resetErrors();
      refetchFaqData();
    }
  };

  useEffect(() => {
    if (faqs?.results && faqs.results.length > 0) {
      setFaqData(faqs.results);
    }
  }, [faqs]);
  return (
    <PageWrapper className="flex flex-col h-full">
      {isFaqDataLoading ? (
        <div className="flex justify-center items-center h-full">
          <CircleSpinner color={"primary"} />
        </div>
      ) : (
        <>
          <div>
            {/* Restrict height and make it scrollable */}
            <div
              className="flex flex-col w-full overflow-y-auto"
              style={{ maxHeight: maxHeight }}
              ref={faqEnd}
            >
              <FaqList
                faqs={faqData}
                onQuestionChange={handleQuestionChange}
                onAnswerChange={handleAnswerChange}
                onAvailabilityChange={handleAvailabilityChange}
                onDeleteFaq={deleteFaq}
                questionLabel={t("assistant.faqs.questionLabel")}
                answerLabel={t("assistant.faqs.answerLabel")}
                emptyMessage={t("assistant.faqs.emptyMessage")}
                errors={errors}
              />
            </div>

            {/* Footer Buttons */}
            <div className="w-full p-4 flex items-center justify-between space-x-4 border-t-2">
              {/* Add FAQ Button */}
              <Button
                icon="plus-circle"
                onClick={addFaq}
                className="flex items-center justify-center p-3 bg-cyan-500 text-white rounded-full hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-300 transition duration-200"
              >
                {t("assistant.faqs.addFaq")}
              </Button>

              {/* Save FAQs Button */}
              <Button
                onClick={saveAllFaqs}
                className={`flex-grow flex items-center justify-center px-4 py-2 rounded-lg text-white ${
                  loading
                    ? "bg-cyan-400 cursor-not-allowed"
                    : "bg-cyan-500 hover:bg-cyan-600"
                } transition duration-200 focus:outline-none focus:ring-2 focus:ring-cyan-300`}
                loading={loading}
                disabled={
                  sessionFaqs.created.length === 0 &&
                  sessionFaqs.updated.length === 0 &&
                  sessionFaqs.deleted.length === 0
                }
              >
                {loading ? t("general.saving") : t("general.save")}
              </Button>
            </div>
          </div>
        </>
      )}
    </PageWrapper>
  );
};
