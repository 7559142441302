import { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "./Icon";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import CircleSpinner from "./CircleSpinner";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  clearAllTokens,
  getAccountList,
  saveAccountList,
  saveAuthToken,
  saveOldToken,
  saveRefreshToken,
} from "../shared/utils/tokenStore";
import Modal from "./Modal";
import ModalCard from "./ModalCard";
import ModalSection from "./ModalSection";
import { useTranslation } from "react-i18next";
import LoginForm from "./LoginForm";
import ModalActions from "./ModalAction";

export default function UserAvatar() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [arrowElement, setArrowElement] = useState<any>(null);
  const [avatarList, setAvatarList] = useState<any>();
  const [visibleModal, setVisibleModal] = useState(false);
  const userPermitAvatarList: string[] = [
    "HS0olMO3IO3fLjho0000000000000000",
    "DV8FOzJ5cjMSI67t0000000000000000",
    "AE2YYBGzyEWC12620000000000000000",
    "AL2mbmUSFQrTLLCW0000000000000000",
    "hs3vn7Ljk94litJ50000000000000000",
    "DB38Q3g8YNVamDEH0000000000000000",
    "Pd45BoFsXyss5HWp0000000000000000",
    "KG5ZfHx0iO9GzOUP0000000000000000",
    "DC654NYOzYcrBpU30000000000000000",
    "PE1mRumQZg1QZlMq0000000000000000",
    "VT1xfjPoskpbCWGj0000000000000000",
    "PG0EO835sUKoeWTO0000000000000000",
    "Ls88kabA1e7AEayz0000000000000000",
    "AL6KsXYdsiuSPLmh0000000000000000"
  ];

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
    placement: "bottom-end",
  });

  const { profile } = useSelector((state: RootState) => state.login);

  const history = useHistory();

  function handleLogout() {
    if (window && window.localStorage) {
      window.Intercom("shutdown");
      window.Intercom("boot", {
        app_id: "ux6abiod",
      });
      window.Intercom("update", {
        hide_default_launcher: true,
      });
      window.localStorage.clear();
      clearAllTokens();
      history.push("/");
    }
  }

  useEffect(() => {
    const accountList: any = getAccountList();
    if (accountList) {
      setAvatarList(JSON.parse(accountList));
    }
  }, []);

  return (
    <>
      <div
        ref={setReferenceElement}
        onMouseOver={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        className={
          "cursor-pointer hover:ring hover:ring-2 ring-cyan-200 bg-cyan-100 rounded-full w-10 h-10 text-cyan-600 font-medium text-center flex items-center justify-center"
        }
      >
        {profile && profile.name ? (
          profile.name.charAt(0)
        ) : (
          <CircleSpinner></CircleSpinner>
        )}
        <AnimatePresence>
          {visible && (
            <div
              ref={setPopperElement}
              style={{
                ...styles.popper,
                zIndex: 9999999999,
              }}
              {...attributes.popper}
            >
              <motion.div
                initial={{
                  opacity: 0,
                  scale: 0,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                }}
                transition={{
                  ease: "backInOut",
                  bounce: true,
                  duration: 0.3,
                }}
                exit={{
                  scale: 0,
                  opacity: 0,
                }}
                className={"z-50 relative"}
              >
                <div
                  className={
                    "shadow-lg border border-gray-200 m-1 w-52 bg-white overflow-hidden rounded-2xl"
                  }
                >
                  <div
                    onClick={() => {
                      history.push("/profile");
                    }}
                    className={
                      "hover:bg-gray-100 text-cyan-600 cursor-pointer px-4 py-2 text-left"
                    }
                  >
                    <div className={"cursor-pointer font-medium mt-2"}>
                      {profile?.name}
                    </div>
                    <div className={"text-sm text-gray-500 mb-2"}>
                      {profile?.role}
                    </div>
                  </div>
                  {profile?.user_key &&
                  userPermitAvatarList.includes(profile?.user_key) ? (
                    <>
                      <div className="overflow-auto max-h-60">
                        {avatarList
                          ?.filter((acc: any) => acc?.id !== profile?.user_key)
                          ?.map((acc: any) => (
                            <div
                              className={
                                "hover:bg-gray-100 text-cyan-800 cursor-pointer px-4 py-2 flex justify-between align-middle items-center border-t"
                              }
                            >
                              <div
                                onClick={() => {
                                  saveAuthToken(acc?.authToken);
                                  saveRefreshToken(acc?.refreshToken);
                                  saveOldToken(acc?.Syr2hflxppWeZ4YE);
                                  Sentry.setUser({
                                    id: acc?.id,
                                    email: acc?.email,
                                  });
                                  window.location.reload();
                                }}
                              >
                                <div
                                  className={"cursor-pointer font-medium mt-2"}
                                >
                                  {acc?.name}
                                </div>
                                <div className={"text-sm text-gray-500 mb-2"}>
                                  {acc?.role}
                                </div>
                              </div>

                              <div
                                className={
                                  "text-sm text-red-500 mb-2 hover:bg-gray-300 text-center rounded-full p-1.5"
                                }
                                onClick={() => {
                                  const temp = avatarList?.filter(
                                    (accTemp: any) => accTemp.id !== acc.id
                                  );
                                  setAvatarList(temp);
                                  saveAccountList(temp);
                                }}
                              >
                                x
                              </div>
                            </div>
                          ))}
                      </div>
                      <button
                        onClick={() => setVisibleModal(true)}
                        className={
                          "w-full focus:outline-none flex font-medium border-t items-center text-sm px-4 py-2 text-cyan-800 hover:bg-gray-100 hover:border-gray-100 cursor-pointer"
                        }
                      >
                        <Icon
                          name={"plus-circle"}
                          size={"20px"}
                          className={"mr-2"}
                        ></Icon>
                        {t("general.add")} {t("general.account")}
                      </button>
                    </>
                  ) : null}

                  <button
                    onClick={() => handleLogout()}
                    className={
                      "w-full focus:outline-none flex font-medium border-t items-center text-sm px-4 py-2 text-red-600 hover:bg-red-100 hover:border-red-100 cursor-pointer"
                    }
                  >
                    <Icon
                      name={"logout"}
                      size={"20px"}
                      className={"mr-2"}
                    ></Icon>
                    Logout
                  </button>
                </div>
                <div ref={setArrowElement} style={styles.arrow}></div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </div>
      <Modal visible={visibleModal} isResvDocs>
        <ModalCard className={"w-full max-w-lg"} title={t("login.loginButton")}>
          <ModalSection className="p-5">
            <LoginForm />
          </ModalSection>
          <ModalActions onClose={() => setVisibleModal(false)} />
        </ModalCard>
      </Modal>
    </>
  );
}
