import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { AssistantLocalChannel } from '../../types';
import Icon from '../Icon';
import EditAssistantApartmentConnectedChannelModal from './EditAssistantApartmentConnectedChannelModal';

interface AssistantApartmentConnectionProps {
    context_id?: string;
    local_key?: string;
    name?: string;
    active_channels?: AssistantLocalChannel[];
    color: string;
    refetchApartments?: () => void;
}

const AssistantApartmentConnection: FC<AssistantApartmentConnectionProps> = ({ 
    context_id, 
    local_key, 
    name, 
    active_channels = [], 
    color,
  refetchApartments,
}) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Render Active Channels
    const renderActiveChannels = () => {
        return active_channels.map((channel, index) => {
            const availableChannels = JSON.parse(channel.available_channels) || [];
            return availableChannels.map((ch:AssistantLocalChannel) => (
                <Icon
                    key={`${channel.local_key}-${ch.channel}-${index}`}
                    name={`assistant_connected_${ch.channel.toLowerCase()}`}
                    size="24px"
                />
            ));
        });
    };
    
    return (
        <div className="w-full">
            {/* Full-width container with dynamic background color */}
            <div className={`bg-${color} rounded-2xl shadow-md p-4 relative`}>
                {/* Header Section */}
                <div className="flex items-center justify-between mb-4">
                    <h3 className={`text-lg font-semibold text-${color}-800`}>{name}</h3>
                </div>
                {/* Channels Section */}
                <div className="flex flex-wrap gap-4">
                    {renderActiveChannels()}
                </div>
                {/* Modify Button */}
                <button
                    onClick={openModal} // Open the modal on button click
                    className={`absolute bottom-2 right-4 flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-lg bg-${color}-600 hover:bg-${color}-700 hover:underline`}
                >
                    <Icon name="pencil" size="20px" />
                    {t("general.edit")}
                </button>
            </div>

            {/* Modal Component */}
            <EditAssistantApartmentConnectedChannelModal 
                visible={isModalOpen} 
                onClose={closeModal} 
                local_key={local_key || ''}
                context_id={context_id}
                refetchApartments={refetchApartments}
            />
        </div>
    );
};

export default AssistantApartmentConnection;
