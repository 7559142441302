import { useQuery } from "react-query";
import {
  fetchAllModules,
  fetchCalendariCal,
  fetchDirectLocals,
  fetchModuleDetail,
  fetchReservations,
  fetchRestrictions,
} from "../shared/queries";
import dayjs from "dayjs";
import { BaseSelectItem, CalendarDay, Local, ReservationTable } from "../types";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ReservationDetail from "../components/calendar/ReservationDetail";
import ApartmentRowNEW from "../components/calendar/ApartmentRowNEW";
import DatePicker from "../components/DatePicker";
import weekday from "dayjs/plugin/weekday";
import { useHistory } from "react-router-dom";
import Icon from "../components/Icon";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import { useTranslation } from "react-i18next";
import Checkbox from "../components/Checkbox";
import SelectInput from "../components/SelectInput";
import { moduleId } from "../shared/utils/config";
import SideBarChannel from "../components/calendar/SideBarChannel";
import Button from "../components/Button";
import SingleAptCalendar from "../components/calendar/SingleAptCalendar";
import ModalOverride from "../components/calendar/ModalOverride";
import i18n from "../langs";
import "dayjs/locale/it";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
// TODO pagination import Pagination from "../components/Pagination";

dayjs.extend(weekday);

export default function CalendarNEW() {
  const history = useHistory();
  const { t } = useTranslation();
  const [viewDays, setViewDays] = useState(15);
  const [reservationsTimeLine, setReservationTimeline] = useState<any>([]);

  const optViewDay = [
    {
      label: 3,
      value: 3,
    },
    {
      label: 7,
      value: 7,
    },
    {
      label: 15,
      value: 15,
    },
    {
      label: 21,
      value: 21,
    },
    {
      label: 31,
      value: 31,
    },
  ];

  const optRateFilter = [
    {
      label: t(`channelManager.rate_plan.minStayArrival`),
      value: "min_stay_arrival",
    },
    {
      label: t(`channelManager.rate_plan.stopSell`),
      value: "stop_sell",
    },
    {
      label: t(`channelManager.rate_plan.closedToArrival`),
      value: "closed_to_arrival",
    },
    {
      label: t(`channelManager.rate_plan.closedToDeparture`),
      value: "closed_to_departure",
    },
    {
      label: t(`channelManager.rate_plan.maxStay`),
      value: "max_stay",
    },
  ];

  const [days, setDays] = useState<CalendarDay[]>([]);
  const [openTabCal, setOpenTabCal] = useState(false);
  const [startDate, setStartDate] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().add(viewDays, "days").format("YYYY-MM-DD")
  );
  const [currentReservation, setCurrentReservation] = useState<any>();
  const [tempLocals, setTempLocals] = useState<string[]>([]);
  const [iCalButton, setiCalButton] = useState(false);
  const [viewIcal, setViewIcal] = useState<boolean>(false);
  const [disableiCal, setDisableiCal] = useState<boolean>(true);
  const [selectStart, setSelectStart] = useState<any>([]);
  const [timeLine, setTimeLine] = useState(false);
  const [disableChannelBtn, setDisableChannelBtn] = useState(true);
  const [selectChannel, setSelectChannel] = useState<any>();
  const [selectStatus, setSelectStatus] = useState<any>();
  const [selectedFilter, setSelectedFilter] = useState<any>([]);
  const [resvView, setResvView] = useState(true);


  // TODO pagination const limitPage = viewDays;
  // TODO pagination const [currentPage, setCurrentPage] = useState(1);
  // TODO pagination const [pageData, setPageData] = useState<any>([]);
  // TODO pagination const [totalData, setTotalData] = useState(0);

  function recalcDays() {
    const ds = [];
    let tempDate = dayjs(startDate).locale(i18n.language).clone();
    while (tempDate.isBefore(endDate)) {
      ds.push({
        date: tempDate.format("DD-MM-YYYY"),
        weekday: tempDate.format("dd"),
        day: tempDate.format("DD"),
        dayNumber: tempDate.weekday(),
      } as CalendarDay);
      tempDate = tempDate.add(1, "day");
    }
    setDays(ds);
  }

  // useBreadcrumbs([{ label: "Calendar", route: "/calendar" }]);

  const { data, refetch: refetchReservations } = useQuery(
    ["calendar", timeLine, selectChannel, viewDays, startDate, endDate],
    () =>
      fetchReservations(
        [
          {
            key: "1",
            label: "",
            active: true,
            value: timeLine
              ? `${dayjs(startDate).startOf("month").format("YYYY MM DD")}`
              : `${dayjs(startDate).format("YYYY MM DD")}`,
          },
          {
            key: "2",
            label: "",
            active: true,
            value: timeLine
              ? `${dayjs(startDate).endOf("month").format("YYYY MM DD")}`
              : `${dayjs(endDate).format("YYYY MM DD")}`,
          },
          {
            key: "22",
            label: "",
            active: true,
            value: "0",
          },
        ],
        undefined,
        [],
        800,
        "calendar"
      ),
    {
      onSuccess: (data) => {
        if (timeLine) {
          setReservationTimeline(data.data);
        }
      },
    }
  );

  const { data: locals } = useQuery(["localsList"], () => fetchDirectLocals(), {
    onSuccess: (data: any) => {
      const test: string[] = [];
      data.results.map((acc: any) => {
        test.push(acc.local_key as string);
        return test;
      });
      setTempLocals(test);
      // TODO pagination setPageData(test)
      // TODO pagination setTotalData(data?.tot_locals)
      return data;
    },
  });

  function getLocalReservations(local: Local): ReservationTable[] {
    if (data) {
      const dataTemp = data.data
        .filter((d) => d.local === local.name)
        ?.filter((d) => {
          if (selectChannel && selectChannel.value) {
            return d.source?.toLowerCase() === selectChannel?.value;
          }
          return d;
        })
        ?.filter((d) => {
          if (selectStatus && selectStatus.value) {
            return d.checkin_status?.toLowerCase() === selectStatus?.value;
          }
          return d;
        });
      const secondDataTemp = getLocalReservationsiCal(local);
      const dataTempFinal = [...dataTemp, ...secondDataTemp];
      /* return data.data.filter((d) => {
        return d.local === local.name;
      }); */
      if (secondDataTemp.length > 0 && viewIcal) {
        return dataTempFinal;
      }
      return dataTemp;
    }
    return [];
  }

  const { data: iCalDT } = useQuery<any>(
    ["icalDT", tempLocals],
    () => fetchCalendariCal(tempLocals),
    {
      enabled: tempLocals.length > 0 && iCalButton,
    }
  );

  function getLocalReservationsiCal(local: Local): ReservationTable[] {
    if (iCalDT) {
      return iCalDT?.results
        ?.filter((d: any) => {
          return d.local_key === local.local_key;
        })
        ?.filter((d: any) => {
          if (selectChannel && selectChannel.value) {
            return d.source?.toLowerCase() === selectChannel?.value;
          }
          return d;
        })
        ?.filter((d: any) => {
          if (selectStatus && selectStatus.value) {
            return d.checkin_status?.toLowerCase() === selectStatus?.value;
          }
          return d;
        });
    }
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery([], () => {
    return fetchAllModules().then((data) => {
      const moduleCalendarFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.calendar
      );
      if (moduleCalendarFinded) {
        setiCalButton(true);
        if (moduleCalendarFinded?.active_locals_count === 0) {
          setDisableiCal(true);
        } else {
          setDisableiCal(false);
        }
      } else {
        setiCalButton(false);
        setDisableiCal(true);
      }
      const moduleChannelFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.channel_manager
      );
      if (moduleChannelFinded) {
        if (moduleChannelFinded?.active_locals_count === 0) {
          setDisableChannelBtn(true);
        } else {
          setDisableChannelBtn(false);
        }
      } else {
        setDisableChannelBtn(true);
      }
      return data;
    });
  });

  const { data: isRevenueManager } = useQuery(["moduleDetailRevenue"], () =>
    fetchModuleDetail(moduleId.revenue_manager)
  );

  const {
    data: restrictions,
    refetch: refetchRestrictions,
    isFetching: isLoadingRestrictions,
  } = useQuery(
    ["restrictions", startDate, selectStart, timeLine, viewDays],
    () => fetchRestrictions(timeLine ? 31 : viewDays, startDate, null)
  );

  const changeStartLocal = (dateTemp: any, local: any) => {
    setStartDate(dateTemp);
  };
  const changeEndLocal = (dateTemp: any, local: any) => {
    setEndDate(dateTemp);
  };
  useEffect(() => {
    const dataStorage = localStorage.getItem("calendar_filteredLocals");
    const optViewDaysStorage = localStorage.getItem("optViewDay");
    if (dataStorage) {
      setSelectStart(JSON.parse(dataStorage));
      /* if (JSON.parse(dataStorage) && JSON.parse(dataStorage)?.length > 0) {
        setTimeLine(true);
      } else {
        setTimeLine(false);
      } */
      // setStartDate(dayjs().startOf('month').format("YYYY-MM-DD"))
      // setEndDate(dayjs().endOf('month').format("YYYY-MM-DD"))
    }
    if (optViewDaysStorage) {
      const intValue = parseInt(optViewDaysStorage);
      setViewDays(intValue);
      const newDate = dayjs(startDate);
      setStartDate(newDate.format("YYYY-MM-DD"));
      setEndDate(newDate.add(intValue, "days").format("YYYY-MM-DD"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    recalcDays();
    refetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, i18n.language, viewDays]);

  return (
    <>
      <div
        className={
          timeLine ? "w-full" : "lg:sticky lg:top-0.5 w-full lg:bg-gray-50"
        }
        style={{ zIndex: 999 }}
      >
        <div className="w-full mb-5 md:flex justify-between items-center md:text-sm text-xs ml-4">
          <div className="flex align-middle">
            <SelectInput<BaseSelectItem, true>
              value={
                selectStatus && selectStatus?.value
                  ? selectStatus
                  : {
                      label: t("selector.selectStatus"),
                      value: null,
                    }
              }
              // placeholder="Seleziona locals da visualizzare.."
              onChange={(item) => {
                if (item) {
                  setSelectStatus(item);
                }
              }}
              options={[
                {
                  label: t("resv.notNecessary"),
                  value: "noneed",
                },
                {
                  label: t("resv.notExecuted"),
                  value: "wait",
                },
                {
                  label: t("resv.waitingConfirmation"),
                  value: "pend",
                },
                {
                  label: t("resv.confirmed"),
                  value: "ok",
                },
              ]}
            />
            <div className="md:mt-0 mt-3 md:flex">
              <Button
                className={"ml-1"}
                flat={!selectStatus?.value}
                color={!selectStatus?.value ? "primary" : "negative"}
                disabled={!selectStatus?.value}
                onClick={() => {
                  setSelectStatus({
                    label: null,
                    value: null,
                  });
                }}
                label=""
                icon="x"
                iconSize="18px"
              />
            </div>
          </div>
          <div className="mr-8">
            <Button
              size="small"
              onClick={() => {
                history.push("/reservations/new");
              }}
            >
              <span className={"hidden lg:block"}>
                {t("reservations.newReservation")}
              </span>
              <span className={"block lg:hidden"}>
                <Icon name={"document-add"} size={"26px"} />
              </span>
            </Button>
          </div>
        </div>
        <div className="justify-start gap-5 mx-4 mb-1 lg:flex hidden">
          <div className="border-2 p-3 rounded-xl bg-white">
            <div className="mb-2 md:text-sm text-xs">
              {t("general.legendaCheckin")}:
            </div>
            <div className="md:flex md:space-y-0 space-y-0.5 justify-start gap-5 text-xs font-bold">
              <div
                className={
                  "font-medium flex items-center text-gray-900 bg-gray-100 rounded-full p-1 border-2 border-gray-800 cursor-pointer "
                }
                onClick={() => {
                  setSelectStatus({
                    label: t("resv.notNecessary"),
                    value: "noneed",
                  });
                }}
              >
                <div className={"w-3 h-3 bg-gray-800 mr-2 rounded-full"}></div>{" "}
                {t("resv.notNecessary")}
              </div>
              <div
                className={
                  "font-medium flex items-center text-gray-900 bg-red-200 rounded-full p-1 border-2 border-red-700 cursor-pointer"
                }
                onClick={() => {
                  setSelectStatus({
                    label: t("resv.notExecuted"),
                    value: "wait",
                  });
                }}
              >
                <div className={"w-3 h-3 bg-red-700 mr-2 rounded-full"}></div>{" "}
                {t("resv.notExecuted")}
              </div>
              <div
                className={
                  "font-medium flex items-center text-gray-900 bg-yellow-300 rounded-full p-1 border-2 border-yellow-700 cursor-pointer"
                }
                onClick={() => {
                  setSelectStatus({
                    label: t("resv.waitingConfirmation"),
                    value: "pend",
                  });
                }}
              >
                <div
                  className={"w-3 h-3 bg-yellow-700 mr-2 rounded-full"}
                ></div>{" "}
                {t("resv.waitingConfirmation")}
              </div>

              <div
                className={
                  "font-medium flex items-center text-gray-900 bg-green-100 rounded-full p-1 border-2 border-green-700 cursor-pointer md:text-sm text-xs"
                }
                onClick={() => {
                  setSelectStatus({
                    label: t("resv.confirmed"),
                    value: "ok",
                  });
                }}
              >
                <div className={"w-3 h-3 bg-green-700 mr-2 rounded-full"}></div>{" "}
                {t("resv.confirmed")}
              </div>
            </div>
          </div>

          <div className="border-2 p-3 rounded-xl bg-white">
            <div className="mb-2 md:text-sm text-xs">
              {t("general.legendaAvailability")}:
            </div>
            <div className="md:flex md:space-y-0 space-y-0.5 justify-start gap-5 text-xs font-bold">
              <div
                className={
                  "font-medium flex items-center text-red-800 bg-red-200 rounded-full p-1 border-2 border-red-800 text-center"
                }
              >
                {t("channelManager.notAvailable")}
              </div>
              <div
                className={
                  "font-medium flex items-center text-green-800 bg-green-100 rounded-full p-1 border-2 border-green-800  text-center"
                }
              >
                {t("channelManager.available")}
              </div>
            </div>
          </div>
        </div>

        {!timeLine && (
          <div className="md:flex p-4 pt-0 space-x-2 lg:space-x-0 items-center justify-between mt-4 md:mb-0 mb-4 ">
            <div className="md:flex items-center space-x-4 md:space-y-0 space-y-2">
              <div className="font-bold text-gray-800 md:text-2xl text-md flex gap-1">
                {t("reservations.date")}
              </div>

              <div className="md:text-sm text-xs">
                <DatePicker
                  value={startDate}
                  inputFormat={"YYYY-MM-DD"}
                  outputFormat={"YYYY-MM-DD"}
                  displayFormat={"DD-MM-YYYY"}
                  onChange={(value) => {
                    setStartDate(
                      dayjs(value).format("YYYY-MM-DD") // dayjs(value).subtract(1, "days").format("YYYY-MM-DD")
                    );
                    setEndDate(
                      dayjs(value)
                        .subtract(1, "days")
                        .add(viewDays, "days")
                        .format("YYYY-MM-DD")
                    );
                  }}
                />
              </div>
              <div className="md:text-sm text-xs">
                <SelectInput<BaseSelectItem, true>
                  value={optViewDay.find((opt) => opt.value === viewDays)}
                  // placeholder="Seleziona locals da visualizzare.."
                  onChange={(item: any) => {
                    if (item) {
                      setViewDays(item.value);
                      const newDate = dayjs(startDate);
                      setStartDate(newDate.format("YYYY-MM-DD"));
                      setEndDate(
                        newDate.add(item.value, "days").format("YYYY-MM-DD")
                      );
                      localStorage.setItem("optViewDay", item.value);
                    }
                  }}
                  options={optViewDay}
                />
              </div>
              {iCalButton && (
                <div className="bg-white border-gray-300 border-2 rounded-xl px-2 md:text-sm text-xs">
                  <Checkbox
                    disabled={disableiCal}
                    size="sm"
                    checked={viewIcal}
                    onChange={() => setViewIcal((prev) => !prev)}
                    label={t("calendar.viewiCal")}
                  />
                </div>
              )}
            </div>

            <div className="md:flex gap-3 md:mt-0 mt-3 md:text-sm text-xs md:space-y-0 space-y-3">
              <Button
                label={t("channelManager.bulkUpdate")}
                disabled={disableChannelBtn}
                onClick={() => setOpenTabCal((prev) => !prev)}
              />
            </div>
          </div>
        )}
        <div className="w-full md:flex justify-start items-center gap-5 md:text-sm text-xs ml-4">
          {restrictions &&
            Object.keys(restrictions.results).length > 0 &&
            !timeLine && (
              <div className="w-96 flex align-middle ">
                <SelectInput<BaseSelectItem, true>
                  isDisabled={isLoadingRestrictions}
                  isLoading={isLoadingRestrictions}
                  value={selectedFilter}
                  isMulti
                  placeholder={t("channelManager.affectedRestrictions")}
                  onChange={(item) => {
                    if (item && item.length > 0) {
                      setSelectedFilter(item);
                    } else {
                      setSelectedFilter([]);
                    }
                  }}
                  options={optRateFilter}
                />
              </div>
            )}
          <div className="w-96 flex align-middle md:mt-0 mt-3">
            <SelectInput<BaseSelectItem, true>
              value={
                selectChannel && selectChannel.value
                  ? selectChannel
                  : {
                      label: t("selector.selectChannel"),
                      value: null,
                    }
              }
              isDisabled={isLoadingRestrictions}
              isLoading={isLoadingRestrictions}
              // placeholder="Seleziona locals da visualizzare.."
              onChange={(item) => {
                if (item) {
                  setSelectChannel(item);
                }
              }}
              options={[
                {
                  label: "Booking.com",
                  value: "booking",
                },
                {
                  label: "Airbnb",
                  value: "airbnb",
                },
              ]}
            />
            <div className="md:flex">
              <Button
                className={"ml-1"}
                flat={!selectChannel?.value}
                color={!selectChannel?.value ? "primary" : "negative"}
                disabled={!selectChannel?.value}
                onClick={() => {
                  setSelectChannel({
                    label: null,
                    value: null,
                  });
                }}
                label=""
                icon="x"
                iconSize="18px"
              />
            </div>
          </div>
          <div className="w-96 flex align-middle gap-3 md:mt-0 mt-3 md:text-sm text-xs">
            <SelectInput<BaseSelectItem, true>
              value={selectStart}
              isDisabled={isLoadingRestrictions}
              isLoading={isLoadingRestrictions}
              isMulti
              placeholder={t("selector.selectApt")}
              onChange={(item, actionMeta) => {
                if (item && actionMeta.action !== "clear") {
                  setSelectStart(item);
                  if (item.length > 0 && timeLine) {
                    setStartDate(dayjs().startOf("month").format("YYYY-MM-DD"));
                    setEndDate(dayjs().endOf("month").format("YYYY-MM-DD"));
                    // setTimeLine(true);
                    setSelectStart([item[item.length - 1]]);
                  } else if (item.length === 0) {
                    setStartDate(dayjs().format("YYYY-MM-DD"));
                    setEndDate(
                      dayjs().add(viewDays, "days").format("YYYY-MM-DD")
                    );
                    setSelectStart(item);
                    setTimeLine(false);
                  } else {
                    setStartDate(dayjs().format("YYYY-MM-DD"));
                    setEndDate(
                      dayjs().add(viewDays, "days").format("YYYY-MM-DD")
                    );
                    setSelectStart(item);
                  }
                  localStorage.setItem(
                    "calendar_filteredLocals",
                    JSON.stringify(item)
                  );
                } else {
                  setSelectStart([]);
                  setTimeLine(false);
                  setStartDate(dayjs().format("YYYY-MM-DD"));
                  setEndDate(
                    dayjs().add(viewDays, "days").format("YYYY-MM-DD")
                  );
                  localStorage.setItem(
                    "calendar_filteredLocals",
                    JSON.stringify([])
                  );
                }
              }}
              options={
                !isLoadingRestrictions
                  ? locals?.results?.map((local: any) => {
                      return {
                        label: local.name,
                        value: local.local_key,
                      };
                    })
                  : []
              }
            />
            <div className="md:flex">
              {selectStart.length === 1 && (
                <Button
                  dataTip={
                    timeLine
                      ? t("calendar.calendarResetChangeView")
                      : t("calendar.calendarChangeView")
                  }
                  label=""
                  icon="calendar"
                  iconSize="16px"
                  onClick={() => {
                    setTimeLine((prev) => {
                      if (prev) {
                        setStartDate(dayjs().format("YYYY-MM-DD"));
                        setEndDate(
                          dayjs().add(viewDays, "days").format("YYYY-MM-DD")
                        );
                        return !prev;
                      }
                      setStartDate(
                        dayjs().startOf("month").format("YYYY-MM-DD")
                      );
                      setEndDate(dayjs().endOf("month").format("YYYY-MM-DD"));
                      return !prev;
                    });
                  }}
                />
              )}
              <Button
                className={"md:ml-1 ml-0"}
                flat={selectStart.length === 0}
                color={selectStart.length === 0 ? "primary" : "negative"}
                disabled={selectStart.length === 0}
                onClick={() => {
                  setSelectStart([]);
                  setTimeLine(false);
                  setStartDate(dayjs().format("YYYY-MM-DD"));
                  setEndDate(
                    dayjs().add(viewDays, "days").format("YYYY-MM-DD")
                  );
                  localStorage.setItem(
                    "calendar_filteredLocals",
                    JSON.stringify([])
                  );
                }}
                label=""
                icon="x"
                iconSize="18px"
              />
            </div>
          </div>
        </div>
<div className="p-4">
          <Button className="flex gap-1" onClick={() => {setResvView((prev: any) => !prev)}} size="small">
            <p className="md:text-sm text-xs">{t("reservations.reservations")}</p>
            <Icon name={resvView ? "eye":"eye-off"} size="16px" className="cursor-pointer"/>
          </Button></div>
        {!timeLine && (
          <>
          
            <div className="flex border-t border-r">
              <div className="md:w-52 w-20 bg-gray-100 border-r flex items-center justify-between px-2"></div>
              <div className="flex-1 flex justify-between p-3">
                <div className="flex-1 text-sm font-bold capitalize flex gap-1">
                  {dayjs(startDate).locale(i18n.language).format("MMMM YYYY")}{" "}
                  {/* <Icon name="moon" size="18px" /> */}
                </div>
                <div className="flex-1 flex justify-end mr-10 gap-4 ">
                  <Icon
                    onClick={() => {
                      const newDate = dayjs(startDate).subtract(
                        viewDays,
                        "days"
                      );
                      setStartDate(newDate.format("YYYY-MM-DD"));
                      setEndDate(
                        newDate.add(viewDays, "days").format("YYYY-MM-DD")
                      );
                    }}
                    className={"cursor-pointer"}
                    name={"double-arrow-left"}
                    size={"20px"}
                  />
                  {/* <Icon
                    onClick={() => {
                      const newDate = dayjs(startDate).subtract(1, "days");
                      setStartDate(newDate.format("YYYY-MM-DD"));
                      setEndDate(
                        newDate.add(viewDays, "days").format("YYYY-MM-DD")
                      );
                    }}
                    className={"cursor-pointer"}
                    name={"chevron-left"}
                    size={"20px"}
                  />
                  <Icon
                    onClick={() => {
                      const newDate = dayjs(startDate).add(1, "days");
                      setStartDate(newDate.format("YYYY-MM-DD"));
                      setEndDate(
                        newDate.add(viewDays, "days").format("YYYY-MM-DD")
                      );
                    }}
                    className={"cursor-pointer"}
                    name={"chevron-right"}
                    size={"20px"}
                  /> */}
                  <Icon
                    onClick={() => {
                      const newDate = dayjs(startDate).add(viewDays, "days");
                      setStartDate(newDate.format("YYYY-MM-DD"));
                      setEndDate(
                        newDate.add(viewDays, "days").format("YYYY-MM-DD")
                      );
                    }}
                    className={"cursor-pointer"}
                    name={"double-arrow-right"}
                    size={"20px"}
                  />{" "}
                </div>
              </div>
            </div>
            <div className="flex border-b">
              <div className="md:w-52 w-20 bg-gray-100 border-r flex items-center justify-between px-2"></div>
              <div className="flex-1 flex">
                {days?.map((day, index) => (
                  <div
                    key={index}
                    className={`relative flex-1 border-r text-center py-3 font-medium text-sm border-t ${
                      day.dayNumber === 0 ? "text-red-600" : "text-gray-500"
                    }`}
                  >
                    {day.weekday} {day.day}
                    {index === 0 && (
                      <div
                        className={"border-r border-cyan-400"}
                        style={{
                          position: "absolute",
                          right: 0,
                          width: "1px",
                          top: 0,
                          height: "100vh",
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={"select-none"}>
        {!timeLine && locals && (
          <div className={"border-t"}>
            {locals?.results
              ?.filter((local: any) => {
                if (selectStart.length > 0) {
                  return selectStart?.find(
                    (sel: any) => sel.value === local.local_key
                  );
                }
                return local;
              })
              .map((local: any, index: number) => (
                <ApartmentRowNEW
                  viewDays={viewDays}
                  selectStart={selectStart}
                  key={`${index}-${local.name}`}
                  startDate={startDate}
                  days={days}
                  onView={(r) => {
                    setCurrentReservation(r);
                  }}
                  local={local}
                  reservations={getLocalReservations(local)}
                  objChannel={restrictions}
                  refetchRestrictions={refetchRestrictions}
                  findActiveRevenue={
                    isRevenueManager?.locals?.find(
                      (l: any) => l.local_key === local.local_key
                    )?.active
                  }
                  selectedFilter={selectedFilter}
                  resvView={resvView}
                />
              ))}
          </div>
        )}
        {timeLine && (
          <>
            {locals?.results
              ?.filter((local: any) => {
                if (selectStart.length > 0) {
                  return selectStart?.find(
                    (sel: any) => sel.value === local.local_key
                  );
                }
                return local;
              })
              .map((local: any, index: number) => (
                <SingleAptCalendar
                  selectStart={selectStart}
                  key={index}
                  startDate={startDate}
                  days={days}
                  onView={(r) => {
                    setCurrentReservation(r);
                  }}
                  local={local}
                  reservations={reservationsTimeLine?.filter(
                    (resv: any) => resv.local === local.name
                  )}
                  setStartDate={(date: any) => changeStartLocal(date, local)}
                  setEndDate={(date: any) => changeEndLocal(date, local)}
                  objChannel={restrictions}
                  refetchRestrictions={refetchRestrictions}
                  findActiveRevenue={
                    isRevenueManager?.locals?.find(
                      (l: any) => l.local_key === local.local_key
                    )?.active
                  }
                />
              ))}
          </>
        )}
        {currentReservation && (
          <motion.div
            initial={{
              right: -200,
            }}
            animate={{
              right: viewDays,
            }}
            style={{
              top: "0",
              position: "fixed",
              height: "100vh",
              width: "100vw",
              zIndex: 1001,
              padding: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={(e) => {
              setCurrentReservation(null);
            }}
          >
            <div
              style={{
                borderRadius: "6px",
                overflowX: "auto",
                height: "100%",
                boxShadow: "0px 2px 16px rgba(0,0,0,0.3)",
                position: "relative",
                backgroundColor: "#FFF",
              }}
              className={"border border-gray-300 md:w-1/2 w-full"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                style={{
                  padding: "2rem",
                }}
              >
                <ReservationDetail
                  reservationId={currentReservation.code}
                ></ReservationDetail>
              </div>
            </div>
          </motion.div>
        )}
        {openTabCal && (
          <SideBarChannel
            startDate={startDate}
            openTab={openTabCal}
            closeTab={(r) => {
              setOpenTabCal(r);
              refetchRestrictions();
            }}
          />
        )}
        <ModalOverride refetchRestrictions={refetchRestrictions} />
        {/* // TODO pagination {pageData && pageData.length > 1 ? (
        <div className="mt-5">
          <Pagination
            page={currentPage}
            pageSize={limitPage}
            totalItems={totalData}
            onPageUpdate={(value) => {
              if (!isLoading) {
                if (value === "+") {
                  setCurrentPage((prev) => prev + 1);
                  return;
                }
                if (value === "-") {
                  setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
                  return;
                }
                setCurrentPage(+value);
              }
            }}
          />
        </div>
      ) : null} */}
      </div>
    </>
  );
}
