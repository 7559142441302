import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Icon from "../Icon";
import { MessageUploadAttachments } from "../../types";

interface MessageInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
  attachments?: MessageUploadAttachments[];
  style?: React.CSSProperties;
  rows?: number;
  maxLength?: number;
  disabled?: boolean;
  emoji_lang?: string;
  emoji_icon_size?: string;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  handleFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  file_input_id?: string;
  no_attachments?:boolean;
}

const MessageInput: React.FC<MessageInputProps> = ({
  value,
  onChange,
  onKeyDown,
  placeholder,
  className,
  style,
  rows = 3,
  maxLength,
  onFocus,
  onBlur,
  disabled,
  emoji_lang = "it",
  emoji_icon_size = "20px",
  attachments = [],
  handleFileChange,
  file_input_id = "file-input",
  no_attachments = false
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const resizeTextArea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to calculate new height
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        200 // Maximum height (can be adjusted)
      )}px`;
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    const emojiSymbol = emoji.native;
    const textarea = textareaRef.current;

    if (textarea) {
      const { selectionStart, selectionEnd } = textarea;
      const newValue =
        value.slice(0, selectionStart) +
        emojiSymbol +
        value.slice(selectionEnd);

      onChange({
        ...({
          target: { value: newValue },
        } as React.ChangeEvent<HTMLTextAreaElement>),
      });

      setTimeout(() => {
        textarea.focus();
        textarea.selectionStart = textarea.selectionEnd =
          selectionStart + emojiSymbol.length;
      }, 0);
    }
  };

  const handleToggleEmojiPicker = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPickerPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
    }
    setShowEmojiPicker((prev) => !prev);
  };

  useEffect(() => {
    resizeTextArea();
  }, [value]);

  return (
    <div className={`relative ${className}`} style={style}>
      <textarea
        ref={textareaRef}
        disabled={disabled}
        className="w-full rounded-lg p-2 pr-12 resize-none focus:outline-none focus:ring-2 focus:ring-cyan-600"
        value={value}
        onChange={(e) => {
          onChange(e);
          resizeTextArea();
        }}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        rows={rows}
        maxLength={maxLength}
        onFocus={onFocus}
        onBlur={(e) => {
          if (showEmojiPicker) {
            e.preventDefault();
            textareaRef.current?.focus();
          } else if (onBlur) {
            onBlur(e);
          }
        }}
        style={{
          maxHeight: "200px",
          overflowY: "auto",
        }}
      />
      <div className="relative">
      {!no_attachments && (
        <>
        <button
          type="button"
          onClick={(e: React.MouseEvent) => {
            const fileInputElement = document.getElementById(file_input_id);
            fileInputElement?.click();
          }}
          className="absolute right-12 bottom-6 cursor-pointer"
        >
          <Icon name="clip" size={emoji_icon_size} />
        </button>
            <input
            ref={fileInputRef}
            id={file_input_id}
            type="file"
            accept=".jpg, .jpeg, .png"
            className="hidden"
            onChange={handleFileChange}
            multiple
          />
          </>
          )}
        <button
          ref={buttonRef}
          type="button"
          className="absolute right-6 bottom-6"
          onClick={handleToggleEmojiPicker}
        >
          <Icon
            name={showEmojiPicker ? "emojiSymbol:selected" : "emojiSymbol"}
            size={emoji_icon_size}
          />
        </button>

        {showEmojiPicker && (
          <div
            className="absolute bottom-12 right-6 z-50 bg-white shadow-lg rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <Picker
              data={data}
              onEmojiSelect={handleEmojiSelect}
              locale={emoji_lang}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageInput;
